import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { PiGitCommitBold } from "react-icons/pi";
import "./Commit.css";
const Commit = ({ activeStep, setActiveStep, setStepper }) => {
  const [showModal, setShowmodal] = useState(false);
  return (
    <div className="commitview">
      <div className="repoDetails">
        <div className="CurrentRepo"></div>
        <div className="OldRepo"></div>
      </div>
      <div className="commitMessage">
        <input className="form-control" placeholder="Commit Message" />
      </div>
      <div className="commitbutton">
        <button
          className="btn btn-primary mt-3"
          onClick={() => setShowmodal(true)}
        >
          <PiGitCommitBold />
          &ensp;Commit
        </button>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowmodal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        // keyboard={false}
        centered
      >
        {" "}
        {/* <form onSubmit={handleSubmit}> */}
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontWeight: "bold" }}
          >
            Commit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px 30px" }}></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setStepper(false)}>
            Continue
          </Button>
          <button
            className="btn btn-success"
            type="submit"
            onClick={() => setActiveStep((prev) => prev + 1)}
          >
            Pull
          </button>
        </Modal.Footer>
        {/* </form> */}
      </Modal>
    </div>
  );
};

export default Commit;
