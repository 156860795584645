import { toast } from "react-toastify";
// import { getModalType } from "../../api/Postaction";
import axios from "axios";

import uuid from "react-uuid";

//getDocumentPayload
export const getDocumentPayload = (isAdmin = false, isModalType = false) => {
  const adminCheck = sessionStorage.getItem("admin");
  const documentDropdown = sessionStorage.getItem("documentDropdown");
  let payload = {
    model_type: documentDropdown,
    is_admin: false,
  };

  if (adminCheck === "Logged in successfully as admin") {
    payload.is_admin = true;
  }

  if (isAdmin) {
    delete payload.is_admin;
  }
  if (isModalType) {
    delete payload.model_type;
  }
  return payload;
};

export const showToast = (message, type = 0, toastId = null) => {
  const toastOptions = toastId ? { toastId } : {};
  switch (type) {
    case 0:
      toast.error(message, toastOptions);
      break;
    case 1:
      toast.success(message, toastOptions);
      break;
    default:
      toast.success(message, toastOptions);
  }
};

//timeStampIso
export const timeStampIso = () => {
  const dateString = new Date();
  const dateObject = new Date(dateString);
  return dateObject.toISOString();
};

export const setModuleValue = async () => {
  let getModalTypeValue = await axios.get('https://pharcomm360.agilisiumtech.com:444/login/get_model_type/');

  if (getModalTypeValue) {
    sessionStorage.setItem("documentDropdown", getModalTypeValue);
  }

  return getModalTypeValue;
};

// remove bullets
export const removeBulletPoints = (text) => {
  // Replace various representations of the bullet point character with an empty string
  return text?.replace(/[\u2022\u25E6\u25D8\u2219\u2023\u25CF]/g, "").trim();
};

export const base64Encode = (input) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let output = "";
  let i = 0;

  while (i < input.length) {
    const a = input.charCodeAt(i++);
    const b = input.charCodeAt(i++);
    const c = input.charCodeAt(i++);
    const index1 = a >> 2;
    const index2 = ((a & 3) << 4) | (b >> 4);
    const index3 = isNaN(b) ? 64 : ((b & 15) << 2) | (c >> 6);
    const index4 = isNaN(c) ? 64 : c & 63;

    output +=
      chars.charAt(index1) +
      chars.charAt(index2) +
      chars.charAt(index3) +
      chars.charAt(index4);
  }
  // Add padding if needed
  while (output.length % 4 !== 0) {
    output += "=";
  }

  return output;
};

export const base64Decode = (input) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let output = "";
  let i = 0;

  input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

  while (i < input.length) {
    const index1 = chars.indexOf(input.charAt(i++));
    const index2 = chars.indexOf(input.charAt(i++));
    const index3 = chars.indexOf(input.charAt(i++));
    const index4 = chars.indexOf(input.charAt(i++));
    const a = (index1 << 2) | (index2 >> 4);
    const b = ((index2 & 15) << 4) | (index3 >> 2);
    const c = ((index3 & 3) << 6) | index4;

    output += String.fromCharCode(a);
    if (index3 !== 64) output += String.fromCharCode(b);
    if (index4 !== 64) output += String.fromCharCode(c);
  }

  return output;
};

export const getRandomNumberFromUuid = () => {
  // Generate a UUID using react-uuid
  const generatedUuid = uuid();

  // Extract a portion of the UUID and convert it to a number
  const randomNumber = parseInt(generatedUuid.slice(0, 10), 16);

  return randomNumber;
};
