// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edgebutton {
  /* width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px; */
  /* line-height: 1; */
  border-radius: 40px;
  padding: 2px 5px;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
`, "",{"version":3,"sources":["webpack://./src/components/etlModule/buttonedge.css"],"names":[],"mappings":"AAAA;EACE;;;;;;oBAMkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".edgebutton {\n  /* width: 20px;\n  height: 20px;\n  background: #eee;\n  border: 1px solid #fff;\n  cursor: pointer;\n  border-radius: 50%;\n  font-size: 12px; */\n  /* line-height: 1; */\n  border-radius: 40px;\n  padding: 2px 5px;\n}\n\n.edgebutton:hover {\n  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
