// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editcomputefields {
    /* margin-top: 30px; */
    display: grid;
    grid-template-columns: 40%;
    grid-column-gap: 70px;
    grid-row-gap: 35px;
    /* padding: 10px; */
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .editcomputebutton {
    background-color: #8b5ccc;
    border: none;
    color: white;
    padding: 5px 30px;
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  .editcomputebutton:hover {
    background-color: rgba(54, 3, 125, 0.919);
    color: white;
  }
  
  .editcomputeheading {
    margin-bottom: 30px;
    /* margin-top: 30px; */
    color: rgb(40, 121, 241);
  }
  
  .editcomputeform {
    background-color: white !important;
    padding: 3% 0;
    width: 100%;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    /* width: 40%; */
    border-radius: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/editcomponent/editcompute/editcompute.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,0BAA0B;IAC1B,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,yCAAyC;IACzC,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,sBAAsB;IACtB,wBAAwB;EAC1B;;EAEA;IACE,kCAAkC;IAClC,aAAa;IACb,WAAW;IACX,gDAAgD;IAChD,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":[".editcomputefields {\n    /* margin-top: 30px; */\n    display: grid;\n    grid-template-columns: 40%;\n    grid-column-gap: 70px;\n    grid-row-gap: 35px;\n    /* padding: 10px; */\n    width: 100%;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  \n  .editcomputebutton {\n    background-color: #8b5ccc;\n    border: none;\n    color: white;\n    padding: 5px 30px;\n    text-align: center;\n    font-size: 20px;\n    margin-bottom: 30px;\n    margin-top: 10px;\n    border-radius: 5px;\n  }\n  \n  .editcomputebutton:hover {\n    background-color: rgba(54, 3, 125, 0.919);\n    color: white;\n  }\n  \n  .editcomputeheading {\n    margin-bottom: 30px;\n    /* margin-top: 30px; */\n    color: rgb(40, 121, 241);\n  }\n  \n  .editcomputeform {\n    background-color: white !important;\n    padding: 3% 0;\n    width: 100%;\n    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */\n    transition: 0.3s;\n    /* width: 40%; */\n    border-radius: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
