import axios from "axios";
import { postUrl } from "../api/Endpoint";
import { useState } from "react";

let finalGeneratedcode;
let generatedID = [];
const promises = [];

export const Codegenerator = async (nodeBookName, EtlDetails) => {
  try {
    generatedID = [];
    console.log("generatedID==>-->", generatedID);
    let token = sessionStorage.getItem("accessToken");
    let result = await axios.post(`${postUrl.notebook}${nodeBookName}`, null, {
      headers : {
        Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
      }
    });
    finalGeneratedcode = result.data.code;
    // if (EtlDetails.extract.length > 0) {
    //   console.log("into generating extract");
    //   EtlDetails.extract.map(async (e) => {
    //     let extract = e.id.split("_")[0];

    //     if (extract === "Extract") {
    //       let a = extractDetails(e, nodeBookName);
    //       promises.push(a);
    //       generatedID.push(e.id);
    //       finalGeneratedcode = a;
    //     }
    //   });
    // }
    // await Promise.all(promises);
    // promises.length = 0;

    // const nextConnections = () => {

    EtlDetails.extract.map(async (ele, i) => {
      let extract = ele.id.split("_")[0];
      let res;
      if (extract === "Extract") {
        console.log("first Executing", ele.id);
        res = extractDetails(ele, nodeBookName, EtlDetails);
        // let nextConnectionID = nextConnection(EtlDetails, ele.id);
        // let b = nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
        promises.push(res);
        // generatedID.push(ele.id);
        // console.log("in extract", generatedID);
      }
    });
    await Promise.all(promises);
    promises.length = 0;
    return finalGeneratedcode;
  } catch (err) {
    console.log("err in generating code", err);
  }
};

const extractDetails = async (e, nodeBookName, EtlDetails) => {
  let body;
  try {
    if (e.path === "Rdbms") {
      if (e.credential === "Username and Password") {
        body = {
          file_name: nodeBookName,
          jdbc_url: e.JdbcUrl,
          driver_name: e.DriverName,
          table_name: e.TableName,
          username: e.UserName,
          password: e.Password,
          condition: e.ColumnName,
          df_name: e.TaskName,
          secret_path: e.Vaultpath,
        };
        if (e.CdcOption === "History") {
          delete body.column_names;
          delete body.condition;
        }
        if (e.VaultOptions === "Choose") {
          delete body.username;
          delete body.password;
        }
        let res;
        if (e.VaultOptions === "Create") {
          let token = sessionStorage.getItem("accessToken");
          res = await axios.post(`${postUrl.jdbcUsenameCreate}`, body, {
            headers : {
              Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
            }
          });
        } else {
          let token = sessionStorage.getItem("accessToken");
          res = await axios.post(`${postUrl.jdbcUsenameChoose}`, body, {
            headers : {
              Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
            }
          });
        }
        finalGeneratedcode = res.data.code;
        generatedID.push(e.id);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
        // promises.push(res);
        // await Promise.all(promises);
        // promises.length = 0;
        // generateCode = res.data.code;
        // console.log("generateCode extract details", generateCode);
        // return generateCode;
      } else if (e.credential === "AWS Secret manager") {
        if (e.CdcOption === "History") {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.JdbcUrl,
            driver_name: e.DriverName,
            table_name: e.TableName,
            credential: e.Credential,
            df_name: e.TaskName,
            // secret_path: e.Vaultpath,
          };
        } else {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.JdbcUrl,
            driver_name: e.DriverName,
            table_name: e.TableName,
            credential: e.Credential,
            condition: e.ColumnName,
            df_name: e.TaskName,
            // secret_path: e.Vaultpath,
          };
        }
        let token = sessionStorage.getItem("accessToken");
        let res1 = await axios.post(`${postUrl.jdbcAws}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res1.data.code;
        generatedID.push(e.id);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
      } else {
        if (e.CdcOption === "History") {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.JdbcUrl,
            driver_name: e.DriverName,
            table_name: e.TableName,
            credential: e.Credential,
            username: e.UserName,
            password: e.Password,
            df_name: e.TaskName,
            // secret_path: e.Vaultpath,
          };
        } else {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.JdbcUrl,
            driver_name: e.DriverName,
            table_name: e.TableName,
            credential: e.Credential,
            username: e.UserName,
            password: e.Password,
            condition: e.ColumnName,
            df_name: e.TaskName,
            // secret_path: e.Vaultpath,
          };
        }
        let token = sessionStorage.getItem("accessToken");
        let res1 = await axios.post(`${postUrl.jdbcDb}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res1.data.code;
        generatedID.push(e.id);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
        // promises.push(res1);
        // await Promise.all(promises);
        // promises.length = 0;
        // generateCode = res1.data.code;

        // console.log("generateCode extract details", generateCode);
        // return generateCode;
      }
    }
    if (e.path === "s3Bucket") {
      let body = {
        file_name: nodeBookName,
        df_name: e.TaskFileName,
        s3_url: e.Path,
        file_format: e.FileType,
      };
      try {
        console.log("e", e);
        let token = sessionStorage.getItem("accessToken");
        let res = await axios.post(`${postUrl.jdbcS3}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res.data.code;
        generatedID.push(e.id);
        console.log("generatedID", generatedID);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
        // console.log("extract s3 code", res.data.code);
        // generateCode = res.data.code;
        // return generateCode;
      } catch (err) {
        console.log("Error on Generating code for Extract s3", err);
      }
    }
    // MetaStore
    if (e.path === "Metastore") {
      let body = {
        file_name: nodeBookName,
        df_name: e.MetaTaskFileName,
        file_path: e.MetaPath,
      };
      try {
        console.log("e", e);
        let token = sessionStorage.getItem("accessToken");
        let res = await axios.post(`${postUrl.metaExtract}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res.data.code;
        generatedID.push(e.id);
        console.log("generatedID", generatedID);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
      } catch (err) {
        console.log("Error on Generating code for Extract Metastore", err);
      }
    }


    if (e.path === "Redshift") {
      if (e.credential === "Username and Password") {
        body = {
          file_name: nodeBookName,
          jdbc_url: e.RedShiftJdbcUrl,
          // driver_name: e,
          table_name: e.RedShiftTableName,
          credential: e.RedShiftCredential,
          username: e.RedShiftUserName,
          password: e.RedShiftPassword,
          condition: e.RedShiftColumnName,
          df_name: e.RedShiftTaskName,
          secret_path: e.RedShiftVaultpath,
          temp_dir: e.RedShiftTempDirectory,
        };
        if (e.CdcOption === "History") {
          delete body.condition;
        }
        if (e.VaultOptions === "Choose") {
          delete body.username;
          delete body.password;
        }
        let res;
        if (e.VaultOptions === "Create") {
          let token = sessionStorage.getItem("accessToken");
          res = await axios.post(`${postUrl.redshiftUsenameCreate}`, body, {
            headers : {
              Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
            }
          });
        } else {
          let token = sessionStorage.getItem("accessToken");
          res = await axios.post(`${postUrl.redshiftUsenameChoose}`, body, {
            headers : {
              Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
            }
          });
        }
        finalGeneratedcode = res.data.code;
        generatedID.push(e.id);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
        // promises.push(res);
        // await Promise.all(promises);
        // promises.length = 0;
        // generateCode = res.data.code;
        // console.log("generateCode extract details", generateCode);
        // return generateCode;
      } else if (e.credential === "AWS Secret manager") {
        if (e.CdcOption === "History") {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.RedShiftJdbcUrl,
            // driver_name: e, //
            table_name: e.RedShiftTableName,
            credential: e.RedShiftCredential,
            df_name: e.RedShiftTaskName,
            temp_dir: e.RedShiftTempDirectory,
          };
        } else {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.RedShiftJdbcUrl,
            // driver_name: e, //
            table_name: e.RedShiftTableName,
            credential: e.RedShiftCredential,
            df_name: e.RedShiftTaskName,
            temp_dir: e.RedShiftTempDirectory,
            condition: e.RedShiftColumnName,
          };
        }
        let token = sessionStorage.getItem("accessToken");
        let res1 = await axios.post(`${postUrl.redshiftAws}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res1.data.code;
        generatedID.push(e.id);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
        // promises.push(res1);
        // await Promise.all(promises);
        // promises.length = 0;
        // generateCode = res1.data.code;
        // console.log("generateCode extract details", generateCode);
        // return generateCode;
      } else {
        if (e.CdcOption === "History") {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.RedShiftJdbcUrl,
            // driver_name: e,
            table_name: e.RedShiftTableName,
            credential: e.RedShiftCredential,
            username: e.RedShiftUserName,
            password: e.RedShiftPassword,
            df_name: e.RedShiftTaskName,
            temp_dir: e.RedShiftTempDirectory,
          };
        } else {
          body = {
            file_name: nodeBookName,
            jdbc_url: e.RedShiftJdbcUrl,
            // driver_name: e,
            table_name: e.RedShiftTableName,
            credential: e.RedShiftCredential,
            username: e.RedShiftUserName,
            password: e.RedShiftPassword,
            condition: e.RedShiftColumnName,
            df_name: e.RedShiftTaskName,
            temp_dir: e.RedShiftTempDirectory,
            // secret_path: e.Vaultpath,
          };
        }
        let token = sessionStorage.getItem("accessToken");
        let res1 = await axios.post(`${postUrl.redshiftDb}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res1.data.code;
        generatedID.push(e.id);
        let nextConnectionID = nextConnection(EtlDetails, e.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
        // promises.push(res1);
        // await Promise.all(promises);
        // promises.length = 0;
        // generateCode = res1.data.code;

        // console.log("generateCode extract details", generateCode);
        // return generateCode;
      }
    }
  } catch (Err) {
    console.log("generateCode extract details", Err);
  }

  // return generateCode;
};

const transformDetails = async (nextConnectionID, nodeBookName, EtlDetails) => {
  let generateCode;
  let body;
  try {
    let data = EtlDetails.transform?.filter(
      (element) => element.id === nextConnectionID
    );
    let value = data[0];
    let transformName = nextConnectionID.split("_")[0];
    let res = EtlDetails.edges?.filter((e) => e.target === nextConnectionID);
    let connected = previousConnections(res[0].source, EtlDetails);
    let res2 = connected?.filter((e) => e.id === res[0].source);
    let excistingTaskname;
    if (res2[0].path === "Redshift") {
      excistingTaskname = res2[0].RedShiftTaskName;
    } else if (res2[0].path === "s3Bucket") {
      excistingTaskname = res2[0].TaskFileName;
    } else if (res2[0].path === "Metastore") {
      excistingTaskname = res2[0].MetaTaskFileName;
    } else {
      excistingTaskname = res2[0].TaskName;
    }

    if (transformName === "AddColumn") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        target_column: value.TargetName,
        column: value.Condition,
        operator: value.AddColumnOperator,
        value: value.Value,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformAddColumn}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      // console.log("finalGeneratedcode", finalGeneratedcode);
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      //  console.log("Transform Generated COde", generateCode);
    }
    if (transformName === "RenameColumn") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        old_column: value.Oldcolumn,
        new_column: value.TargetName,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformRenameColumn}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
    if (transformName === "Concat") {
      console.log("generating concat");
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column1: value.AddColumn1,
        column2: value.AddColumn2,
        new_column_name: value.TargetName,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformConcat}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      console.log("concat code", finalGeneratedcode);
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
    if (transformName === "Filter") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column: value.TargetColumn,
        operator: value.FilterOperator,
        value: value.Value,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformFilter}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
    if (transformName === "DropColumn") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        columns: value.Columns,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformdropColumn}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
    if (transformName === "SelectColumn") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        columns_to_be_filtered: value.Columns.split(","),
      };
      console.log("SelectColumn Body", body);
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transfromSelectColumn}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
    if (transformName === "DropDuplicate") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformdropDuplicate}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
    if (transformName === "Pivot") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        groupby_column: value.GroupbyColumn,
        pivot_column: value.PivotColumn,
        aggregate_type: value.AggregateType,
        aggregate_column: value.AggregateColumn,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformPivot}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
    if (transformName === "OrderBy") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column: value.Columns,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.transformOrderBy}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // console.log("generateCode transform details", generateCode);
      // return generateCode;
    }
  } catch (err) {
    console.log("Error on generating code error on Transformation", err);
  }
};

const joinDetails = async (nextConnectionID, nodeBookName, EtlDetails) => {
  try {
    let generateCode;
    let body;
    const res = EtlDetails.edges?.filter(
      (obj) => obj.target === nextConnectionID
    );
    let result1 = res.map((e) => e.source);
    // console.log("generatedID", generatedID);
    // console.log("result1", result1);
    // console.log("nextConnectionID", nextConnectionID);
    let result2 = result1.every((value) => generatedID.includes(value));

    if (result2 === true) {
      // console.log("inside", a);

      // console.log("first assigned ********", a);
      let transformName = nextConnectionID.split("_")[0];
      let data = EtlDetails.join?.filter(
        (element) => element.id === nextConnectionID
      );
      let value = data[0];
      const matches = EtlDetails.edges.filter(
        (e) => e.target === nextConnectionID
      );
      let result = matches.map((ele) => ele.source);
      const matchingObjects = result.map((e) => {
        let connected = previousConnections(e, EtlDetails);
        let res = connected.filter((item) => item.id === e);
        return res[0];
      });
      if (transformName === "Join") {
        body = {
          file_name: nodeBookName,
          df_name: value.TaskName,
          df_source1:
            matchingObjects[0]?.path === "Redshift"
              ? matchingObjects[0].RedShiftTaskName
              : matchingObjects[0]?.path === "s3Bucket"
              ? matchingObjects[0]?.TaskFileName
              : matchingObjects[0]?.path === "Metastore"
              ? matchingObjects[0]?.MetaTaskFileName
              : matchingObjects[0]?.TaskName,
          df_source2:
            matchingObjects[1]?.path === "Redshift"
              ? matchingObjects[1].RedShiftTaskName
              : matchingObjects[1]?.path === "s3Bucket"
              ? matchingObjects[1]?.TaskFileName
              : matchingObjects[1]?.path === "Metastore"
              ? matchingObjects[1]?.MetaTaskFileName
              : matchingObjects[1]?.TaskName,
          column1: value.SourceColumn1,
          column2: value.SourceColumn2,
          join_type: value.JoinType,
        };
        let token = sessionStorage.getItem("accessToken");
        let res = await axios.post(`${postUrl.join}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res.data.code;
        generatedID.push(value.id);
        let nextConnectionID = nextConnection(EtlDetails, value.id);
        let b = await nextExecutable(
          nextConnectionID,
          nodeBookName,
          EtlDetails
        );
      }
      // return generateCode;
    } else {
      console.log("in else 2nd ########");
    }
  } catch (err) {
    console.log("Error on generating Join code", err);
  }
};

const aggregateDetails = async (nextConnectionID, nodeBookName, EtlDetails) => {
  let generateCode;
  let body;
  try {
    let data = EtlDetails.aggregate?.filter(
      (element) => element.id === nextConnectionID
    );
    let value = data[0];
    let aggregateName = nextConnectionID.split("_")[0];
    let res = EtlDetails.edges?.filter((e) => e.target === nextConnectionID);
    let connected = previousConnections(res[0].source, EtlDetails);
    let res2 = connected?.filter((e) => e.id === res[0].source);
    let excistingTaskname;
    if (res2[0].path === "Redshift") {
      excistingTaskname = res2[0].RedShiftTaskName;
    } else if (res2[0].path === "s3Bucket") {
      excistingTaskname = res2[0].TaskFileName;
    } else if (res2[0].path === "Metastore") {
      excistingTaskname = res2[0].MetaTaskFileName;
    } else {
      excistingTaskname = res2[0].TaskName;
    }

    if (aggregateName === "Aggregate") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        groupby_column: value.GroupBy,
        aggregate_column: value.AggregateColumn,
        aggregate_function: value.Aggregate,
        Target_column: value.TargetColumn,
      };
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.aggregate}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      // console.log("aggegrate generated", finalGeneratedcode);
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
      // generatedID.push(value);
      // console.log("Aggregate Generated COde", generateCode);
      // return generateCode;
    }
    // if (transformName === "RenameColumn") {
    //   body = {
    //     file_name: nodeBookName,
    //     df_name: ele.TaskName,
    //     df_name1: excistingTaskname,
    //     old_column: ele.Oldcolumn,
    //     new_column: ele.TargetName,
    //   };
    //   let result = await axios.post(`${postUrl.transformRenameColumn}`, body);
    //   generateCode = result.data.code;
    // }
    // if (transformName === "Concat") {
    //   body = {
    //     file_name: nodeBookName,
    //     df_name: ele.TaskName,
    //     df_name1: excistingTaskname,
    //     column1: ele.AddColumn1,
    //     column2: ele.AddColumn2,
    //     new_column_name: ele.TargetName,
    //   };
    //   let result = await axios.post(`${postUrl.transformConcat}`, body);
    //   generateCode = result.data.code;
    // }
    // if (transformName === "Filter") {
    //   body = {
    //     file_name: nodeBookName,
    //     df_name: ele.TaskName,
    //     df_name1: excistingTaskname,
    //     column: ele.TargetColumn,
    //     operator: ele.FilterOperator,
    //     value: ele.Value,
    //   };
    //   let result = await axios.post(`${postUrl.transformFilter}`, body);
    //   generateCode = result.data.code;
    // }
  } catch (err) {
    console.log("Error on generating code on aggregate", err);
  }
};

const dataQualityDetails = async (
  nextConnectionID,
  nodeBookName,
  EtlDetails
) => {
  let body;
  try {
    let data = EtlDetails.dataQuality?.filter(
      (element) => element.id === nextConnectionID
    );
    let value = data[0];
    let transformName = nextConnectionID.split("_")[0];
    let res = EtlDetails.edges?.filter((e) => e.target === nextConnectionID);
    let connected = previousConnections(res[0].source, EtlDetails);
    let res2 = connected?.filter((e) => e.id === res[0].source);
    let excistingTaskname;

    if (res2[0].path === "Redshift") {
      // console.log("in if");
      excistingTaskname = res2[0].RedShiftTaskName;
    } else if (res2[0].path === "s3Bucket") {
      // console.log("in else if");
      excistingTaskname = res2[0].TaskFileName;
    } else if (res2[0].path === "Metastore") {
      excistingTaskname = res2[0].MetaTaskFileName;
    } else {
      // console.log("in else ");
      excistingTaskname = res2[0].TaskName;
    }

    if (transformName === "Null Check") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column_name: value.ColumnName,
        // sql_condition: value, //TODO
        primary_key_column: value.PrimaryKey,
        s3_output_path: value.S3Output,
      };
      if (value.s3 === "No") {
        delete body.s3_output_path;
      }
      // console.log("body", body);
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.nullCheck}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      // console.log("finalGeneratedcode", finalGeneratedcode);
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
    }
    if (transformName === "Boolean Check") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column_name: value.ColumnName,
        // sql_condition: value., // TODO
        primary_key_column: value.PrimaryKey,
        s3_output_path: value.S3Output,
      };

      if (value.s3 === "No") {
        delete body.s3_output_path;
      }
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.booleanCheck}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
    }
    if (transformName === "Custom Check") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        // column_name: value., //TODO
        sql_condition: value.Sqlcondition,
        primary_key_column: value.PrimaryKey,
        s3_output_path: value.S3Output,
      };
      if (value.s3 === "No") {
        delete body.s3_output_path;
      }
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.customCheck}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      // console.log("concat code", finalGeneratedcode);
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
    }
    if (transformName === "Integer Check") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column_name: value.ColumnName,
        // sql_condition: value., // TODO
        primary_key_column: value.PrimaryKey,
        s3_output_path: value.S3Output,
      };
      if (value.s3 === "No") {
        delete body.s3_output_path;
      }
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.integerCheck}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
    }
    if (transformName === "Numeric Check") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column_name: value.ColumnName,
        // sql_condition: value., // TODO
        primary_key_column: value.PrimaryKey,
        s3_output_path: value.S3Output,
      };
      if (value.s3 === "No") {
        delete body.s3_output_path;
      }
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.numericCheck}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
    }
    if (transformName === "Length Check") {
      body = {
        file_name: nodeBookName,
        df_name: value.TaskName,
        df_name1: excistingTaskname,
        column_name: value.ColumnName,
        sql_condition: value.Sqlcondition,
        primary_key_column: value.PrimaryKey,
        s3_output_path: value.S3Output,
      };
      if (value.s3 === "No") {
        delete body.s3_output_path;
      }
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.post(`${postUrl.lengthCheck}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = result.data.code;
      generatedID.push(value.id);
      let nextConnectionID = nextConnection(EtlDetails, value.id);
      let b = await nextExecutable(nextConnectionID, nodeBookName, EtlDetails);
    }
  } catch (err) {
    console.log("Error in Generating DataQuality Code", err);
  }
};

const loadDetails = async (nextConnectionID, nodeBookName, EtlDetails) => {
  console.log("Load data", nextConnectionID, nodeBookName);
  let body;
  let generateCode;
  let data = EtlDetails.extract?.filter(
    (element) => element.id === nextConnectionID
  );
  let value = data[0];
  let res = EtlDetails.edges?.filter((e) => e.target === nextConnectionID);
  let connected = previousConnections(res[0].source, EtlDetails);
  let res2 = connected?.filter((e) => e.id === res[0].source);
  let excistingTaskname;
  if (res2[0].path === "Redshift") {
    excistingTaskname = res2[0].RedShiftTaskName;
  } else if (res2[0].path === "s3Bucket") {
    excistingTaskname = res2[0].TaskFileName;
  } else if (res2[0].path === "Metastore") {
    excistingTaskname = res2[0].MetaTaskFileName;
  } else {
    excistingTaskname = res2[0].TaskName;
  }
  // console.log("Load Excisting taskname", excistingTaskname);
  if (value.path === "Rdbms") {
    if (value.credential === "Username and Password") {
      body = {
        file_name: nodeBookName,
        jdbc_url: value.JdbcUrl,
        driver_name: value.DriverName,
        table_name: value.TableName,
        username: value.UserName,
        password: value.Password,
        df_name: excistingTaskname,
        secret_path: value.Vaultpath,
      };
      if (value.VaultOptions === "Choose") {
        delete body.username;
        delete body.password;
      }
      let res;
      if (value.VaultOptions === "Create") {
        let token = sessionStorage.getItem("accessToken");
        res = await axios.post(`${postUrl.loadUsenameCreate}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
      } else {
        let token = sessionStorage.getItem("accessToken");
        res = await axios.post(`${postUrl.loadUsenameChoose}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
      }
      finalGeneratedcode = res.data.code;
      generatedID.push(value.id);
      return finalGeneratedcode;
    } else if (value.credential === "AWS Secret manager") {
      body = {
        file_name: nodeBookName,
        jdbc_url: value.JdbcUrl,
        driver_name: value.DriverName,
        table_name: value.TableName,
        credential: value.Credential,
        df_name: excistingTaskname,
      };
      let token = sessionStorage.getItem("accessToken");
      let res1 = await axios.post(`${postUrl.loadAws}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = res1.data.code;
      generatedID.push(value.id);
      // console.log("generateCode load details", generateCode);
      return finalGeneratedcode;
    } else {
      body = {
        file_name: nodeBookName,
        jdbc_url: value.JdbcUrl,
        driver_name: value.DriverName,
        table_name: value.TableName,
        credential: value.Credential,
        username: value.UserName,
        password: value.Password,
        df_name: excistingTaskname,
      };
      let token = sessionStorage.getItem("accessToken");
      let res1 = await axios.post(`${postUrl.loadDb}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = res1.data.code;
      generatedID.push(value.id);
      // console.log("generateCode load details", generateCode);
      return finalGeneratedcode;
    }
  }
  if (value.path === "s3Bucket") {
    let body = {
      file_name: nodeBookName,
      df_name: excistingTaskname,
      s3_url: value.Path,
      file_format: value.FileType,
    };
    try {
      let token = sessionStorage.getItem("accessToken");
      let res = await axios.post(`${postUrl.loadS3}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = res.data.code;
      generatedID.push(value.id);
      return finalGeneratedcode;
    } catch (err) {
      console.log("Error on Generating code for Extract s3", err);
    }
  }
  //Metastore Load
  if (value.path === "Metastore") {
    if (value.meta === "SaveAsPath") {
      let body = {
        file_name: nodeBookName,
        df_name: excistingTaskname,
        file_path: value.MetaPath,
      };
      try {
        let token = sessionStorage.getItem("accessToken");
        let res = await axios.post(`${postUrl.metaLoadPath}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res.data.code;
        generatedID.push(value.id);
        return finalGeneratedcode;
      } catch (err) {
        console.log("Error on Generating code for Extract Metastore", err);
      }
    } else {
      let body = {
        file_name: nodeBookName,
        df_name: excistingTaskname,
        file_path: value.Table,
      };
      try {
        let token = sessionStorage.getItem("accessToken");
        let res = await axios.post(`${postUrl.metaLoadTable}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        finalGeneratedcode = res.data.code;
        generatedID.push(value.id);
        return finalGeneratedcode;
      } catch (err) {
        console.log("Error on Generating code for Extract Metastore", err);
      }
    }
  }

  if (value.path === "Redshift") {
    if (value.credential === "Username and Password") {
      body = {
        file_name: nodeBookName,
        jdbc_url: value.RedShiftJdbcUrl,
        table_name: value.RedShiftTableName,
        username: value.RedShiftUserName,
        password: value.RedShiftPassword,
        df_name: excistingTaskname,
        secret_path: value.RedShiftVaultpath,
        temp_dir: value.RedShiftTempDirectory,
      };
      if (value.RedShiftVaultOptions === "Choose") {
        delete body.username;
        delete body.password;
      }
      let res;
      if (value.RedShiftVaultOptions === "Create") {
        let token = sessionStorage.getItem("accessToken");
        res = await axios.post(`${postUrl.redshiftloadUsenameCreate}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
      } else {
        let token = sessionStorage.getItem("accessToken");
        res = await axios.post(`${postUrl.redshiftloadUsenameChoose}`, body, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
      }
      finalGeneratedcode = res.data.code;
      generatedID.push(value.id);
      return finalGeneratedcode;
    } else if (value.credential === "AWS Secret manager") {
      body = {
        file_name: nodeBookName,
        jdbc_url: value.RedShiftJdbcUrl,
        table_name: value.RedShiftTableName,
        credential: value.RedShiftCredential,
        df_name: excistingTaskname,
        temp_dir: value.RedShiftTempDirectory,
      };
      let token = sessionStorage.getItem("accessToken");
      let res1 = await axios.post(`${postUrl.redshiftloadAws}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = res1.data.code;
      generatedID.push(value.id);
      // console.log("generateCode load details", generateCode);
      return finalGeneratedcode;
    } else {
      body = {
        file_name: nodeBookName,
        jdbc_url: value.RedShiftJdbcUrl,
        table_name: value.RedShiftTableName,
        credential: value.RedShiftCredential,
        username: value.RedShiftUserName,
        password: value.RedShiftPassword,
        df_name: excistingTaskname,
        temp_dir: value.RedShiftTempDirectory,
      };
      let token = sessionStorage.getItem("accessToken");
      let res1 = await axios.post(`${postUrl.redshiftloadDb}`, body, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      });
      finalGeneratedcode = res1.data.code;
      generatedID.push(value.id);
      // console.log("generateCode load details", generateCode);
      return finalGeneratedcode;
    }
  }
};

export const previousConnections = (id, EtlDetails) => {
  const data = id.split("_")[0];
  let res;
  if (data === "Extract") {
    res = EtlDetails.extract;
  }
  if (
    data === "AddColumn" ||
    data === "Concat" ||
    data === "RenameColumn" ||
    data === "Filter" ||
    data === "Pivot" ||
    data === "DropColumn" ||
    data === "DropDuplicate" ||
    data === "OrderBy" ||
    data === "SelectColumn"
  ) {
    res = EtlDetails.transform;
  }
  if (data === "Join") {
    res = EtlDetails.join;
  }
  if (data === "Aggregate") {
    res = EtlDetails.aggregate;
  }
  if (
    data === "Boolean Check" ||
    data === "Custom Check" ||
    data === "Integer Check" ||
    data === "Null Check" ||
    data === "Numeric Check" ||
    data === "Length Check"
  ) {
    res = EtlDetails.dataQuality;
  }
  return res;
};

export const nextConnection = (EtlDetails, id) => {
  let res = EtlDetails.edges?.filter((e) => e.source === id);
  let res1 = res[0]?.target;
  console.log("connnection target", res1);
  return res1;
};

export const nextExecutable = async (
  nextConnectionID,
  nodeBookName,
  EtlDetails
) => {
  let data = nextConnectionID.split("_")[0];
  console.log("nextExecutable", data);
  if (
    data === "AddColumn" ||
    data === "RenameColumn" ||
    data === "Concat" ||
    data === "Filter" ||
    data === "Pivot" ||
    data === "DropColumn" ||
    data === "DropDuplicate" ||
    data === "OrderBy" ||
    data === "SelectColumn"
  ) {
    let response = await transformDetails(
      nextConnectionID,
      nodeBookName,
      EtlDetails
    );
  }
  if (data === "Aggregate") {
    let response = await aggregateDetails(
      nextConnectionID,
      nodeBookName,
      EtlDetails
    );
  }
  if (data === "Join") {
    let response = await joinDetails(
      nextConnectionID,
      nodeBookName,
      EtlDetails
    );
  }
  if (
    data === "Boolean Check" ||
    data === "Custom Check" ||
    data === "Integer Check" ||
    data === "Null Check" ||
    data === "Numeric Check" ||
    data === "Length Check"
  ) {
    let response = await dataQualityDetails(
      nextConnectionID,
      nodeBookName,
      EtlDetails
    );
  }
  if (data === "Load") {
    let response = await loadDetails(
      nextConnectionID,
      nodeBookName,
      EtlDetails
    );
  }
};
