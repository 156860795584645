import { React, useEffect, useState } from "react";
import { useContext } from "react";
import axios from "axios";
import Select from "react-select";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import "../../components/createcompute/compute.css";
import { ComputeValidation, ComputeValidationDatabricks } from "../validation";
import CustomCompute from "../customcompute/customcompute";
import { MyContext } from "../context/ContextApi";
import { Oval } from "react-loader-spinner";
import { ipAddress } from "../api/IpAddress";

const clustersourceOptions = [
  { value: "AWS-EMR-Classic", label: "AWS-EMR-Classic" },
  { value: "Databricks", label: "Databricks" },
  { value: "AWS-Serverless", label: "AWS-Serverless" },
];

const noOptionsMessage = () => "Loading...";
const CreateCompute = ({
  setShowOrchestration,
  showOrchestration,
  setExpand,
  selectedValue,
  setSelectedValue,
}) => {
  const [computeSize, setComputeSize] = useState([]);
  const { changeHandler, allValues } = useContext(MyContext);
  const { register, control, handleSubmit, formState, setValue } =
    useForm({
      resolver: zodResolver(
        allValues.Clustersource.value === "Databricks"
          ? ComputeValidationDatabricks
          : ComputeValidation
      ),
      mode: "all",
    });

  const { errors } = formState;

  // const [showOrchestration1, setshowOrchestration1] = useState(false);
  const [clustersizeOptions, setClustersizeOptions] = useState([
    { value: "Custom", label: "Custom" },
  ]);

  const clustersource = useController({
    name: "clustersource",
    control,
    defaultValue: allValues.Clustersource.value,
  });

  const clustersize = useController({
    name: "clustersize",
    control,
    defaultValue: allValues.Clustersize.value,
  });

  const handleSelectChange = (option) => {
    clustersource.field.onChange(option.value);
    allValues.Clustersource = option;
    if (showOrchestration === true) {
      setShowOrchestration(false);
    }
  };

  const handleSelectChange2 = (option) => {
    clustersize.field.onChange(option.label);
    allValues.Clustersize = option;
    if (allValues.Clustersize.value !== "Custom") showValue();
    if (allValues.Clustersize.value === "Custom") {
      if (showOrchestration === true) setShowOrchestration(false);
    }
  };

  let Custom = <></>;
  let next = <></>;
  if (
    allValues.Clustername === "" ||
    allValues.Clustersize.value !== "Custom" ||
    allValues.Clustersource === ""
  ) {
    Custom = <></>;
    next = (
      <div className="text-center">
        <button
          className={`projectbutton `}
          type="submit"
          data-toggle="collapse"
          data-target={
            formState.isValid
              ? allValues.Clustersize !== ""
                ? "#collapseExample2"
                : ""
              : ""
          }
        >
          Next
        </button>
      </div>
    );
  } else {
    Custom = (
      <>
        {" "}
        <CustomCompute
          setShowOrchestration={setShowOrchestration}
          setExpand={setExpand}
        />
      </>
    );
  }

  const handleSave = async (formValues, event) => {
    setExpand(false);
    setShowOrchestration(true);
    console.log("in compute", allValues);
  };

  const fetchData = async () => {
    if (
      allValues.Clustersource !== "" &&
      allValues.Clustersource.value !== "AWS-Serverless"
    ) {
      allValues.Clustersize = "";
      setValue("clustersize", undefined);
      setClustersizeOptions([]);
      try {
        let token = sessionStorage.getItem("accessToken");
        let result = await axios.get(
          `${ipAddress.dev}/emr_classic/list_sml/`, { 
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        // console.log("computeSize", result.data);
        setComputeSize(result.data);
        // setSelectedValue(result.data[0]);
        let data = result.data;

        let data1 = data.filter(
          (e) =>
            e.cluster_source === allValues.Clustersource.value ||
            e.cluster_source === null
        );

        let result1 = data1.map((option) => ({
          label: option.cluster_size,
          value: option.size_id.toString(),
        }));
        let res = [...result1, { value: "Custom", label: "Custom" }];
        setClustersizeOptions(res);
      } catch (err) {
        console.log("compute err===>", err);
      }
    } else if (allValues.Clustersource !== "") {
      setClustersizeOptions([]);
      setValue("clustersize", "Custom");
      allValues.Clustersize = { value: "Custom", label: "Custom" };
      // setClustersizeOptions([{ value: "Custom", label: "Custom" }]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [allValues.Clustersource]);

  const showValue = () => {
    if (
      allValues.Clustersize.value !== "" &&
      allValues.Clustersource.value !== ""
    ) {
      let res = computeSize.find(
        (item) => item.cluster_size === allValues.Clustersize.label
      );

      setSelectedValue(res);
    }
  };

  // useEffect(() => {
  //   if (allValues.Clustersize.value !== "Custom") showValue();
  //   if (allValues.Clustersize.value === "Custom") {
  //     if (showOrchestration === true) setShowOrchestration(false);
  //   }
  // }, [allValues.Clustersize]);

  return (
    <div className="" style={{ transition: "0.3s" }}>
      <div className="">
        <form className="computeform" onSubmit={handleSubmit(handleSave)}>
          <div className="computefields">
            <div>
              <div>
                <Select
                  placeholder="Select ClusterSource"
                  value={allValues.Clustersource}
                  onChange={handleSelectChange}
                  options={clustersourceOptions}
                ></Select>
              </div>
              <div style={{ color: "red" }}>
                {errors.clustersource?.message}
              </div>
            </div>

            <div>
              <input
                className="form-control"
                {...register("Clustername")}
                placeholder="Enter ClusterName"
                value={allValues.Clustername}
                onChange={changeHandler}
              />
              <div style={{ color: "red" }}>{errors.Clustername?.message}</div>
            </div>

            <div>
              <Select
                placeholder="Select ClusterSize"
                value={allValues.Clustersize}
                onChange={handleSelectChange2}
                options={clustersizeOptions}
                isDisabled={allValues.Clustersource.label === "AWS-Serverless"}
                noOptionsMessage={noOptionsMessage}
              ></Select>
            </div>
            <div style={{ color: "red" }}>{errors.clustersize?.message}</div>
            {allValues.Clustersize.value === "Custom" ? (
              <></>
            ) : (
              <>
                {allValues.Clustersource.value === "Databricks" ? (
                  allValues.Clustersize === "" ||
                  allValues.Clustername === "" ? (
                    <></>
                  ) : selectedValue === undefined ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <div>
                        <Oval
                          height={30}
                          width={80}
                          color="#0d6efd"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="whitesmoke"
                          strokeWidth={4}
                          strokeWidthSecondary={4}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="d-flex">
                        <div className="row">
                          {/* <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Cluster_name
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              value={selectedValue.cluster_name}
                              readOnly
                            />
                          </div> */}
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Min_workers
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={selectedValue.min_workers}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Max_workers
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={selectedValue.max_workers}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="row">
                          <div className="col ">
                            <label
                              style={{ fontSize: "10px" }}
                              // className="mb-2"
                            >
                              Node_type_id
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              value={selectedValue.node_type_id}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Driver_Typeid
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={selectedValue.driver_node_type_id}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px", width: "10px" }}>
                              Autotermination_min
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              placeholder=""
                              value={selectedValue.autotermination_minutes}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>{" "}
                      {/* <input
                        type="text"
                        className="form-control "
                        {...register("profileARN")}
                        placeholder="Instance Profile ARN"
                        value={allValues.profileARN}
                        onChange={changeHandler}
                      />
                      <div style={{ color: "red" }}>
                        {errors.profileARN?.message}
                      </div> */}
                    </>
                  )
                ) : allValues.Clustersource.value === "AWS-EMR-Classic" ? (
                  allValues.Clustersize === "" ||
                  allValues.Clustername === "" ? (
                    <></>
                  ) : selectedValue === undefined ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <div>
                        <Oval
                          height={30}
                          width={80}
                          color="#0d6efd"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="whitesmoke"
                          strokeWidth={4}
                          strokeWidthSecondary={4}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex">
                        <div className="row">
                          {/* <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Cluster_name
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              value={selectedValue.cluster_name}
                              readOnly
                            />
                          </div> */}
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>Market</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={selectedValue.market}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Instance_type
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              readOnly
                              value={selectedValue.instance_type}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="row">
                          <div className="col">
                            <label style={{ width: "10px", fontSize: "10px" }}>
                              Slave_instance_count
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={selectedValue.slave_instance_count}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Master_instance_count
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={selectedValue.master_instance_count}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ width: "10px", fontSize: "10px" }}>
                              Autotermination_seconds
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={selectedValue.autotermination_seconds}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </>
                    // <></>
                  )
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          {next}
        </form>
      </div>
      <div>
        <>{Custom}</>
      </div>
      {/* <div>{showOrchestration1 ? <p>Orchestration</p> : <></>}</div> */}
    </div>
  );
};

export default CreateCompute;
