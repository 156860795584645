// import React, { useContext, useEffect, useState } from "react";
// import Select from "react-select";
// import axios from "axios";
// import "../editchoosecompute/editchoosecompute.css";
// import { MdAddCircle } from "react-icons/md";
// import { useForm, useController } from "react-hook-form";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { chooseComputeValidation } from "../../validation";
// import EditCompute from "../editcompute/editcompute";
// import {editContext} from "../../ContextApi";
// import { ipAddress } from "../api/IpAddress";

// const EditChoosecompute = () => {
//   const [options, setOptions] = useState([]);
//   const [show, setShow] = useState(false);
//   const [showOrchestration, setShowOrchestration] = useState(false);
//   const [checked, setChecked] = useState("Choose Cluster");
//   const [disabled, setDisabled] = useState(true);
//   const {
//     allValues,
//     changeCluster,
//     Choosecluster,
//     setChoosecluster,
//     chooseClustersource1,
//     chooseClustersource,
//   } = useContext(editContext);

//   const { control, handleSubmit, formState } = useForm({
//     resolver: zodResolver(chooseComputeValidation),
//   });

//   // const navigate = useNavigate();

//   const { errors } = formState;

//   const chooseCompute = useController({
//     name: "chooseCompute",
//     control,
//     // defaultValue: Choosecluster.value,
//   });

//   const chooseComputeSource = useController({
//     name: "chooseComputeSource",
//     control,
//     // defaultValue: Choosecluster.value,
//   });
//   console.log("errors", errors);

//   const clustersourceOptions = [
//     { value: "AWS-EMR-Classic", label: "AWS-EMR-Classic" },
//     { value: "AWS-EMR-Serverless", label: "AWS-EMR-Serverless" },
//     { value: "Databricks", label: "Databricks" },
//   ];

//   async function emrgetdata() {
//     try {
//       if (chooseClustersource.value === "AWS-EMR-Classic") {
//         let res = await axios.get(
//           `http://${ipAddress.dev}:8000/emr_classic/listing_cluster/`
//         );
//         let data = res.data.Clusters;
//         const mappedOptions = data.map((option) => ({
//           label: option.Name,
//           value: option.Id,
//         }));
//         let result = [...mappedOptions];
//         setOptions(result);
//       }
//       if (chooseClustersource.value === "Databricks") {
//         let res2 = await axios.get(
//           `http://${ipAddress.dev}:8000/cluster/list_clusters`
//         );
//         let data1 = res2.data;
//         const mappedOptions2 = Object.keys(data1).map((key) => ({
//           label: key,
//           value: data1[key],
//         }));
//         let result = [...mappedOptions2];

//         setOptions(result);
//       }
//     } catch (err) {
//       console.log("err", err);
//     }
//   }

//   useEffect(() => {
//     emrgetdata();
//   }, [chooseClustersource1]);

//   const handleCompute = async (formValues) => {
//     allValues["Choosecluster"] = Choosecluster.label;
//     allValues["Chooseclustersource"] = chooseClustersource.label;
//     console.log("allvalues==>", allValues);

//     setShowOrchestration((current) => !current);

//     // setActiveStep(activeStep + 1);
//   };
//   const handleSelectChange2 = (option) => {
//     chooseCompute.field.onChange(option.value);
//     changeCluster(option);
//   };

//   console.log("Choosecluster value", Choosecluster);

//   const handleSelectChange1 = (option) => {
//     chooseComputeSource.field.onChange(option.value);
//     chooseClustersource1(option);
//     setChoosecluster("");
//   };

//   const handleChange = (e) => {
//     const target = e.target;
//     if (target.checked) {
//       setChecked(target.value);
//     }
//     setShow((current) => !current);
//     if (showOrchestration === true) {
//       setShowOrchestration((current) => !current);
//     }

//     allValues["Choosecluster"] = "";
//     setChoosecluster("");
//   };

//   // const dropdownValue = () => {
//   //   setShow((current) => !current);
//   //   if (showOrchestration === true) {
//   //     setShowOrchestration((current) => !current);
//   //   }
//   //   allValues["Choosecluster"] = "";
//   //   setChoosecluster("");
//   // };

//   return (
//     <div>
//       <div className="formLayout" style={{ marginTop: "40px" }}>
//         <div className="formDetails">
//           <div className="heading">
//             <h4>Edit Compute</h4>
//           </div>
//           <div className="computeHeading">
//             <div
//               style={{
//                 display: "flex",
//                 marginTop: "2%",
//                 alignItems: "center" /* Align Items Vertically */,
//                 justifyContent: "space-between",
//               }}
//             >
//               {/* <h5 style={{ marginLeft: "5%" }}>
//                 {" "}
//                 <input
//                   type="radio"
//                   value="Choose Cluster"
//                   checked={checked === "Choose Cluster"}
//                   onChange={handleChange}
//                 />
//                 &ensp;Choose Cluster
//               </h5> */}
//               {/* <div className="mr-4">
//                 <label>
//                   <input
//                     type="radio"
//                     value="Choose Cluster"
//                     checked={checked === "Choose Cluster"}
//                     onChange={handleChange}
//                   />
//                   &ensp;
//                   <span>Choose Cluster</span>
//                 </label>
//                 &ensp; &ensp; &ensp;
//                 <label>
//                   <input
//                     type="radio"
//                     value="Create Cluster"
//                     checked={checked === "Create Cluster"}
//                     onChange={handleChange}
//                   />
//                   &ensp;
//                   <span>Create Cluster</span>
//                 </label>
//               </div> */}

//               {/* <button
//                 className="btn "
//                 onClick={dropdownValue}
//                 style={{
//                   backgroundColor: "#8B5CCC",
//                   color: "white",
//                   borderRadius: "20px",
//                   marginRight: "30px",
//                 }}
//               >
//                 <MdAddCircle style={{ fontSize: "20px", fontWeight: "bold" }} />{" "}
//                 {show ? "Choose Cluster" : "Create Cluster"}
//               </button> */}
//             </div>
//             <form onSubmit={handleSubmit(handleCompute)}>
//               <div className="editchoosecomputefields">
//                 <div>
//                   <Select
//                     placeholder="Select Cluster Source"
//                     value={chooseClustersource}
//                     onChange={handleSelectChange1}
//                     options={clustersourceOptions}
//                     isDisabled={show}
//                   />
//                   <div style={{ color: "red" }}>
//                     {show ? <></> : errors.chooseComputeSource?.message}
//                   </div>{" "}
//                 </div>
               
//                 <div>
//                   <Select
//                     placeholder="Select Cluster"
//                     value={Choosecluster}
//                     onChange={handleSelectChange2}
//                     options={options}
//                     isDisabled={show}
//                   />
//                   <div style={{ color: "red" }}>
//                     {show ? <></> : errors.chooseCompute?.message}
//                     {/* {Choosecluster === "" ? errors.chooseCompute?.message : ""} */}
//                   </div>{" "}
//                 </div>
      

//                 {show ? (
//                   <></>
//                 ) : (
//                   <div className="text-center">
//                     <button
//                       className={`projectbutton ${
//                         show ? "btn btn-secondary" : ""
//                       }`}
//                       type="submit"
//                       disabled={show}
//                     >
//                       Save
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </form>

//             {/* <div>{show && <CreateCompute disable={show} />}</div> */}
//             <EditCompute
//               // checked={checked}
//               // handleChange={handleChange}
//               // disable={show}
//             /> 
//           </div>
//         </div>
//       </div>
//       {showOrchestration ? <p>Orchestration</p> : <></>}
//     </div>
//   );
// };

// export default EditChoosecompute;
