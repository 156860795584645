// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commitview {
  padding: 20px 20px;
  /* background-color: red; */
}

.repoDetails {
  margin-top: 10px;
  display: flex;
  gap: 5%;
  width: 100%;
}

.CurrentRepo {
  height: 45vh;
  width: 100%;
  /* background-color: pink; */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.OldRepo {
  height: 45vh;
  width: 100%;
  /* background-color: blue; */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.commitMessage {
  margin-top: 40px;
}
.commitbutton {
  display: flex;
  /* margin-right: 20px; */
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/etlModule/commitComponent/Commit.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,OAAO;EACP,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,4BAA4B;EAC5B,2CAA2C;EAC3C,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,WAAW;EACX,4BAA4B;EAC5B,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,wBAAwB;EACxB,yBAAyB;AAC3B","sourcesContent":[".commitview {\n  padding: 20px 20px;\n  /* background-color: red; */\n}\n\n.repoDetails {\n  margin-top: 10px;\n  display: flex;\n  gap: 5%;\n  width: 100%;\n}\n\n.CurrentRepo {\n  height: 45vh;\n  width: 100%;\n  /* background-color: pink; */\n  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n}\n.OldRepo {\n  height: 45vh;\n  width: 100%;\n  /* background-color: blue; */\n  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n}\n\n.commitMessage {\n  margin-top: 40px;\n}\n.commitbutton {\n  display: flex;\n  /* margin-right: 20px; */\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
