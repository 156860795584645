import React, { useEffect, useState } from "react";
import { Table, Pagination } from "rsuite";
import "rsuite/dist/rsuite.css";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Loader from "../loaderComponent/Loader";
// import Pagination from "../paginationComponent/Pagination";
import { MyContext } from "../context/ContextApi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./table.scss";
import Layout from "./Layout/layout";
import { Container, Row, Col } from "react-bootstrap";
import { rejectNotification, rejectWrapper, wrapper } from "../api/PostAction";
import { ipAddress } from "../api/IpAddress";

const ProjectTable = ({ records, columns, fetching, setResponse }) => {
  const { Column, HeaderCell, Cell } = Table;
  const [search, setSearch] = useState("");
  const [data, setData] = useState("");
  const [filteredData, setFilteredData] = useState(records);
  const [recordId, setRecordId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectData, setRejectData] = useState("");
  const [projectName, setProjectName] = useState("");
  const totalPages = Math.ceil(records?.length / rowsPerPage);
  const showModal = (record) => {
    setProjectName(record.project_name);
    setModalShow(true);
    setRecordId(record.project_id);
  };

  function handleRowsPerPageChange(event) {
    const newRowsPerPage = Number(event.target.value);
    // setFilteredData(records.slice(startIndex, endIndex));
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  }
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // const rowsToDisplay = filteredData.slice(startIndex, endIndex);
  // console.table(rowsToDisplay);
  // useEffect(() => {
  //   const startIndex = currentPage * rowsPerPage;
  //   const endIndex = startIndex + rowsPerPage;

  //   setFilteredData(records.slice(startIndex, endIndex));
  // }, [currentPage, records, rowsPerPage]);

  // useEffect(() => {
  //   const result = records.slice(startIndex, endIndex).filter((data) => {
  //     return data.project_name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  //   });

  //   setFilteredData(result);
  // }, [search]);
  // useEffect(() => {
  //   const start = limit * (page - 1);
  //   const end = start + limit;
  //   const result = records.slice(start, end)?.filter((data) => {
  //     return data.project_name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  //   });

  //   setFilteredData(result);
  // }, [search]);

  // useEffect(() => {
  //   setFilteredData(records);
  // }, [records]);
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      const res = await axios.delete(
        `${ipAddress.dev}/project/${recordId}/`
      );
      setModalShow(false);
      toast.success("Record Deleted Succesfully!!!");
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    if (search !== "") {
      const start = limit * (page - 1);
      const end = start + limit;
      const result = records.slice(start, end)?.filter((data) => {
        return (
          data.project_name?.toLowerCase().indexOf(search?.toLowerCase()) >= 0
        );
      });
      console.log("result", result);
      setData(result);
      setPage(1);
      // setLimit(result.length);
    } else {
      const res = records?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      console.log("res", res);
      setData(res);
    }
  }, [search]);

  useEffect(() => {
    if (search === "") {
      const res = records?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      setData(res);
    }
  }, [records, page, limit]);
  useEffect(() => {
    if (rejectData !== "") {
      setRejectModal(true);
    }
  }, [rejectData]);
  const closeModal = () => {
    setRejectModal(false);
    setRejectData("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert(`${rejectData.project_name}`);
    setRejectModal(false);
    let res = new FormData(e.target);
    let value = [...res.entries()];
    console.log("value", value[0][1]);
    let a = await rejectWrapper(rejectData, setResponse);
    let body = {
      description: value[0][1],
      recipients: rejectData["created by"],
      id: rejectData.project_id,
    };
    if (a !== undefined) {
      await rejectNotification(body);
    }
  };
  return (
    <>
      <input
        type="text"
        className="w-25 form-control"
        placeholder="🔎  Search Here"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{
          border: "1px solid black",
          // marginTop: "38px",
        }}
      />
      <div>
        <Table
          loading={fetching === "true" ? true : false}
          className="custom-table"
          virtualized
          hover={false}
          height={520}
          data={data}
          style={{
            marginTop: "30px",
            borderRadius: "10px ",
            scrollbarWidth: "0",
          }}
          align="center"
          verticalAlign="middle"
          // locale={{
          //   emptyMessage: fetching === "true" ? "Loading..." : "No data found",
          // }}
        >
          <Column width={180} align="center" resizable fixed>
            <HeaderCell id="header">Project Name</HeaderCell>
            <Cell dataKey="project_name" id="content" />
          </Column>

          <Column width={150} resizable fullText>
            <HeaderCell id="header">Bucket Name</HeaderCell>
            <Cell dataKey="bucket_name" id="content" />
          </Column>

          <Column width={130} resizable fullText>
            <HeaderCell id="header">Compute</HeaderCell>
            <Cell dataKey="cluster_name" id="content" />
          </Column>

          <Column width={140} resizable fullText>
            <HeaderCell id="header">Cost Center</HeaderCell>
            <Cell dataKey="cost_center" id="content" />
          </Column>

          <Column width={150} resizable fullText>
            <HeaderCell id="header">Environment</HeaderCell>
            <Cell dataKey="environment" id="content" />
          </Column>
          <Column width={150} resizable fullText>
            <HeaderCell id="header">Orchestration</HeaderCell>
            <Cell dataKey="orchestration" id="content" />
          </Column>
          <Column width={150} resizable fullText>
            <HeaderCell id="header">Created By</HeaderCell>
            <Cell dataKey="created by" id="content" />
          </Column>
          <Column width={150} resizable fullText>
            <HeaderCell id="header">Status</HeaderCell>
            <Cell dataKey="status" id="content" />
          </Column>
          <Column width={200} resizable fullText>
            <HeaderCell id="header">Approval</HeaderCell>
            <Cell style={{ padding: "6px" }} id="content">
              {(rowData) => (
                <>
                  {rowData.status === "Approval Pending" && (
                    <>
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => wrapper(rowData, setResponse)}
                      >
                        Approve
                      </button>
                      &ensp;
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => setRejectData(rowData)}
                      >
                        Reject
                      </button>
                    </>
                  )}
                  {rowData.status === "In Progress" && "In Progress"}
                  {rowData.status === "Active" && (
                    <div className="mt-1" style={{ color: "green" }}>
                      Approved
                    </div>
                  )}
                  {rowData.status === "Rejected" && (
                    <div className="mt-1" style={{ color: "red" }}>
                      Rejected
                    </div>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column width={150} fullText>
            <HeaderCell id="header" style={{ minWidth: "160px" }}>
              Action
            </HeaderCell>
            <Cell style={{ padding: "6px", marginLeft: "6px" }} id="content">
              {(rowData) => (
                <>
                  <Link
                    to="/editproject"
                    state={rowData}
                    style={{ color: "black" }}
                  >
                    <FaEdit size={"1.25em"} style={{ cursor: "pointer" }} />
                  </Link>
                  &ensp;
                  <RiDeleteBin5Fill
                    size={"1.25em"}
                    style={{ cursor: "pointer" }}
                    onClick={() => showModal(rowData)}
                  />
                </>
              )}
            </Cell>
          </Column>
        </Table>
      </div>

      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={records?.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      <div>
        {" "}
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure want to delete this record ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
            <Button variant="danger">Delete</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={rejectModal}
          // onHide={() => set(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Reject
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                className="form-control"
                placeholder="Enter the Reject Message"
                name="message"
                required
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
              <button className="btn btn-danger">Reject</button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      {/* <Pagination
        records={records}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        startIndex={startIndex}
        endIndex={endIndex}
      /> */}
    </>
  );
};

export default ProjectTable;
