// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.customcomputefields{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 40% ;
    grid-column-gap: 70px;
    grid-row-gap: 20px;
    padding: 10px;
    justify-content: center;
    margin-bottom: 10px;
}

.customcomputeform{
    border-style: ridge;
}

.customcomputebutton{
    background-color: rgb(40, 121, 241);
    border: none;
    color: white;
    padding: 10px 40px;
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    border-radius: 5px;
    
}
.customcomputebutton:hover{
    background-color: rgba(82, 82, 90, 0.919);
    color: white;
}

.customcomputeheading{
    margin-top: 30px;
    margin-bottom : 30px;
    color: rgb(40, 121, 241);
}
`, "",{"version":3,"sources":["webpack://./src/components/customcompute/customcompute.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,aAAa;IACb,2BAA2B;IAC3B,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mCAAmC;IACnC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;;AAEtB;AACA;IACI,yCAAyC;IACzC,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,wBAAwB;AAC5B","sourcesContent":["\n.customcomputefields{\n    margin-top: 30px;\n    display: grid;\n    grid-template-columns: 40% ;\n    grid-column-gap: 70px;\n    grid-row-gap: 20px;\n    padding: 10px;\n    justify-content: center;\n    margin-bottom: 10px;\n}\n\n.customcomputeform{\n    border-style: ridge;\n}\n\n.customcomputebutton{\n    background-color: rgb(40, 121, 241);\n    border: none;\n    color: white;\n    padding: 10px 40px;\n    text-align: center;\n    font-size: 20px;\n    margin-bottom: 30px;\n    border-radius: 5px;\n    \n}\n.customcomputebutton:hover{\n    background-color: rgba(82, 82, 90, 0.919);\n    color: white;\n}\n\n.customcomputeheading{\n    margin-top: 30px;\n    margin-bottom : 30px;\n    color: rgb(40, 121, 241);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
