import { React, useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { CgCloseO } from "react-icons/cg";
import { motion } from "framer-motion";
import { Line11 } from "../line";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  NullCheckValidtion,
  CustomCheckValidation,
  LengthCheckValidation,
} from "../../validation";
import { useSelector, useDispatch } from "react-redux";
import { updateDataQuality } from "../../../store/reducers/etlSlice";

const DataqualtiyForm = ({ setDataqualityForm, data }) => {
  const dispatch = useDispatch();
  const EtlDetails = useSelector((state) => state.etl);
  const dataCache = EtlDetails.dataQuality?.find((ele) => ele.id === data);
  const [radioselectedOption, setRadioSelectedOption] = useState(
    dataCache ? dataCache.s3 : ""
  );
  const Nullcheck = useForm({
    resolver: zodResolver(NullCheckValidtion),
  });
  const NullcheckSubmit = (value) => {
    console.log("AddColumnSubmit Values", value);
    Nullcheck.reset();
    setDataqualityForm(false);
    let a = { id: data, ...value };
    console.log("nullcheck", a);
    dispatch(updateDataQuality(a));
    console.log("Store transform", EtlDetails.dataQuality);
  };
  const Customcheck = useForm({
    resolver: zodResolver(CustomCheckValidation),
  });
  const CustomcheckSubmit = (value) => {
    Customcheck.reset();
    setDataqualityForm(false);
    let a = { id: data, ...value };
    console.log("Customcheck", a);
    dispatch(updateDataQuality(a));
  };
  const Lengthcheck = useForm({
    resolver: zodResolver(LengthCheckValidation),
  });
  const LengthSubmit = (value) => {
    Lengthcheck.reset();
    setDataqualityForm(false);
    let a = { id: data, ...value };
    console.log("Customcheck", a);
    dispatch(updateDataQuality(a));
  };

  const handleOptionChange = (event) => {
    setRadioSelectedOption(event.target.value);
    if (radioselectedOption === "No") {
      Nullcheck.unregister("S3Output");
      Customcheck.unregister("S3Output");
    }
  };
  console.log("errors", Customcheck.formState.errors);
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      transition={{ duration: 0.8 }}
      style={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center
        position: "fixed",
        top: 0,
        right: 0,
        width: "35%",
        height: "100%",
        backgroundColor: "#fff",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
        // padding: "30px 20px",
        boxSizing: "border-box",
        // zIndex: 9999,
        borderRadius: "20px 0px 0px 20px",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          height: "70px",
        }}
      >
        <h4 className="Heading1">
          {data.split("_")[0] === "Null Check" && "Null Check"}
          {data.split("_")[0] === "Boolean Check" && "Boolean Check"}
          {data.split("_")[0] === "Integer Check" && "Integer Check"}
          {data.split("_")[0] === "Numeric Check" && "Numeric Check"}
          {data.split("_")[0] === "Custom Check" && "Custom Check"}
          {data.split("_")[0] === "Length Check" && "Length Check"}
        </h4>
        <CgCloseO
          size={20}
          onClick={() => setDataqualityForm(false)}
          style={{ cursor: "pointer" }}
        />
      </div>
      {(data.split("_")[0] === "Null Check" ||
        data.split("_")[0] === "Boolean Check" ||
        data.split("_")[0] === "Integer Check" ||
        data.split("_")[0] === "Numeric Check") && (
        <div className="formLayout">
          <div className="Heading">
            {/* <h3 className="Heading1">{data.split("_")[0]}</h3>
            <Line11 /> */}
          </div>
          <form onSubmit={Nullcheck.handleSubmit(NullcheckSubmit)}>
            <div className="transformsfields">
              <div>
                <label className="fields">Task Name :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Nullcheck.register("TaskName")}
                  placeholder="e.g check"
                  defaultValue={dataCache ? dataCache.TaskName : ""}
                />
                <div style={{ color: "red" }}>
                  {Nullcheck.formState.errors.TaskName?.message}
                </div>
              </div>
              <div>
                <label className="fields">Column Name :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Nullcheck.register("ColumnName")}
                  placeholder="e.g Salesman Id"
                  defaultValue={dataCache ? dataCache.ColumnName : ""}
                />
                <div style={{ color: "red" }}>
                  {Nullcheck.formState.errors.ColumnName?.message}
                </div>
              </div>
              <div>
                <label className="fields">Primary Key Column :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Nullcheck.register("PrimaryKey")}
                  placeholder="e.g Batch Id"
                  defaultValue={dataCache ? dataCache.PrimaryKey : ""}
                />
                <div style={{ color: "red" }}>
                  {Nullcheck.formState.errors.PrimaryKey?.message}
                </div>
              </div>
              Do you want fail records to be loaded in s3
              <div className="d-flex">
                <div>
                  <input
                    type="radio"
                    value="Yes"
                    {...Nullcheck.register("s3", {
                      required: true,
                    })}
                    checked={radioselectedOption === "Yes"}
                    // onChange={handleOptionChange}
                    onClick={handleOptionChange}
                    id="Yes"
                  />
                  <label className="fields" htmlFor="Yes">
                    {" "}
                    <span style={{ marginLeft: "10px" }}> Yes</span>
                  </label>
                </div>
                &ensp; &ensp;{" "}
                <div>
                  <input
                    type="radio"
                    value="No"
                    {...Nullcheck.register("s3", {
                      required: true,
                    })}
                    checked={radioselectedOption === "No"}
                    // onChange={handleOptionChange}
                    onClick={handleOptionChange}
                    id="No"
                  />
                  <label className="fields" htmlFor="No">
                    <span style={{ marginLeft: "10px" }}> No</span>
                  </label>
                </div>
              </div>
              {Nullcheck.formState.errors?.s3 && (
                <p style={{ color: "red" }}>Please select an option</p>
              )}
              {radioselectedOption === "Yes" && (
                <div>
                  <label className="fields">S3 Output Path:</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...Nullcheck.register("S3Output")}
                    placeholder="e.g s3://data-bucket/failed_records"
                    defaultValue={dataCache ? dataCache.S3Output : ""}
                  />
                  <div style={{ color: "red" }}>
                    {Nullcheck.formState.errors.S3Output?.message}
                  </div>
                </div>
              )}
            </div>
            <div className="transformsfieldbutton">
              <div>
                <button
                  className="transformsbutton"
                  onClick={() => setDataqualityForm(false)}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="transformsbutton" type="submit">
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {data.split("_")[0] === "Custom Check" && (
        <div className="formLayout">
          {/* <div className="Heading">
            <h3 className="Heading1">{data.split("_")[0]}</h3>
            <Line11 />
          </div> */}
          <form onSubmit={Customcheck.handleSubmit(CustomcheckSubmit)}>
            <div className="transformsfields">
              <div>
                <label className="fields">Task Name :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Customcheck.register("TaskName")}
                  placeholder="e.g Custom Check"
                  defaultValue={dataCache ? dataCache.TaskName : ""}
                />
                <div style={{ color: "red" }}>
                  {Customcheck.formState.errors.TaskName?.message}
                </div>
              </div>
              <div>
                <label className="fields">Condition :</label>
                <textarea
                  type="text"
                  className="form-control mt-1 fields"
                  {...Customcheck.register("Sqlcondition")}
                  placeholder="e.g Custom.filter(df.state != 'OH')          
                      
                  Note: Use the Previous Taskname"
                  defaultValue={dataCache ? dataCache.Sqlcondition : ""}
                />
                <div style={{ color: "red" }}>
                  {Customcheck.formState.errors.Sqlcondition?.message}
                </div>
              </div>
              <div>
                <label className="fields">Primary Key Column :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Customcheck.register("PrimaryKey")}
                  placeholder="e.g State Id"
                  defaultValue={dataCache ? dataCache.PrimaryKey : ""}
                />
                <div style={{ color: "red" }}>
                  {Customcheck.formState.errors.PrimaryKey?.message}
                </div>
              </div>
              Do you want fail records to be loaded in s3
              <div className="d-flex">
                <div>
                  <input
                    type="radio"
                    value="Yes"
                    {...Customcheck.register("s3", {
                      required: true,
                    })}
                    checked={radioselectedOption === "Yes"}
                    // onChange={handleOptionChange}
                    onClick={handleOptionChange}
                    id="Yes"
                  />
                  <label className="fields" htmlFor="Yes">
                    {" "}
                    <span style={{ marginLeft: "10px" }}> Yes</span>
                  </label>
                </div>
                &ensp; &ensp;{" "}
                <div>
                  <input
                    type="radio"
                    value="No"
                    {...Customcheck.register("s3", {
                      required: true,
                    })}
                    checked={radioselectedOption === "No"}
                    // onChange={handleOptionChange}
                    onClick={handleOptionChange}
                    id="No"
                  />
                  <label className="fields" htmlFor="No">
                    <span style={{ marginLeft: "10px" }}> No</span>
                  </label>
                </div>
              </div>
              {Customcheck.formState.errors?.s3 && (
                <p style={{ color: "red" }}>Please select an option</p>
              )}
              {radioselectedOption === "Yes" && (
                <div>
                  <label className="fields">S3 Output Path:</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...Customcheck.register("S3Output")}
                    placeholder="e.g s3://data-bucket/failed_records"
                    defaultValue={dataCache ? dataCache.S3Output : ""}
                  />
                  <div style={{ color: "red" }}>
                    {Customcheck.formState.errors.S3Output?.message}
                  </div>
                </div>
              )}
            </div>
            <div className="transformsfieldbutton">
              <div>
                <button
                  className="transformsbutton"
                  onClick={() => setDataqualityForm(false)}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="transformsbutton" type="submit">
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {data.split("_")[0] === "Length Check" && (
        <div className="formLayout">
          {/* <div className="Heading">
            <h3 className="Heading1">{data.split("_")[0]}</h3>
            <Line11 />
          </div> */}
          <form onSubmit={Lengthcheck.handleSubmit(LengthSubmit)}>
            <div className="transformsfields">
              <div>
                <label className="fields">Task Name :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Lengthcheck.register("TaskName")}
                  placeholder="e.g Length Check"
                  defaultValue={dataCache ? dataCache.TaskName : ""}
                />
                <div style={{ color: "red" }}>
                  {Lengthcheck.formState.errors.TaskName?.message}
                </div>
              </div>
              <div>
                <label className="fields">Column Name :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Lengthcheck.register("ColumnName")}
                  placeholder="e.g Pincode"
                  defaultValue={dataCache ? dataCache.ColumnName : ""}
                />
                <div style={{ color: "red" }}>
                  {Lengthcheck.formState.errors.ColumnName?.message}
                </div>
              </div>
              <div>
                <label className="fields">Condition :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Lengthcheck.register("Sqlcondition")}
                  placeholder="e.g != 6"
                  defaultValue={dataCache ? dataCache.Sqlcondition : ""}
                />
                <div style={{ color: "red" }}>
                  {Lengthcheck.formState.errors.Sqlcondition?.message}
                </div>
              </div>
              <div>
                <label className="fields">Primary Key Column :</label>
                <input
                  type="text"
                  className="form-control mt-1 fields"
                  {...Lengthcheck.register("PrimaryKey")}
                  placeholder="e.g State Id"
                  defaultValue={dataCache ? dataCache.PrimaryKey : ""}
                />
                <div style={{ color: "red" }}>
                  {Lengthcheck.formState.errors.PrimaryKey?.message}
                </div>
              </div>
              Do you want fail records to be loaded in s3
              <div className="d-flex">
                <div>
                  <input
                    type="radio"
                    value="Yes"
                    {...Lengthcheck.register("s3", {
                      required: true,
                    })}
                    checked={radioselectedOption === "Yes"}
                    // onChange={handleOptionChange}
                    onClick={handleOptionChange}
                    id="Yes"
                  />
                  <label className="fields" htmlFor="Yes">
                    {" "}
                    <span style={{ marginLeft: "10px" }}> Yes</span>
                  </label>
                </div>
                &ensp; &ensp;{" "}
                <div>
                  <input
                    type="radio"
                    value="No"
                    {...Lengthcheck.register("s3", {
                      required: true,
                    })}
                    checked={radioselectedOption === "No"}
                    // onChange={handleOptionChange}
                    onClick={handleOptionChange}
                    id="No"
                  />
                  <label className="fields" htmlFor="No">
                    <span style={{ marginLeft: "10px" }}> No</span>
                  </label>
                </div>
              </div>
              {Lengthcheck.formState.errors?.s3 && (
                <p style={{ color: "red" }}>Please select an option</p>
              )}
              {radioselectedOption === "Yes" && (
                <div>
                  <label className="fields">S3 Output Path:</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...Lengthcheck.register("S3Output")}
                    placeholder="s3://data-bucket/failed_records"
                    defaultValue={dataCache ? dataCache.S3Output : ""}
                  />
                  <div style={{ color: "red" }}>
                    {Lengthcheck.formState.errors.S3Output?.message}
                  </div>
                </div>
              )}
            </div>
            <div className="transformsfieldbutton">
              <div>
                <button
                  className="transformsbutton"
                  onClick={() => setDataqualityForm(false)}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="transformsbutton" type="submit">
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </motion.div>
  );
};

export default DataqualtiyForm;

/// []
