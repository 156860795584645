import React, { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import { IoAlertCircleSharp } from "react-icons/io5";
import { MdDelete, MdOutlineJoinInner, MdVerified } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteEdges,
  deleteJoin,
  deleteNodes,
} from "../../store/reducers/etlSlice";

const Join = ({
  id,
  setNodes,
  setEdges,
  edges,
  setPopup,
  setData,
  node,
  setJoinForm,
}) => {
  const dispatch = useDispatch();
  const EtlDetails = useSelector((state) => state.etl);
  const [isHovered, setIsHovered] = useState(false);
  const [removeNode, setRemoveNode] = useState(false);
  const [filled, setFilled] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const ToNavigateSource = () => {
    //  window.location.href = '/Sourcetarget';
    setData(id);
    setJoinForm(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (removeNode) {
      setRemoveNode(false);
    }
  };

  const deleteNode = () => {
    // console.log({ edges });
    setNodes((elements) => elements.filter((element) => element.id !== id));
    // setEdges((elements) => elements.filter((element) => element.source !== id));
    // setEdges((elements) => elements.filter((element) => element.target !== id));
    let a = EtlDetails.edges.filter((element) => element.source === id);
    let b = EtlDetails.edges.filter((element) => element.target === id);
    if (EtlDetails.edges.length > 0) {
      let a1 = a[0]?.id;
      let b1 = b[0]?.id;
      let b2 = b[1]?.id;
      dispatch(deleteEdges(a1));
      dispatch(deleteEdges(b2));
      dispatch(deleteEdges(b1));
    }
    if (EtlDetails.join.length > 0) {
      dispatch(deleteJoin(id));
    }
    dispatch(deleteNodes(id));
  };
  useEffect(() => {
    let a = EtlDetails.join.some((e) => e.id === id);
    if (a === true) {
      setFilled(true);
    }
  }, [EtlDetails.join]);
  return (
    <div style={{ marginTop: "-19px" }}>
      <span
        style={{
          // display: "flex",
          // alignItems: "center",
          fontSize: "7px",
          fontWeight: "bold",
          width: "100px",
          marginLeft: "-6px",
          // display: "flex",
          // width: "200px",
          color: filled ? "green" : "red",
        }}
        className="d-flex align-items-center"
      >
        {filled ? (
          <MdVerified size={7} color="green" />
        ) : (
          <IoAlertCircleSharp size={6} color="red" />
        )}
        &ensp;{node}
      </span>{" "}
      {isHovered ? (
        <div
          onMouseLeave={handleMouseLeave}
          style={{
            // position:"fixed",
            display: "flex",
            flexDirection: "column",
            height: "4vh ",
            // backgroundColor: "red",
            marginLeft: "-1px",
          }}
        >
          <div
            // className="d-flex align-items-center"
            style={{ fontSize: "7px", width: "10px", marginTop: "7px" }}
            onClick={ToNavigateSource}
          >
            <p
              style={{
                fontSize: "7px",
                display: "flex",
                alignItems: "center",
                width: "30px",
              }}
            >
              {" "}
              Open <IoMdArrowDropright />
            </p>
          </div>
          <div style={{ marginTop: "-3px", width: "10px" }}>
            <BsThreeDots
              onClick={() => setRemoveNode((current) => !current)}
              style={{ marginLeft: "3px" }}
            />
            {removeNode && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  left: "2px",
                  top: "0px",
                  zIndex: 1000,
                  border: "1px solid #ccc",
                  backgroundColor: "whitesmoke",
                  borderRadius: "5px",
                  minWidth: "25px",
                }}
              >
                <MdDelete color="red" onClick={deleteNode} />
                <RiCloseCircleLine
                  onClick={() => setRemoveNode((current) => !current)}
                />
                {/* </button> */}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          onClick={handleMouseEnter}
          style={{ marginTop: "7px", width: "10px" }}
        >
          <MdOutlineJoinInner size={17} />
        </div>
      )}
    </div>
  );
};

export default Join;
