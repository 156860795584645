// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activeitem {
  background: #242e46 0% 0% no-repeat padding-box;
  opacity: 1;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.notification {
  margin-left: auto;
  cursor: pointer;
}

.titlename {
  font-weight: 700 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebarComponent/Sidebar.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EACA,UAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AAEF;;AAAA;EACE,iBAAA;EACA,eAAA;AAGF;;AADA;EAEE,2BAAA;AAGF","sourcesContent":[".activeitem {\n  background: #242e46 0% 0% no-repeat padding-box;\n  opacity: 1;\n}\n.profile {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n}\n.notification {\n  margin-left: auto;\n  cursor: pointer;\n}\n.titlename {\n  // font-family: cinzel !important;\n  font-weight: 700 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
