import React, { useContext, useState } from "react";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { MyContext } from "../../context/ContextApi";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MwaaValidation } from "../../validation";
import Select from "react-select";
import VpcData from "./vpc";
import Airflow from "./airflow";
import axios from "axios";
import OwnOrchestration from "./ownorchestration";
import IAM from "../../iam/Iam";
import { ipAddress } from "../../api/IpAddress";


const CreateOrchestration = ({ selectedValue }) => {
  const { allValues, changeHandler } = useContext(MyContext);
  const [expand, setExpand] = useState(true);
  const [checked, setChecked] = useState("MWAA");
  const [show1, setShow1] = useState(true);
  const [showvpc, setShowvpc] = useState(false);
  const [mwaaData, setMwaaData] = useState([]);
  const [showIam, setshowIam] = useState(false);
  const [ownorchestration, setOwnorchestration] = useState({
    source: "Github",
    hubRegion: "",
    hubIp: "",
    hubEc2: "",
    hubPemkey: "",
    hubarchitecture: "",
    hubrepoLink: "",
    hubtoken: "",
    labRegion: "",
    labIp: "",
    labEc2: "",
    labPemkey: "",
    labarchitecture: "",
    labrepoLink: "",
    labtoken: "",
    labEnv: "",
    codeRegion: "",
    codeIp: "",
    codeEc2: "",
    codePemkey: "",
  });
  const changeOwnorchestration = (e) => {
    const { name, value } = e.target;
    setOwnorchestration({ ...ownorchestration, [name]: value });
  };
  const {
    control,
    register: register1,
    handleSubmit,
    formState: { errors: errors1 },
  } = useForm({
    resolver: zodResolver(MwaaValidation),
    mode: "all",
  });

  const handleChange = (e) => {
    const target = e.target;
    allValues.inOrchcreation = target.value;
    if (target.checked) {
      setChecked(target.value);
    }
    setShow1((current) => !current);
    if (showvpc === true) {
      setShowvpc((current) => !current);
    }
    if (showIam === true) {
      setshowIam(false);
    }
  };

  const EnvironmentOptions = [
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
  ];
  const EnvClass = useController({
    name: "EnvClass",
    control,
    defaultValue: allValues.EnvClass.value,
  });
  const handleSelectChange = (option) => {
    EnvClass.field.onChange(option.value);
    allValues.EnvClass = option;
    fetchMwaaData();
  };

  const fetchMwaaData = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.get(`${ipAddress.dev}/mwaa/list_mwaa/`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      
      let res1 = result.data;
      let data = res1.find(
        (item) => item.mwaa_environment_class_size === allValues.EnvClass.value
      );
      setMwaaData(data);
      
    } catch (err) {
      console.error(err);
    }
  };

  const handlemwaa = async (formValues) => {
    console.log("Formvalues in Orchestration==>", formValues);
    console.log("allvalues in Orchestration==>", allValues);
    setShowvpc(true);
    // setShowOrchestration((current) => !current);
    // await ProjectCreation(allValues);
  };

  const skipOrches = async () => {
    setExpand(false);
    allValues.inOrchcreation = "skip";
    console.log("Skipped orches");
    if (showIam === false) setshowIam(true);
  };
  return (
    <div>
      <div className="formLayout" style={{ marginTop: "40px" }}>
        <div
          className="heading"
          data-toggle="collapse"
          data-target="#collapseExample3"
          onClick={() => setExpand((current) => !current)}
        >
          <h4>Orchestration</h4>
          {expand ? (
            <BsCaretUpFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample3"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          ) : (
            <BsCaretDownFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample3"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          )}
        </div>
        <div className="formDetails collapse show" id="collapseExample3">
          <div
            style={{
              display: "flex",
              marginTop: "2%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <div className="form-check">
              <input
                type="radio"
                value="MWAA"
                id="MWAA"
                checked={checked === "MWAA"}
                onChange={handleChange}
              />
              &ensp;
              <label htmlFor="MWAA" className="">
                MWAA
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                id="EKS"
                value="Airflow on EKS"
                checked={checked === "Airflow on EKS"}
                onChange={handleChange}
              />
              &ensp;
              <label htmlFor="EKS" className="">
                Airflow on EKS
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                id="OwnOrchestration"
                value="OwnOrchestration"
                checked={checked === "OwnOrchestration"}
                onChange={handleChange}
              />
              &ensp;
              <label htmlFor="OwnOrchestration" className="">
                Bring Your Own Orchestration
              </label>
            </div>
          </div>
          {checked === "MWAA" && (
            <>
              <form onSubmit={handleSubmit(handlemwaa)}>
                <div className="projectfields">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register1("EnvName")}
                      placeholder="Environment Name"
                      value={allValues.EnvName}
                      onChange={changeHandler}
                    />
                    <div style={{ color: "red" }}>
                      {errors1?.EnvName && errors1.EnvName.message}
                    </div>
                  </div>
                  <div>
                    <Select
                      // {...register("costcenter")}
                      placeholder="Select Enviroment Class"
                      value={allValues.EnvClass}
                      onChange={handleSelectChange}
                      options={EnvironmentOptions}
                    />

                    <div style={{ color: "red" }}>
                      {errors1.EnvClass?.message}
                    </div>
                  </div>
                  {/* <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register1("s3Bucket")}
                      placeholder="s3Bucket Name"
                      value={allValues.s3Bucket}
                      onChange={changeHandler}
                    />
                    <div style={{ color: "red" }}>
                      {errors1?.s3Bucket && errors1.s3Bucket.message}
                    </div>
                  </div> */}
                  {/* <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register1("dagPath")}
                      placeholder="DagPath Name"
                      value={allValues.dagPath}
                      onChange={changeHandler}
                    />
                    <div style={{ color: "red" }}>
                      {errors1?.dagPath && errors1.dagPath.message}
                    </div>
                  </div> */}
                  {/* <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register1("s3Path")}
                      placeholder="Requirements Path"
                      value={allValues.s3Path}
                      onChange={changeHandler}
                    />
                    <div style={{ color: "red" }}>
                      {errors1?.s3Path && errors1.s3Path.message}
                    </div>
                  </div> */}
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register1("Kmskey")}
                      placeholder="KMS Key Name"
                      value={allValues.Kmskey}
                      onChange={changeHandler}
                    />
                    <div style={{ color: "red" }}>
                      {errors1?.Kmskey && errors1.Kmskey.message}
                    </div>
                  </div>
                  <div></div>
                  {allValues.EnvClass === "" ? (
                    <></>
                  ) : mwaaData.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <div className="d-flex">
                        <div className="row">
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Mwaa Class
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              value={mwaaData.mwaa_environment_class}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Min Workers
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={mwaaData.mwaa_minworkers}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="row">
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Max Workers
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={mwaaData.mwaa_maxworkers}
                              readOnly
                            />
                          </div>
                          <div className="col ">
                            <label
                              style={{ fontSize: "10px" }}
                              // className="mb-2"
                            >
                              Mwaa Schedulers
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              value={mwaaData.mwaa_schedulers}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>{" "}
                    </>
                  )}
                </div>

                <div className="text-center">
                  {!errors1.EnvName?.message &&
                  !errors1.EnvClass?.message &&
                  !errors1.s3Bucket?.message &&
                  !errors1.dagPath?.message &&
                  !errors1.s3Path?.message &&
                  !errors1.Kmskey?.message ? (
                    showvpc ? (
                      <></>
                    ) : (
                      <>
                        <button className="projectbutton" type="submit">
                          Next
                        </button>
                        {/* <div style={{ textAlign: "center" }}>
                          <div
                            className="projectbutton"
                            type=""
                            onClick={skipOrches}
                            data-toggle="collapse"
                            data-target="#collapseExample3"
                          >
                            Skip
                          </div>
                        </div> */}
                      </>
                    )
                  ) : (
                    <>
                      <button className="projectbutton" type="submit">
                        Next
                      </button>
                      {/* <div style={{ textAlign: "center" }}>
                        <div
                          className="projectbutton"
                          type=""
                          onClick={skipOrches}
                          data-toggle="collapse"
                          data-target="#collapseExample3"
                        >
                          Skip
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </form>
              {!errors1.EnvName?.message &&
                !errors1.EnvClass?.message &&
                !errors1.s3Bucket?.message &&
                !errors1.dagPath?.message &&
                !errors1.s3Path?.message &&
                !errors1.Kmskey?.message && (
                  <div>
                    {" "}
                    {showvpc ? (
                      <VpcData
                        expand={expand}
                        setExpand={setExpand}
                        selectedValue={selectedValue}
                        mwaaData={mwaaData}
                        setshowIam={setshowIam}
                        // showCicd={showCicd}
                        skipOrches={skipOrches}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                )}
            </>
          )}
          {checked === "Airflow on EKS" && (
            <Airflow
              expand={expand}
              setExpand={setExpand}
              setshowIam={setshowIam}
              skipOrches={skipOrches}
            />
          )}
          {checked === "OwnOrchestration" && (
            <OwnOrchestration
              expand={expand}
              setExpand={setExpand}
              selectedValue={selectedValue}
              setshowIam={setshowIam}
              ownorchestration={ownorchestration}
              changeOwnorchestration={changeOwnorchestration}
            />
          )}
        </div>
      </div>
      {showIam && (
        <IAM
          mwaaData={mwaaData}
          selectedValue={selectedValue}
          ownorchestration={ownorchestration}
        />
      )}
    </div>
  );
};

export default CreateOrchestration;