// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aggregatefields {
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 30px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.Heading {
  margin-right: 60%;
}

.Heading1 {
  display: flex;
  align-items: center;
}

.aggregatebutton {
  /* text-align: center; */
  background-color: #8b5ccc;
  color: white;
  /* border: none; */
  height: 36.56px;
  /* padding: 5px 27px; */
  /* font-size: 20px; */
  /* margin-bottom: 30px; */
  border-radius: 5px;
  font-size: 0.9rem;
}

.aggregatebutton:hover {
  background-color: rgba(54, 3, 125, 0.919);
  color: white;
}

.aggregatefieldbutton {
  /* display: flex; */
  /* padding: 10px; */
  /* grid-column-gap: 50px;
  margin-left: 20px; */
}
`, "",{"version":3,"sources":["webpack://./src/components/etlModule/aggregate/aggregateform.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,uBAAuB;EACvB,qBAAqB;EACrB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB;sBACoB;AACtB","sourcesContent":[".aggregatefields {\n  display: grid;\n  grid-template-columns: 100%;\n  grid-row-gap: 30px;\n  justify-content: center;\n  margin-top: 30px;\n  margin-bottom: 40px;\n}\n\n.Heading {\n  margin-right: 60%;\n}\n\n.Heading1 {\n  display: flex;\n  align-items: center;\n}\n\n.aggregatebutton {\n  /* text-align: center; */\n  background-color: #8b5ccc;\n  color: white;\n  /* border: none; */\n  height: 36.56px;\n  /* padding: 5px 27px; */\n  /* font-size: 20px; */\n  /* margin-bottom: 30px; */\n  border-radius: 5px;\n  font-size: 0.9rem;\n}\n\n.aggregatebutton:hover {\n  background-color: rgba(54, 3, 125, 0.919);\n  color: white;\n}\n\n.aggregatefieldbutton {\n  /* display: flex; */\n  /* padding: 10px; */\n  /* grid-column-gap: 50px;\n  margin-left: 20px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
