import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Sidebar from "../../sidebarComponent/Sidebar";
import LoginPage from "../../login/loginPage";

const Layout = ({ children }) => {
  const { instance } = useMsal();

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const sessionTimeout = 120 * 60 * 1000; // 2 hours, if user not access screen the session expired

    const sessionTimer = setTimeout(() => {
      setIsSessionExpired(true);
      instance.logout();
      sessionStorage.clear();
    }, sessionTimeout);

    const eventListener = () => {
      clearTimeout(sessionTimer);
      setTimeout(() => {
        setIsSessionExpired(true);
        instance.logout();
        sessionStorage.clear();
      }, sessionTimeout);
    };

    window.addEventListener("mousemove", eventListener);
    window.addEventListener("keydown", eventListener);

    return () => {
      clearTimeout(sessionTimer);
      window.removeEventListener("mousemove", eventListener);
      window.removeEventListener("keydown", eventListener);
    };
  }, []);

  const protectedRoute = sessionStorage.getItem("accessToken");

  return (
    <>
      { protectedRoute ?
        <div className="App">
          <Sidebar />
          <div className="component w-100">
            <div>{children}</div>
          </div>
        </div> : <LoginPage/>
      }
    </>
  );
};

export default Layout;
