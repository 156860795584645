import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import axios from "axios";
import { getUrl, postUrl } from "../api/Endpoint";
import { toast } from "react-toastify";
import { DiGitCommit } from "react-icons/di";
import { Tooltip as ReactTooltip } from "react-tooltip";

const MonacoEditor = ({ code, output, nodeBookName }) => {
  const [showModal, setShowmodal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [message, setMessage] = useState("Loading...");
  const [selectedProject, setSelectedProject] = useState("");
  const [details, setDetails] = useState("");
  const handleSubmit = async () => {
    setShowmodal(false);
    try {
      let res;
      let token = sessionStorage.getItem("accessToken");
      if (details.gitlab_project_id === null) {
        res = await axios.post(`${postUrl.notebookGithub}`, {
          file_path: `${details.source}${nodeBookName}`,
          owner: details.owner,
          repo: details.repo,
          branch: details.branch,
          token: details.token,
        }, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        console.log("in hub", {
          file_path: `${details.source}${nodeBookName}`,
          owner: details.owner,
          repo: details.repo,
          branch: details.branch,
          token: details.token,
        });
      } else {
        let token = sessionStorage.getItem("accessToken");
        res = await axios.post(`${postUrl.notebookGitlab}`, {
          file_path: `${details.source}${nodeBookName}`,
          project_id: details.project_id,
          token: details.token,
          branch: details.branch,
        }, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });
        console.log("in lab", {
          file_path: `${details.source}${nodeBookName}`,
          project_id: details.project_id,
          token: details.token,
          branch: details.branch,
        });
      }
      toast.success(`${res.data.message}!!!`);
      console.log("Final res", res);
    } catch (err) {
      console.log("Error in attaching to git hub", err);
    }
  };
  const closeModal = () => {
    setShowmodal(false);
    setSelectedProject("");
  };
  const fetchData = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let res = await axios.get(`${getUrl.projectReport}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      let a = res.data.data;
      let b = a?.filter((e) => e.status === "Active");
      let options = b.map((e) => ({
        label: e.project_name,
        value: e.project_id,
      }));
      if (options.length === 0) {
        setMessage("No project in  Active state");
      }
      setProjects(options);
    } catch (err) {
      setMessage("No Data");
      console.log("error in getting project data", err);
    }
  };
  const commitcode = () => {
    if (code === "") {
      toast.error("Create Pipeline");
    } else {
      setShowmodal(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleChange = (option) => {
    setSelectedProject(option);
  };
  const getGitDetails = async () => {
    if (selectedProject !== "") {
      setDetails("");
      try {
        let token = sessionStorage.getItem("accessToken");
        let res = await axios.get(
          `${getUrl.gitDetails}${selectedProject.value}`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        setDetails(res.data);
        console.log({ details });
      } catch (err) {
        console.log("Error in getting git details", err);
      }
    }
  };
  useEffect(() => {
    getGitDetails();
  }, [selectedProject]);
  const openModal = () => {
    if (output === "") {
      toast.warning("Make sure You Run the code", { autoClose: 2000 });
    } else {
      setShowmodal(true);
    }
  };
  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "5%",
      }}
    >
      <Editor
        height="50vh"
        // width="1420vh"
        language="python"
        value={code}
        options={{ readOnly: true }}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "87% 13%",
          marginLeft: "0.5%",
          height: "230px",
          marginTop: "10px",
          // gap: "3%",
        }}
      >
        <div
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            height: "230px",
            // background: "red",
            overflow: "scroll",
          }}
        >
          <p className="ml-3 mt-2" style={{ fontWeight: "bold" }}>
            OUTPUT :
          </p>
          <p className="ml-3" style={{ padding: "20px", paddingTop: "0px" }}>
            {output}
          </p>
        </div>
        <div
          style={{
            // maxWidth: "23vh",
            // backgroundColor: "blue",
            height: "230px",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            paddingBottom: "10px",
          }}
        >
          <button
            id="app-title"
            style={{
              padding: "11px 15px",
              borderRadius: "10px",
              backgroundColor: "rgb(3, 10, 29)",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
            onClick={commitcode}
          >
            <span className="title">
              {" "}
              <DiGitCommit fontSize={25} color="white" />
              Commit
            </span>
          </button>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        // keyboard={false}
        centered
      >
        {" "}
        {/* <form onSubmit={handleSubmit}> */}
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontWeight: "bold" }}
          >
            Commit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px 30px" }}>
          <Select
            className="mt-3 mb-3"
            placeholder="Select Project"
            onChange={handleChange}
            options={projects}
            value={selectedProject}
            noOptionsMessage={() => message}
          />
          {selectedProject === "" ? (
            ""
          ) : details !== "" ? (
            <>
              {" "}
              <h5 className="mt-3">Git Details</h5>
              <div className="mt-3 d-flex">
                <input
                  className="form-control w-50"
                  value={`NotebookName : ${nodeBookName}`}
                  readOnly
                />
                <input
                  className="form-control w-50 ml-3"
                  value={`Source : ${details?.source}${nodeBookName}`}
                  readOnly
                />
              </div>
              <div className="mt-3 d-flex">
                <input
                  className="form-control w-50"
                  value={`Branch : ${details?.branch}`}
                  readOnly
                />
                <input
                  className="form-control w-50 ml-3"
                  value={`Token : **********`}
                  readOnly
                />
              </div>{" "}
              {details?.gitlab_project_id === null ? (
                <div className="mt-3 d-flex">
                  <input
                    className="form-control w-50"
                    value={`Owner : ${details?.owner}`}
                    readOnly
                  />
                  <input
                    className="form-control w-50 ml-3"
                    value={`Repo : ${details?.repo}`}
                    readOnly
                  />
                </div>
              ) : (
                <div className="mt-3 d-flex">
                  <input
                    className="form-control w-50"
                    value={`ProjectID : ${details?.project_id}`}
                    readOnly
                  />
                </div>
              )}
              <p className="mt-4 mb-4">
                {" "}
                <span style={{ fontWeight: "bold" }}>NOTE:</span> This File will
                replace, if file name already exists in respective directory
              </p>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <button
            className="btn btn-success"
            type="submit"
            disabled={selectedProject === "" ? true : false}
            onClick={handleSubmit}
          >
            Save
          </button>
        </Modal.Footer>
        {/* </form> */}
      </Modal>
    </div>
  );
};

export default MonacoEditor;
