import React, { useState } from "react";
import "./etl.css";
import { Etlcontext } from "../context/ContextApi";
import CreateNotebook from "./CreateNotebook";
import ChooseNotebok from "./ChooseNotebok";
import { ColorRing, RotatingLines } from "react-loader-spinner";
import Select from "react-select";
import axios from "axios";
import { useForm, useController } from "react-hook-form";
import { MdVerified } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoCloseCircle, IoWarningOutline } from "react-icons/io5";
import { getUrl } from "../api/Endpoint";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetMyState } from "../../store/reducers/etlSlice";
import StepperProcess from "../stepperComponent/Stepper";
import { current } from "@reduxjs/toolkit";
import { ipAddress } from "../api/IpAddress";

const Etlindex = () => {
  const [Stepper, setStepper] = useState(false);
  const [etlData, setEtlData] = useState({
    notebookFlag: "Create Notebook",
    s3Path: "",
  });
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [noOptionsMessage, setNoOptionsMessage] = useState("Loading...");
  const [status, setStatus] = useState(false);
  const [clustersource, setClustersource] = useState({
    data: "",
  });
  const [cluster, setCluster] = useState({
    data: "",
  });
  const { control, handleSubmit, formState, setValue } = useForm({});
  const chooseCompute = useController({
    name: "chooseCompute",
    control,
    // defaultValue: cluster.data.value,
  });

  const chooseComputeSource = useController({
    name: "chooseComputeSource",
    control,
    // defaultValue: clustersource.data.value,
  });
  const handleSelectChange1 = (option) => {
    chooseComputeSource.field.onChange(option.value);
    clustersource.data = option;
    emrgetdata();
    cluster.data = "";
  };
  const handleSelectChange2 = (option, e) => {
    chooseCompute.field.onChange(option.value);
    cluster.data = option;
    console.log(clustersource, cluster);
    setShow(false);
    setStatus(false);
  };

  const statusCheck = async () => {
    let token = sessionStorage.getItem("accessToken");
    const result = await axios.get(
      `${getUrl.databricksStatus}${cluster.data.value}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      }
    );
    let res = result.data;
    console.log("statuscheck", res);
    return res;
  };
  useEffect(() => {
    dispatch(resetMyState());
  }, [dispatch]);
  useEffect(() => {
    // Start the interval to call the API every 1 second
    if (cluster.data !== "") {
      console.log("into useeffect");
      if (cluster.data !== "") {
        const interval = setInterval(async () => {
          // Call the API
          console.log("into interval");
          let response = await statusCheck();
          if (response === "RUNNING") {
            clearInterval(interval);
            setStatus(true);
          }
        }, 5000);
        return () => {
          clearInterval(interval);
        };
      }
    }

    // Clean up the interval on component unmount
  }, [cluster.data]);

  // useEffect(() => {
  //   if (clustersource.data.value === "Databricks") statusCheck();
  // }, [cluster.data]);

  async function emrgetdata() {
    setOptions([]);

    setNoOptionsMessage("Loading...");
    try {
      if (clustersource.data.value === "AWS-EMR-Classic") {
        let token = sessionStorage.getItem("accessToken");
        let res = await axios.get(
          `${ipAddress.dev}/emr_classic/listing_cluster/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let data = res.data.Clusters;
        const mappedOptions = data.map((option) => ({
          label: option.Name,
          value: option.Id,
        }));
        let result = [...mappedOptions];
        setOptions(result);
        if (result.length === 0) {
          setNoOptionsMessage("No Cluster Available");
        }
      }
      if (clustersource.data.value === "Databricks") {
        let token = sessionStorage.getItem("accessToken");
        let res2 = await axios.get(
          `${ipAddress.dev}/cluster/list_clusters/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let data1 = res2.data;
        const mappedOptions2 = Object.keys(data1).map((key) => ({
          label: key,
          value: data1[key],
        }));
        const filteredOptions = mappedOptions2?.filter(
          (item) => item.label !== ""
        );

        let result = [...filteredOptions];
        setOptions(result);
        if (result.length === 0) {
          setNoOptionsMessage("No Cluster Available");
        }
      }
      if (clustersource.data.value === "AWS-EMR-Serverless") {
        let token = sessionStorage.getItem("accessToken");
        let res3 = await axios.get(
          `${ipAddress.dev}/emr_serverless/listing_cluster_details/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let data1 = res3.data.applications;
        console.log("its serverless data", data1);
        const mappedOptions = data1.map((option) => ({
          label: option.name,
          value: option.id,
        }));
        let result = [...mappedOptions];
        setOptions(result);
        if (result.length === 0) {
          setNoOptionsMessage("No Cluster Available");
        }
      }
    } catch (err) {
      setNoOptionsMessage("No Cluster Available");
      console.log("err", err);
    }
  }

  const handleChooseNotebookChange = (event) => {
    const value = event.target.value;
    setEtlData(value);
    if (value === "Choose Notebook") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };
  const clustersourceOptions = [
    { value: "AWS-EMR-Classic", label: "AWS-EMR-Classic" },
    { value: "AWS-EMR-Serverless", label: "AWS-EMR-Serverless" },
    { value: "Databricks", label: "Databricks" },
  ];
  return (
    <div className="nodecontainer">
      <div className="Section">
        <div>
          <h5 onClick={() => setStepper((current) => !current)}>
            Data Pipeline
          </h5>
        </div>
        <div className="" style={{ display: "flex", flexDirection: "column" }}>
          <div
            className="mr-1 modulescluster"
            onClick={() => setShow((current) => !current)}
            style={{ width: "250px" }}
            // onBlur={() => setShow(false)}
          >
            <p style={{ height: "17px" }}>
              {cluster.data === "" ? (
                <p style={{ textAlign: "center", width: "100%" }} className="">
                  <span>
                    <IoWarningOutline
                      style={{ color: "#daa520", marginTop: "-2px" }}
                    />{" "}
                    &ensp; Attach a cluster
                  </span>
                </p>
              ) : (
                <p
                  style={{ whiteSpace: "nowrap", height: "10px" }}
                  className="d-flex align-items-center"
                >
                  {status ? (
                    <MdVerified color="green" />
                  ) : (
                    // <ColorRing
                    //   visible={true}
                    //   height="20"
                    //   width="20"
                    //   ariaLabel="blocks-loading"
                    //   wrapperStyle={{}}
                    //   wrapperClass="blocks-wrapper"
                    //   colors={[
                    //     "#ffe900",
                    //     "#ffe900",
                    //     "#ffe900",
                    //     "#ffe900",
                    //     "#ffe900",
                    //   ]}
                    // />
                    <RotatingLines
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                      strokeColor="red"
                    />
                  )}
                  &ensp;&ensp;
                  <div
                    style={{
                      width: "90%",
                      overflow: "scroll",
                      marginTop: "16px",
                    }}
                  >
                    {cluster.data.label}
                  </div>
                  {/* {show ? <IoCloseCircle /> : <IoMdArrowDropdown size={20} />} */}
                </p>
              )}
              {/* Attach a cluster */}
            </p>
            {show && (
              <div
                style={{
                  position: "absolute",
                  top: 60,
                  // left: -10,
                  right: 17,
                  width: "100%",
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "flex-end",
                  // alignItems: "center",
                  zIndex: 1000,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div style={{ width: "20%" }}>
                  <Select
                    placeholder="Select Cluster Source"
                    onChange={handleSelectChange1}
                    options={clustersourceOptions}
                    value={clustersource.data}
                  />
                  <div className="mt-2">
                    {" "}
                    <Select
                      placeholder="Select Cluster"
                      value={cluster.data}
                      noOptionsMessage={() => noOptionsMessage}
                      onChange={handleSelectChange2}
                      options={options}
                    />
                  </div>
                </div>
                {/* </button> */}
              </div>
            )}
          </div>
        </div>

        {/* <ul className="nav nav-tabs modules" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Pipeline
            </a>
          </li>
          <div
            className="mr-5 modulescluster"
            onClick={() => setShow((current) => !current)}
          >
            <p style={{ height: "5px" }}>Attach a cluster</p>
          </div>
        </ul> */}
      </div>

      {/* <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active "
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        > */}
      {/* <div className="NotebookRadiobutton">
        <div className="">
          <input
            type="radio"
            name="optradio"
            id="Create Notebook"
            value="Create Notebook"
            defaultChecked={true}
            onChange={handleChooseNotebookChange}
          />
          <label className="label" htmlFor="Create Notebook">
            Create Notebook
          </label>
        </div>
        <div className="">
          <input
            type="radio"
            name="optradio"
            id="Choose Notebook"
            value="Choose Notebook"
            onChange={handleChooseNotebookChange}
          />
          <label className="label" htmlFor="Choose Notebook">
            Choose Notebook
          </label>
        </div>
      </div> */}
      <div id="home">
        {" "}
        {/* {checked ? (
          <ChooseNotebok />
        ) : ( */}
        <Etlcontext.Provider value={{ etlData }}>
          <CreateNotebook clusterID={cluster.data.value} status={status} />{" "}
        </Etlcontext.Provider>
        {/* )} */}
      </div>
      {Stepper && <StepperProcess setStepper={setStepper} />}
    </div>
  );
};

export default Etlindex;
