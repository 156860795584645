import { React, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { useForm, useController, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import "../../components/createstorage/storage.css";
import {
  StorageChoosebucketValidation,
  StorageCreatebucketValidation,
} from "../validation";
import { MyContext } from "../context/ContextApi";
import axios from "axios";
import Choosecompute from "../choosecompute/choosecompute";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { MdRemoveCircleOutline } from "react-icons/md";
import { IoAddCircle } from "react-icons/io5";
import { ipAddress } from "../api/IpAddress";

const CreateStorage = () => {
  const { changeHandler, allValues } = useContext(MyContext);
  const [show, setShow] = useState(false);
  const [s3bucketsOptions, setS3bucketsOptions] = useState([]);
  const [pathoptions, setPathoptions] = useState();
  const [choosebucket, setchoosebucket] = useState(true);
  const [expand, setExpand] = useState(true);
  const [noOptionsMessage, setNoOptionsMessage] = useState("Loading...");
  const {
    register,
    control,
    handleSubmit,
    formState,
    setValue,
    isSubmitting,
    validateAllFields,
    getValues,
  } = useForm({
    resolver: zodResolver(
      allValues.BucketFlag === "Create Bucket"
        ? StorageCreatebucketValidation
        : StorageChoosebucketValidation
    ),
    mode: "all",
    defaultValues: {
      items: [{ Path: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "items",
    control,
  });

  const { errors } = formState;

  const s3buckets = useController({
    name: "s3buckets",
    control,
    defaultValue: allValues.exicistingBucket.value,
  });

  const path = useController({
    name: "Pathfield",
    control,
    // defaultValue: allValues.Pathfield.value,
  });

  const handleSelectChange = (option) => {
    s3buckets.field.onChange(option.value);
    allValues.exicistingBucket = option;
    setShow(false);
    allValues.Pathfield = "";
    setValue("Pathfield", undefined);
    setPathoptions([]);
  };
  const handleSelectChange2 = (option) => {
    path.field.onChange(option.value);
    allValues.Pathfield = option;
  };

  const handleChooseBucketChange = (event) => {
    const value = event.target.value;
    allValues.BucketFlag = value;
    if (value === "Choose Bucket") {
      setchoosebucket(true);
      setShow(false);
    } else {
      setchoosebucket(false);
      setShow(false);
    }
  };

  const handleSave = async (formValues, event) => {
    setExpand((current) => !current);
    let a = getValues("items");
    let res = a.map((obj) => obj.Path);
    // allValues.Path = res;
    allValues.Path = res;
    console.log("formValues", formValues);
    console.log("in storage", allValues);
    setShow(true);
  };

  const s3bucketsdata = async () => {
    setNoOptionsMessage("Loading...");
    try {
      let token = sessionStorage.getItem("accessToken");
      let response = await axios.get(`${ipAddress.dev}/s3/buckets/`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      let data = response.data;
      let result = data.map((option) => ({
        value: option,
        label: option,
      }));
      setS3bucketsOptions(result);
      if (result.length === 0) {
        setNoOptionsMessage("No data");
      }
    } catch (err) {
      setNoOptionsMessage("No data");
      console.log(err);
    }
  };

  const pathdata = async () => {
    setNoOptionsMessage("Loading...");
    try {
      let token = sessionStorage.getItem("accessToken");
      let response = await axios.get(
        `${ipAddress.dev}/s3/${allValues.exicistingBucket.value}/`, {
          headers : {
            Authorization : "Bearer "  + token //Pass Token
          }
        }
      );

      let data = response.data;
      let result = data?.map((option) => ({
        value: option,
        label: option,
      }));
      setPathoptions(result);
      if (result.length === 0) {
        setNoOptionsMessage("No data");
      }
    } catch (err) {
      console.log("Getting path err==>", err);
      setNoOptionsMessage("No data");
    }
  };

  useEffect(() => {
    s3bucketsdata();
  }, []);
  useEffect(() => {
    if (allValues.exicistingBucket !== "") pathdata();
  }, [allValues.exicistingBucket]);
  const updateValue = () => {
    append({
      Path: "",
    });
    if (show === true) {
      setShow(false);
    }
  };
  const deleteValue = (index) => {
    remove(index);
    let a = getValues("items");
    let res = a.map((obj) => obj.Path);
    // allValues.Path = res;
    allValues.Path = res.join(",");
    console.log("delete", allValues);
  };

  return (
    <div className="">
      <div className="formLayout" style={{ marginTop: "40px" }}>
        <div
          className="heading"
          data-toggle="collapse"
          data-target="#collapseExample1"
          onClick={() => setExpand((current) => !current)}
        >
          <h4>Storage</h4>
          {expand ? (
            <BsCaretUpFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample1"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          ) : (
            <BsCaretDownFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample1"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          )}
        </div>
        <form
          className="formDetails collapse show"
          id="collapseExample1"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="Radiobutton">
            <div className="form-check">
              <input
                type="radio"
                name="optradio"
                id="Choose Bucket"
                value="Choose Bucket"
                defaultChecked={true}
                onChange={handleChooseBucketChange}
              />
              <label className="label" htmlFor="Choose Bucket">
                Choose Bucket
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                name="optradio"
                id="Create Bucket"
                value="Create Bucket"
                onChange={handleChooseBucketChange}
              />
              <label className="label" htmlFor="Create Bucket">
                Create Bucket
              </label>
            </div>
          </div>

          <div className="">
            {choosebucket && (
              <div className="projectfields">
                <div>
                  <Select
                    placeholder="Select s3buckets"
                    value={allValues.exicistingBucket}
                    onChange={handleSelectChange}
                    options={s3bucketsOptions}
                    noOptionsMessage={() => noOptionsMessage}
                  ></Select>
                  {choosebucket && (
                    <>
                      <div style={{ color: "red" }}>
                        {errors.s3buckets?.message}
                      </div>
                    </>
                  )}
                </div>

                <div>
                  <Select
                    placeholder="Select Pathfield"
                    value={allValues.Pathfield}
                    onChange={handleSelectChange2}
                    options={pathoptions}
                    noOptionsMessage={() => noOptionsMessage}
                  ></Select>
                  {choosebucket && (
                    <>
                      <div style={{ color: "red" }}>
                        {errors.Pathfield?.message}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="storagefields">
              {!choosebucket && (
                <div className="secondfield">
                  <div>
                    <input
                      className="form-control"
                      {...register("Bucketname")}
                      placeholder="Enter BucketName (ProjectName_BucketName)"
                      onChange={changeHandler}
                      value={allValues.Bucketname}
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        lineHeight: "1.6em",
                      }}
                    >
                      <p style={{ marginTop: "1.5%", marginLeft: "5px" }}>
                        Bucket name must be globally unique and must not contain
                        spaces or uppercase letters.
                        <sup
                          style={{
                            marginTop: "5%",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          *
                        </sup>
                      </p>
                    </span>
                    {!choosebucket && (
                      <>
                        <div style={{ color: "red", height: `0px` }}>
                          {errors.Bucketname?.message}
                        </div>
                      </>
                    )}
                  </div>

                  {fields.map((field, index) => {
                    return (
                      <div className="d-flex " key={field.id}>
                        <div className="flex-grow-1">
                          <div>
                            <input
                              className="form-control"
                              {...register(`items.${index}.Path`)}
                              placeholder="Enter Path    (Eg: Path1/Path2/Path3)"
                            />
                          </div>
                          {!choosebucket && (
                            <>
                              <div style={{ color: "red" }} key={index}>
                                {/* {errors?.Path && errors?.Path[index]?.message} */}
                                {errors.items?.[index]?.Path && (
                                  <span>
                                    {errors.items[index].Path.message}
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <div className="">
                          {fields.length !== 1 && (
                            <MdRemoveCircleOutline
                              className="ml-3"
                              style={{ fontSize: "30px", color: "red" }}
                              onClick={() => deleteValue(index)}
                            />
                          )}
                          {fields.length - 1 === index && (
                            <IoAddCircle
                              className="ml-3"
                              onClick={updateValue}
                              style={{ fontSize: "30px", color: "#8b5ccc" }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="text-center">
              <button
                className="projectbutton"
                type="submit"
                // disabled={isSubmitting}
                data-toggle="collapse"
                data-target={`${
                  allValues.BucketFlag === "Choose Bucket"
                    ? allValues.exicistingBucket === "" ||
                      allValues.Pathfield === ""
                      ? ""
                      : "#collapseExample1"
                    : allValues.BucketFlag === "Create Bucket"
                    ? allValues.Bucketname === "" || allValues.Path === ""
                      ? ""
                      : "#collapseExample1"
                    : ""
                }`}
                // onClick={handleRevalidate}
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
      {!errors.Bucketname?.message &&
        !errors.Region?.message &&
        !errors.items && <div>{show && <Choosecompute />}</div>}
    </div>
  );
};

export default CreateStorage;
