// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.computefields {
  /* margin-top: 30px; */
  display: grid;
  grid-template-columns: 40%;
  grid-column-gap: 70px;
  grid-row-gap: 20px;
  /* padding: 10px; */
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
}

.computebutton {
  background-color: rgb(40, 121, 241);
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.computebutton:hover {
  background-color: rgba(54, 3, 125, 0.919);
  color: white;
}

.computeheading {
  margin-bottom: 30px;
  /* margin-top: 30px; */
  color: rgb(40, 121, 241);
}

.computeform {
  background-color: white !important;
  padding: 3% 0;
  width: 100%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
  /* width: 40%; */
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/createcompute/compute.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,0BAA0B;EAC1B,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,kCAAkC;EAClC,aAAa;EACb,WAAW;EACX,gDAAgD;EAChD,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".computefields {\n  /* margin-top: 30px; */\n  display: grid;\n  grid-template-columns: 40%;\n  grid-column-gap: 70px;\n  grid-row-gap: 20px;\n  /* padding: 10px; */\n  width: 100%;\n  justify-content: center;\n  margin-bottom: 10px;\n}\n\n.computebutton {\n  background-color: rgb(40, 121, 241);\n  border: none;\n  color: white;\n  padding: 10px 40px;\n  text-align: center;\n  font-size: 20px;\n  margin-bottom: 30px;\n  border-radius: 5px;\n}\n\n.computebutton:hover {\n  background-color: rgba(54, 3, 125, 0.919);\n  color: white;\n}\n\n.computeheading {\n  margin-bottom: 30px;\n  /* margin-top: 30px; */\n  color: rgb(40, 121, 241);\n}\n\n.computeform {\n  background-color: white !important;\n  padding: 3% 0;\n  width: 100%;\n  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */\n  transition: 0.3s;\n  /* width: 40%; */\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
