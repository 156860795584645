import { React, useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import axios, { all } from "axios";
import Select from "react-select";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import "../editcompute/editcompute.css";
import { ipAddress } from "../../api/IpAddress";

import { editContext } from "../../context/ContextApi";
import {
  EditAWSServerlessvalidation,
  EditAWSEMRClassicValidation,
  EditDataBricksValidation,
} from "../../validation";

const EditCompute = ({
  activeStep,
  setActiveStep,
  // disable,
  handleChange,
  checked,
}) => {
  const [computeSize, setComputeSize] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [clustersizeOptions, setClustersizeOptions] = useState([]);

  // const enable = !disable;

  const {
    changeHandler,
    allValues,
    clusterSource,
    clusterSize,
    changeclusterSource,
    changeclusterSize,
    setClusterSize,
  } = useContext(editContext);

  const { register, control, handleSubmit, formState, watch, setValue } =
    useForm({
      defaultValues: {
        run_time_version: allValues.run_time_version,
        Market: allValues.Market,
        Instancetype: allValues.Instancetype,
        SlaveInstanceCount: allValues.SlaveInstanceCount,
        MasterInstanceCount: allValues.MasterInstanceCount,
        AutoTerminationSeconds: allValues.AutoTerminationSeconds,
        MinWorkers: allValues.MinWorkers,
        MaxWorkers: allValues.MaxWorkers,
        NodeTypeId: allValues.NodeTypeId,
        DriverNodeTypeId: allValues.DriverNodeTypeId,
        AutoTerminationMin: allValues.AutoTerminationMin,
        ReleaseLabel: allValues.ReleaseLabel,
        MaxCPU: allValues.MaxCPU,
        MaxMemory: allValues.MaxMemory,
        MaxDisk: allValues.MaxDisk,
      },

      mode: "all",
      resolver: zodResolver(
        allValues.Clustersource === "AWS-EMR-Classic" &&
          EditAWSEMRClassicValidation,
        allValues.Clustersource === "Databricks" && EditDataBricksValidation,
        allValues.Clustersource === "AWS-EMR-Serverless" &&
          EditAWSServerlessvalidation
      ),
    });

  // const watchedValue = watch("Clustername");

  const { isDirty } = formState;
  const { errors } = formState;

  const [show, setShow] = useState(false);

  const clustersize = useController({
    name: "clustersize",
    control,
    // defaultValue: clusterSize.value,
  });

  const handleSelectChange2 = async (option) => {
    clustersize.field.onChange(option.value);
    allValues.Clustersize = option;
  };

  //  const [defaultclustersize,setDefaultclustersize]= useState(allValues.Clustersize)

  const fetchData = async () => {
    if (allValues.Clustersource !== "AWS-EMR-Serverless") {
      try {
        let token = sessionStorage.getItem("accessToken");
        let result = await axios.get(
          `${ipAddress.dev}/emr_classic/list_sml/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        setComputeSize(result.data);
        let data = result.data;
        const filteredData = data.filter(
          (item) => item.cluster_source === allValues.Clustersource
        );
        let result1 = filteredData.map((option) => ({
          label: option.cluster_size,
          value: option.size_id.toString(),
        }));
        let res = [...result1, ...clustersizeOptions];
        setClustersizeOptions(res);
      } catch (err) {
        console.log("compute err===>", err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showValue = () => {
    if (
      allValues.Clustersize.value !== "" &&
      allValues.Clustersource.value !== ""
    ) {
      let res = computeSize.find(
        (item) => item.cluster_size === allValues.Clustersize.label
      );
      setSelectedValue(res);
    }
  };

  useEffect(() => {
    showValue();
  }, [allValues.Clustersize]);

  const handleSave = async (formValues) => {
    console.log("Edit compute", allValues);
    console.log("save", formValues);
  };

  const handleInputChange = (e) => {
    setValue("Clustername", e.target.value, { shouldDirty: true });
    console.log(e.target.value);
  };

  return (
    <div className="formLayout" style={{ marginTop: "40px" }}>
      <div className="editformDetails">
        <div className="editheading">
          <h4>Edit Compute</h4>
        </div>
        <div className="editcomputeHeading">
          <div
            style={{
              display: "flex",
              marginTop: "2%",
              alignItems: "center" /* Align Items Vertically */,
              justifyContent: "space-between",
            }}
          ></div>
          <div className="">
            <form
              className="editcomputeform"
              onSubmit={handleSubmit(handleSave)}
            >
              <div className="editcomputefields">
                <div>
                  <div>
                    <input
                      className="form-control"
                      {...register("Clustersource")}
                      placeholder="Enter ClusterSource"
                      value={allValues.Clustersource}
                      // onChange={changeHandler}
                      readOnly
                    />
                  </div>
                  <div style={{ color: "red" }}>
                    {errors.clustersource?.message}
                  </div>
                </div>

                <div>
                  <input
                    className="form-control"
                    {...register("Clustername")}
                    placeholder="Enter ClusterName"
                    defaultValue={allValues.Clustername}
                    // onChange={changeHandler}
                    readOnly
                  />
                  <div style={{ color: "red" }}>
                    {errors.Clustername?.message}
                  </div>
                </div>

                {/* <p>Watched value: {watchedValue}</p> */}

                <div>
                  <Select
                    placeholder="Select ClusterSize"
                    value={allValues.Clustersize}
                    options={clustersizeOptions}
                    onChange={handleSelectChange2}
                    isDisabled
                  ></Select>

                  <div style={{ color: "red" }}>
                    {errors.clustersize?.message}
                  </div>
                </div>

                <>
                  {allValues.Clustersource === "Databricks" ? (
                    <>
                      {" "}
                      <div className="d-flex">
                        <div className="row">
                          <div className="col">
                            <label for="" style={{ fontSize: "10px" }}>
                              Run_time_version
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              {...register("run_time_version")}
                              value={allValues.run_time_version}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Min_workers
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("MinWorkers")}
                              value={allValues.MinWorkers}
                              onChange={changeHandler}
                            />
                            <div style={{ color: "red" }}>
                              {errors.MinWorkers?.message}
                            </div>
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Max_workers
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("MaxWorkers")}
                              value={allValues.MaxWorkers}
                              onChange={changeHandler}
                            />
                            <div style={{ color: "red" }}>
                              {errors.MaxWorkers?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div class="row">
                          <div className="col ">
                            <label
                              for=""
                              style={{ fontSize: "10px" }}
                              // className="mb-2"
                            >
                              Node_type_id
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              {...register("NodeTypeId")}
                              value={allValues.NodeTypeId}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Driver_Typeid
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("DriverNodeTypeId")}
                              value={allValues.DriverNodeTypeId}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px", width: "10px" }}>
                              Autotermination_min
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("AutoTerminationMin")}
                              placeholder=""
                              value={allValues.AutoTerminationMin}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>{" "}
                    </>
                  ) : (
                    <></>
                  )}

                  {allValues.Clustersource === "AWS-EMR-Classic" ? (
                    <>
                      <div className="d-flex">
                        <div class="row">
                          {/* <div className="col">
                            <label for="" style={{ fontSize: "10px" }}>
                              Run_time_version
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              {...register("run_time_version")}
                              value={allValues.run_time_version}
                              readOnly
                            />
                          </div> */}
                          <div className="col">
                            <label for="" style={{ fontSize: "10px" }}>
                              Market
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("Market")}
                              value={allValues.Market}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Instance_type
                            </label>
                            <input
                              type="text"
                              {...register("Instancetype")}
                              className="form-control"
                              placeholder=""
                              value={allValues.Instancetype}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div class="row">
                          <div className="col">
                            <label
                              for=""
                              style={{ width: "10px", fontSize: "10px" }}
                            >
                              Slave_instance_count
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("SlaveInstanceCount")}
                              value={allValues.SlaveInstanceCount}
                              onChange={changeHandler}
                            />
                            <div style={{ color: "red" }}>
                              {errors.SlaveInstanceCount?.message}
                            </div>
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              Master_instance_count
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("MasterInstanceCount")}
                              value={allValues.MasterInstanceCount}
                              onChange={changeHandler}
                            />
                            <div style={{ color: "red" }}>
                              {errors.MasterInstanceCount?.message}
                            </div>
                          </div>
                          <div className="col">
                            <label style={{ width: "10px", fontSize: "10px" }}>
                              Autotermination_seconds
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("AutoTerminationSeconds")}
                              value={allValues.AutoTerminationSeconds}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {allValues.Clustersource === "AWS-EMR-Serverless" ? (
                    <>
                      <div className="d-flex">
                        <div class="row">
                          <div className="col">
                            <label for="" style={{ fontSize: "10px" }}>
                              ReleaseLabel
                            </label>
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder=""
                              {...register("ReleaseLabel")}
                              value={allValues.ReleaseLabel}
                              readOnly
                            />
                          </div>
                          <div className="col">
                            <label for="" style={{ fontSize: "10px" }}>
                              MaxCpu
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("MaxCPU")}
                              value={allValues.MaxCPU}
                              onChange={changeHandler}
                            />
                            <div style={{ color: "red" }}>
                              {errors.MaxCPU?.message}
                            </div>
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              MaxMemory
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("MaxMemory")}
                              value={allValues.MaxMemory}
                              onChange={changeHandler}
                            />
                            <div style={{ color: "red" }}>
                              {errors.MaxMemory?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div class="row">
                          <div className="col">
                            <label
                              for=""
                              style={{ width: "10px", fontSize: "10px" }}
                            >
                              MaxDisk
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("MaxDisk")}
                              value={allValues.MaxDisk}
                              onChange={changeHandler}
                            />
                            <div style={{ color: "red" }}>
                              {errors.MaxDisk?.message}
                            </div>
                          </div>
                          <div className="col">
                            <label style={{ fontSize: "10px" }}>
                              AutoTerminationMinutes
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("AutoTerminationMin")}
                              value={allValues.AutoTerminationMin}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>

                <></>
              </div>

              <div className="text-center">
                <button className="editcomputebutton" type="submit">
                  {/* data-target={
                    AWSEMRClassic.formState.isValid ? AWSEMRServerless.formState.isValid ? AWSDataBricks.formState.isValid
                } */}
                  Save
                </button>
              </div>
              {/* {isDirty && <p>Input field value has changed.</p>} */}
              {console.log(isDirty)}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCompute;
