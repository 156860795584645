import { string, z } from "zod";

const instanceProfileArnRegex =
  /^arn:aws:iam::\d{12}:instance-profile\/[a-zA-Z0-9+=,.@_-]+$/;

const sizeRegex = /^\d+GB$/;

export const CostCenterValidation = z
  .object({
    Department: string().min(2, { message: "Department is Required" }),
    Businessunitowner: string().min(2, { message: "Business is Required" }),
    Sponsor: string().min(2, { message: "Sponsor is Required" }),
    Costcenter: string().min(2, { message: "Costcenter is Required" }),
    Contactemail: string().email(),
    Environment: string().min(2, { message: "Environment is Required" }),
  })
  .required();

export const ProjectValidation = z
  .object({
    Project: string({
      required_error: "Project Name is Required",
      invalid_type_error: "Project Name must be a string",
    }).min(3, { message: "Project Name is Required" }),
    costcenter: string({
      required_error: "costcenter is required",
      invalid_type_error: "costcenter must be a string",
    }),
    Region: string({
      required_error: "Region is Required",
      invalid_type_error: "Region must be a string",
    }),
  })
  .required();

export const chooseComputeValidation = z.object({
  chooseComputeSource: string({
    required_error: "Choose Cluster Source ",
    invalid_type_error: "Cluster Source must be a string",
  }),
  chooseCompute: string({
    required_error: "Choose Cluster ",
    invalid_type_error: "Cluster must be a string",
  }),
});

export const ComputeValidation = z
  .object({
    clustersource: string({
      required_error: "clustersource is Required",
      invalid_type_error: "clustersource must be a string",
    }),
    Clustername: string({
      required_error: "Clustername is Required",
      invalid_type_error: "Clustername must be a string",
    }).min(3, { message: "Invalid Clustername" }),
    clustersize: string({
      required_error: "clustersize is required",
      invalid_type_error: "clustersize must be a string",
    }),
  })
  .required();

export const ComputeValidationDatabricks = z
  .object({
    clustersource: string({
      required_error: "clustersource is Required",
      invalid_type_error: "clustersource must be a string",
    }),
    Clustername: string({
      required_error: "Clustername is Required",
      invalid_type_error: "Clustername must be a string",
    }).min(3, { message: "Invalid Clustername" }),
    clustersize: string({
      required_error: "clustersize is required",
      invalid_type_error: "clustersize must be a string",
    }),
    // profileARN: string()
    //   .min(1, { message: "ProfileARN is Required" })
    //   .regex(instanceProfileArnRegex, {
    //     message: "Invalid instance profile ARN.",
    //   }),
  })
  .required();

export const StorageChoosebucketValidation = z
  .object({
    s3buckets: string({
      required_error: "s3buckets is Required",
      invalid_type_error: "s3buckets must be a string",
    }),
    Pathfield: string({
      required_error: "Pathfield is Required",
      invalid_type_error: "Pathfield must be a string",
    }),
  })
  .required();

export const CustomComputeValidation = z
  .object({
    DatabricksClustersizeName: string().min(3, {
      message: "ClustersizeName is Required",
    }),
    DatabricksCustomARN: string()
      .min(3, {
        message: "ARN is Required",
      })
      .regex(instanceProfileArnRegex, {
        message: "Invalid instance profile ARN.",
      }),
    NodeTypeId: string().min(3, { message: " NodeTypeId is Required" }),

    AutoTerminationMin: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "AutoTerminationMinutes is Required",
          invalid_type_error: "AutoTerminationMinutes must be a integer",
        })
        .int()
        .min(10, "Input value should be at least 10.")
        .max(10000, "Input value should be at most 10000.")
    ),
    MinWorkers: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "MinWorkers is Required",
          invalid_type_error: "MinWorkers must be a Integer",
        })
        .int()
        .min(1, { message: "Input value should be at least 1." })
        .max(16, { message: "Input value should be at most 15." })
    ),
    MaxWorkers: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "MaxWorkers is Required",
          invalid_type_error: "MaxWorkers must be a Integer",
        })
        .int()
        .min(1, "Input value should be at least 1.")
        .max(16, "Input value should be at most 16.")
    ),
  })
  .refine((data) => data.MinWorkers <= data.MaxWorkers, {
    message: "Maxworkers should be greater than minworkers",
    path: ["MaxWorkers"],
  });

export const CustomComputeAWS = z
  .object({
    EMRClustersizeName: string().min(3, {
      message: " ClustersizeName is Required",
    }),
    Market: string().min(3, {
      message: " Market is Required",
    }),
    Instancetype: string().min(3, {
      message: "InstanceType is Required",
    }),
    SlaveInstanceCount: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "SlaveInstanceCount is Required",
          invalid_type_error: "SlaveInstanceCount must be a Integer",
        })
        .int()
        .min(1, "Input value should be at least 1.")
    ),
    MasterInstanceCount: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "MasterInstanceCount is Required",
          invalid_type_error: "MasterInstanceCount must be a Integer",
        })
        .int()
        .min(1, "Input value should be at least 1.")
    ),
    AutoTerminationSec: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "AutoTerminationSeconds is Required",
          invalid_type_error: "AutoTerminationSeconds must be a Integer",
        })
        .int()
        .min(60, "Input value should be at least 60.")
        .max(604800, "Input value should be at most 604800.")
    ),
  })
  .refine((data) => data.MasterInstanceCount < data.SlaveInstanceCount, {
    message: "SlaveInstanceCount should be greater than MasterInstanceCount",
    path: ["SlaveInstanceCount"],
  });

export const CustomAWSServerlessvalidation = z
  .object({
    // ServerlessClustersizeName: string().min(3, {
    //   message: " ClustersizeName is Required",
    // }),
    ReleaseLabel: string().min(3, {
      message: "ReleaseLabel is Required",
    }),
    MaxCPU: z.preprocess((input) => {
      const processed = z.string().transform(Number).safeParse(input);
      return processed.success ? processed.data : input;
    }, z.number().min(1, "MaxCPU  should be at least 1")),
    MaxMemory: z.preprocess((input) => {
      const processed = z.string().transform(Number).safeParse(input);
      return processed.success ? processed.data : input;
    }, z.number().min(1, "MaxMemory value should be at least 1")),
    MaxDisk: string().refine((value) => sizeRegex.test(value), {
      message: "Input should be in this 10GB format.",
    }),
    AutoTerminationMinutesServerless: z.preprocess((input) => {
      const processed = z.string().transform(Number).safeParse(input);
      return processed.success ? processed.data : input;
    }, z.number().min(1, "AutoTerminationMinutes value should be at least 1")),
  })
  .required();

// Orchestration
export const MwaaValidation = z.object({
  EnvName: string()
    .min(3, { message: "Environment Name is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    }),
  EnvClass: string({
    required_error: "Environment Class is required",
    invalid_type_error: "Environment Class must be a string",
  }),
  // s3Bucket: string({
  //   required_error: "Bucket is Required",
  //   invalid_type_error: "Bucket must be a string",
  // })
  //   .regex(/(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/, {
  //     message: "Invalid Bucket Name",
  //   })
  //   .min(6, { message: "Bucket Name should be greater than 6" })
  //   .max(63, { message: "Bucket Name  should be lesser than 63" }),
  // dagPath: string().min(3, { message: "Enter s3 Dag Path is Required" }),
  // s3Path: string().min(3, { message: "Path is required" }),
  Kmskey: string().min(3, { message: "KMS key is required" }),
});

// Orchestration VPC

export const VpcValidation = z.object({
  // vpcID: string().min(3, {
  //   message: "vpcID is Required",
  // }),
  // Subnet: z
  //   .array(
  //     z.object({
  //       value: z.string(),
  //       label: z.string(),
  //     })
  //   )
  //   .min(2, { message: "Please select at least two Subnets" })
  //   .max(2, { message: "Maximum you can select 2 Subnets" }),
  // Security: z
  //   .array(
  //     z.object({
  //       value: z.string(),
  //       label: z.string(),
  //     })
  //   )
  //   .min(1, { message: "Please select at least two option" })
  //   .max(5, { message: "Maximum you can select 5 Security Groups" }),
});

export const AirflowValidation = z.object({
  dagFolder: string().min(3, { message: "Dag s3path Name is Required" }),
  // dbConnection: z.string(),
  // url: z.string().min(3, "Input must be at least 3 characters").optional(),
  Remotelogging: z.string(),
  Path: z.string().min(3, "Input must be at least 3 characters").optional(),
  Environment: string({
    required_error: "Environment is Required",
    invalid_type_error: "Environment must be a string",
  }),
  Emailid: string().email(),
  Firstname: string().min(3, { message: "First Name is Required" }),
  Lastname: string().min(3, { message: "Last Name is Required" }),
  dockerFile: z
    .string()
    .min(3, { message: "dockerFile is Required" })
    .max(1000),
});

export const LoginValidation = z
  .object({
    EmailUsername: string()
      .min(3, {
        message: " Email / Username is Required",
      })
      .email({
        message: "Invalid Email",
      }),
    Password: string().min(8, {
      message: "Password is Required",
    }),
  })

  .required();

export const ChooseNotebookValidation = z.object({
  s3Path: string().min(3, {
    message: "s3 Path is Required",
  }),
});

////////* EditStorageValidation *\\\\\\\\

export const EditStorageChoosebucketValidation = z
  .object({
    s3buckets: string({
      required_error: "s3buckets is Required",
      invalid_type_error: "s3buckets must be a string",
    }),
    Pathfield: string({
      required_error: "Pathfield is Required",
      invalid_type_error: "Pathfield must be a string",
    }),
  })
  .required();

export const EditStoragePathbucketValidation = z
  .object({
    Path: string({
      required_error: "Path is Required",
      invalid_type_error: "Path must be a string",
    }).min(3, { message: "Invalid Path" }),
  })
  .required();

export const StorageCreatebucketValidation = z.object({
  Bucketname: string({
    required_error: "Bucket is Required",
    invalid_type_error: "Bucket must be a string",
  })
    .regex(/(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/, {
      message: "Invalid Bucket Name",
    })
    .min(6, { message: "Bucket Name should be greater than 6" })
    .max(63, { message: "Bucket Name  should be lesser than 63" }),

  items: z.array(
    z.object({
      Path: z.string().nonempty("Path is required"),
    })
  ),
});

//////* EditComputeValidation *\\\\\\\

export const EditAWSServerlessvalidation = z
  .object({
    MaxCPU: z.preprocess((input) => {
      const processed = z.string().transform(Number).safeParse(input);
      return processed.success ? processed.data : input;
    }, z.number().min(1, "MaxCPU  should be at least 1")),
    MaxMemory: z.preprocess((input) => {
      const processed = z.string().transform(Number).safeParse(input);
      return processed.success ? processed.data : input;
    }, z.number().min(1, "MaxMemory value should be at least 1")),
    MaxDisk: string().refine((value) => sizeRegex.test(value), {
      message: "Input should be in this 10GB format.",
    }),
  })
  .required();

export const EditAWSEMRClassicValidation = z
  .object({
    SlaveInstanceCount: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "SlaveInstanceCount is Required",
          invalid_type_error: "SlaveInstanceCount must be a Integer",
        })
        .int()
        .min(1, "Input value should be at least 1.")
    ),

    MasterInstanceCount: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "MasterInstanceCount is Required",
          invalid_type_error: "MasterInstanceCount must be a Integer",
        })
        .int()
        .min(1, "Input value should be at least 1.")
    ),
  })
  .refine((data) => data.MasterInstanceCount < data.SlaveInstanceCount, {
    message: "SlaveInstanceCount should be greater than MasterInstanceCount",
    path: ["SlaveInstanceCount"],
  });

export const EditDataBricksValidation = z
  .object({
    MinWorkers: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "MinWorkers is Required",
          invalid_type_error: "MinWorkers must be a Integer",
        })
        .int()
        .min(1, { message: "Input value should be at least 1." })
        .max(16, { message: "Input value should be at most 15." })
    ),

    MaxWorkers: z.preprocess(
      (input) => {
        const processed = z.string().transform(Number).safeParse(input);
        return processed.success ? processed.data : input;
      },
      z
        .number({
          required_error: "MaxWorkers is Required",
          invalid_type_error: "MaxWorkers must be a Integer",
        })
        .int()
        .min(1, "Input value should be at least 1.")
        .max(16, "Input value should be at most 16.")
    ),
  })
  .refine((data) => data.MinWorkers <= data.MaxWorkers, {
    message: "Maxworkers should be greater than minworkers",
    path: ["MaxWorkers"],
  });

export const cicdGithubValidation = z.object({
  cicdData: z.array(
    z.object({
      Project: z.object({
        value: z.number(),
        label: z.string(),
      }),
      Repository: z.string().nonempty("Repository is required"),
      Branch: z.string().nonempty("Branch is required"),
      Token: z.string().nonempty("Token is required"),
      Username: z.string().nonempty("Username is required"),
      Filename: z.string().optional(),
      Status: z
        .array(
          z.object({
            value: z.string(),
            label: z.string(),
          })
        )
        .min(1, { message: "Select Atleast one option" }),
      Source: z.string().nonempty("Source is required"),
      Destination: z.string().nonempty("Destination is required"),
      Flowdata: z.object({
        value: z.string(),
        label: z.string(),
      }),
    })
  ),
});
export const cicdGitlabValidation = z.object({
  cicdData: z.array(
    z.object({
      Project: z.object({
        value: z.number(),
        label: z.string(),
      }),
      ProjectID: z.string().nonempty("ProjectID is required"),
      // Repository: z.string().nonempty("Repository is required"),
      Branch: z.string().nonempty("Branch is required"),
      PersonalToken: z.string().nonempty("Personal Token is required"),
      // RegistrationToken: z.string().nonempty("Registration Token is required"),
      Filename: z.string().optional(),
      Status: z
        .array(
          z.object({
            value: z.string(),
            label: z.string(),
          })
        )
        .min(1, { message: "Select Atleast one option" }),
      Source: z.string().nonempty("Source is required"),
      Destination: z.string().nonempty("Destination is required"),
      Flowdata: z.object({
        value: z.string(),
        label: z.string(),
      }),
    })
  ),
});
export const s3BucketValidation = z.object({
  Path: string({
    required_error: "Path is Required",
    invalid_type_error: "Path must be a string",
  }).min(3, { message: "Path is Required" }),
  TaskFileName: string({
    required_error: "TaskFileName is Required",
    invalid_type_error: "TaskFileName must be a string",
  })
    .min(3, { message: "TaskFileName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  FileType: z.string(),
});

export const PostgresqlValidation = z.object({
  JdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  DriverName: string({
    required_error: "DriverName is Required",
    invalid_type_error: "DriverName must be a string",
  }).min(3, { message: "DriverName is Required" }),
  UserName: z.string().min(3, { message: "UserName is Required" }).optional(),
  Password: z.string().min(3, { message: "Password is Required" }).optional(),
  TableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  Credential: string({
    required_error: "Credential is Required",
    invalid_type_error: "Credential must be a string",
  }).min(3, { message: "Credential is Required" }),
  TaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  ColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  CdcOption: z.string().optional(),
  // Vaultpath: string({
  //   required_error: "Vault path is Required",
  //   invalid_type_error: "Vault path must be a string",
  // }).min(3, { message: "Vault path is Required" }),
});

export const Postgresql1Validation = z.object({
  JdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  DriverName: string({
    required_error: "DriverName is Required",
    invalid_type_error: "DriverName must be a string",
  }).min(3, { message: "DriverName is Required" }),
  UserName: z
    .string({
      required_error: "UserName is Required",
      invalid_type_error: "UserName must be a string",
    })
    .min(3, { message: "UserName is Required" })
    .optional(),
  Password: string({
    required_error: "Password is Required",
    invalid_type_error: "Password must be a string",
  })
    .min(3, { message: "Password is Required" })
    .optional(),
  TableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  TaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  CdcOption: z.string().optional(),
  ColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  Vaultpath: string({
    required_error: "Vault path is Required",
    invalid_type_error: "Vault path must be a string",
  }).min(3, { message: "Vault path is Required" }),
  VaultOptions: z.string(),
});

export const RedShiftValidation = z.object({
  RedShiftJdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  RedShiftTempDirectory: string({
    required_error: "TempDirectory is Required",
    invalid_type_error: "TempDirectory must be a string",
  }).min(3, { message: "TempDirectory is Required" }),
  RedShiftUserName: z
    .string()
    .min(3, { message: "UserName is Required" })
    .optional(),
  RedShiftPassword: z
    .string()
    .min(3, { message: "Password is Required" })
    .optional(),
  RedShiftTableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  RedShiftCredential: string({
    required_error: "Credential is Required",
    invalid_type_error: "Credential must be a string",
  }).min(3, { message: "Credential is Required" }),
  RedShiftTaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  RedShiftColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  RedShiftCdcOption: z.string().optional(),
  // Vaultpath: string({
  //   required_error: "Vault path is Required",
  //   invalid_type_error: "Vault path must be a string",
  // }).min(3, { message: "Vault path is Required" }),
});

export const RedShift1Validation = z.object({
  RedShiftJdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  RedShiftTempDirectory: string({
    required_error: "TempDirectory is Required",
    invalid_type_error: "TempDirectory must be a string",
  }).min(3, { message: "TempDirectory is Required" }),
  RedShiftUserName: z
    .string({
      required_error: "UserName is Required",
      invalid_type_error: "UserName must be a string",
    })
    .min(3, { message: "UserName is Required" })
    .optional(),
  RedShiftPassword: string({
    required_error: "Password is Required",
    invalid_type_error: "Password must be a string",
  })
    .min(3, { message: "Password is Required" })
    .optional(),
  RedShiftTableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  RedShiftTaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  RedShiftCdcOption: z.string().optional(),
  RedShiftColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  RedShiftVaultpath: string({
    required_error: "Vault path is Required",
    invalid_type_error: "Vault path must be a string",
  }).min(3, { message: "Vault path is Required" }),
  RedShiftVaultOptions: z.string(),
});

export const MetaStoreValidation = z.object({
  MetaPath: string({
    required_error: "Path is Required",
    invalid_type_error: "Path must be a string",
  }).min(3, { message: "Path is Required" }),
  MetaTaskFileName: string({
    required_error: "TaskFileName is Required",
    invalid_type_error: "TaskFileName must be a string",
  })
    .min(3, { message: "TaskFileName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  // FileType: z.string(),
});

export const MetaValidationForPath = z.object({
  MetaPath: string({
    required_error: "Path is Required",
    invalid_type_error: "Path must be a string",
  }).min(3, { message: "Path is Required" }),
});

export const MetaValidationForTable = z.object({
  Table: string({
    required_error: "Table is Required",
    invalid_type_error: "Table must be a string",
  }).min(3, { message: "Table name is Required" }),
});

export const SqlValidation = z.object({
  JdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  DriverName: string({
    required_error: "DriverName is Required",
    invalid_type_error: "DriverName must be a string",
  }).min(3, { message: "DriverName is Required" }),
  UserName: string({
    required_error: "UserName is Required",
    invalid_type_error: "UserName must be a string",
  }).min(3, { message: "UserName is Required" }),
  Password: string({
    required_error: "Password is Required",
    invalid_type_error: "Password must be a string",
  }).min(3, { message: "Password is Required" }),
  TableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  Credential: string({
    required_error: "Credential is Required",
    invalid_type_error: "Credential must be a string",
  }).min(3, { message: "Credential is Required" }),
  TaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  ColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  CdcOption: z.string(),
  Condition: z.string().min(3, { message: "Condition is Required" }).optional(),
});

export const Sql1Validation = z.object({
  JdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  DriverName: string({
    required_error: "DriverName is Required",
    invalid_type_error: "DriverName must be a string",
  }).min(3, { message: "DriverName is Required" }),
  UserName: string({
    required_error: "UserName is Required",
    invalid_type_error: "UserName must be a string",
  }).min(3, { message: "UserName is Required" }),
  Password: string({
    required_error: "Password is Required",
    invalid_type_error: "Password must be a string",
  }).min(3, { message: "Password is Required" }),
  TableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  TaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  ColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  CdcOption: z.string(),
  Condition: z.string().min(3, { message: "Condition is Required" }).optional(),
});

export const OracleValidation = z.object({
  JdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  DriverName: string({
    required_error: "DriverName is Required",
    invalid_type_error: "DriverName must be a string",
  }).min(3, { message: "DriverName is Required" }),
  UserName: string({
    required_error: "UserName is Required",
    invalid_type_error: "UserName must be a string",
  }).min(3, { message: "UserName is Required" }),
  Password: string({
    required_error: "Password is Required",
    invalid_type_error: "Password must be a string",
  }).min(3, { message: "Password is Required" }),
  TableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  Credential: string({
    required_error: "Credential is Required",
    invalid_type_error: "Credential must be a string",
  }).min(3, { message: "Credential is Required" }),
  TaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  ColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  CdcOption: z.string(),
  Condition: z.string().min(3, { message: "Condition is Required" }).optional(),
});

export const Oracle1Validation = z.object({
  JdbcUrl: string({
    required_error: "JdbcUrl is Required",
    invalid_type_error: "JdbcUrl must be a string",
  }).min(3, { message: "JdbcUrl is Required" }),
  DriverName: string({
    required_error: "DriverName is Required",
    invalid_type_error: "DriverName must be a string",
  }).min(3, { message: "DriverName is Required" }),
  UserName: string({
    required_error: "UserName is Required",
    invalid_type_error: "UserName must be a string",
  }).min(3, { message: "UserName is Required" }),
  Password: string({
    required_error: "Password is Required",
    invalid_type_error: "Password must be a string",
  }).min(3, { message: "Password is Required" }),
  TableName: string({
    required_error: "TableName is Required",
    invalid_type_error: "TableName must be a string",
  }).min(3, { message: "TableName is Required" }),
  TaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  ColumnName: z
    .string()
    .min(1, { message: "ColumnName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    })
    .optional(),
  CdcOption: z.string(),
  Condition: z.string().min(3, { message: "Condition is Required" }).optional(),
});

export const ConcatValidation = z
  .object({
    TargetName: string({
      required_error: "TargetName is Required",
      invalid_type_error: "TargetName must be a string",
    }).min(3, { message: "TargetName is Required" }),
    AddColumn1: string({
      required_error: "AddColumn1 is Required",
      invalid_type_error: "AddColumn1 must be a string",
    }).min(3, { message: "AddColumn1 is Required" }),
    AddColumn2: string({
      required_error: "AddColumn2 is Required",
      invalid_type_error: "AddColumn2 must be a string",
    }).min(3, { message: "AddColumn2 is Required" }),
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
  })
  .required();

export const AddColumnValidation = z.object({
  TargetName: string({
    required_error: "TargetName is Required",
    invalid_type_error: "TargetName must be a string",
  }).min(3, { message: "TargetName is Required" }),
  Condition: string({
    required_error: "TargetColumn is Required",
    invalid_type_error: "TargetColumn must be a string",
  }).min(3, { message: "Condition is Required" }),
  TaskName: string({
    required_error: "TaskName is Required",
    invalid_type_error: "TaskName must be a string",
  })
    .min(3, { message: "TaskName is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    }),
  AddColumnOperator: z.string().nonempty("Please select an option"),
  Value: string({
    required_error: "Value is Required",
    invalid_type_error: "Value must be a string",
  }).min(1, { message: "Value is Required" }),
});

export const RenameColumnValidation = z
  .object({
    TargetName: string({
      required_error: "TargetName is Required",
      invalid_type_error: "TargetName must be a string",
    }).min(3, { message: "TargetName is Required" }),
    Oldcolumn: string({
      required_error: "Oldcolumn is Required",
      invalid_type_error: "Oldcolumn must be a string",
    }).min(3, { message: "Oldcolumn is Required" }),
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
  })
  .required();

export const FilterValidation = z
  .object({
    TargetColumn: string({
      required_error: "TargetColumn is Required",
      invalid_type_error: "TargetColumn must be a string",
    }).min(3, { message: "TargetColumn is Required" }),
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    FilterOperator: z.string().nonempty("Please select an option"),
    Value: string({
      required_error: "Value is Required",
      invalid_type_error: "Value must be a string",
    }).min(1, { message: "Value is Required" }),
  })
  .required();

export const PivotValidation = z
  .object({
    GroupbyColumn: string({
      required_error: "GroupbyColumn is Required",
      invalid_type_error: "GroupbyColumn must be a string",
    }).min(3, { message: "GroupbyColumn is Required" }),
    PivotColumn: string({
      required_error: "PivotColumn is Required",
      invalid_type_error: "PivotColumn must be a string",
    }).min(3, { message: "PivotColumn is Required" }),
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    AggregateColumn: string({
      required_error: "AggregateColumn is Required",
      invalid_type_error: "AggregateColumn must be a string",
    }).min(3, { message: "AggregateColumn is Required" }),
    AggregateType: z.string().nonempty("Please select an option"),
  })
  .required();

export const DropDuplicateValidation = z
  .object({
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
  })
  .required();

export const DropColumnValidation = z
  .object({
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    Columns: string({
      required_error: "Columns is Required",
      invalid_type_error: "Columns must be a string",
    }).min(3, { message: "Columns is Required" }),
  })
  .required();

export const SelectColumnValidation = z
  .object({
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    Columns: string({
      required_error: "Columns is Required",
      invalid_type_error: "Columns must be a string",
    }).min(3, { message: "Columns is Required" }),
  })
  .required();

export const OrderByValidation = z
  .object({
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    Columns: string({
      required_error: "Columns is Required",
      invalid_type_error: "Columns must be a string",
    }).min(3, { message: "Columns is Required" }),
  })
  .required();

export const AggregateFormValidation = z
  .object({
    TargetColumn: string({
      required_error: "TargetColumn is Required",
      invalid_type_error: "TargetColumn must be a string",
    })
      .min(3, { message: "TargetColumn is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    AggregateColumn: string({
      required_error: "AggregateColumn is Required",
      invalid_type_error: "AggregateColumn must be a string",
    })
      .min(3, { message: "AggregateColumn is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    GroupBy: string({
      required_error: "GroupBy is Required",
      invalid_type_error: "GroupBy must be a string",
    })
      .min(3, { message: "GroupBy is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    Aggregate: z.string().nonempty("Please select an option"),
  })
  .required();

export const JoinFormValidation = z
  .object({
    TaskName: string({
      required_error: "TaskName is Required",
      invalid_type_error: "TaskName must be a string",
    })
      .min(3, { message: "TaskName is Required" })
      .refine((val) => !/\s/.test(val), {
        message: "Value cannot contain spaces",
      }),
    SourceColumn1: string({
      required_error: "SourceColumn1 is Required",
      invalid_type_error: "SourceColumn1 must be a string",
    }).min(3, { message: "SourceColumn1 is Required" }),
    SourceColumn2: string({
      required_error: "SourceColumn2 is Required",
      invalid_type_error: "SourceColumn2 must be a string",
    }).min(3, { message: "SourceColumn2 is Required" }),
    JoinType: z.string().nonempty("Please select an option"),
  })
  .required();

export const GitlabValidation = z
  .object({
    labtoken: string({
      required_error: "RegistrationToken is Required",
      invalid_type_error: "RegistrationToken must be a string",
    }).min(3, { message: "RegistrationToken is Required" }),
    labrepoLink: string({
      required_error: "BaseLocation is Required",
      invalid_type_error: "BaseLocation must be a string",
    }).min(3, { message: "BaseLocation is Required" }),
    labPemkey: string({
      required_error: "PemKey is Required",
      invalid_type_error: "PemKey must be a string",
    }).min(3, { message: "PemKey is Required" }),
    labEc2: string({
      required_error: "EC 2 User is Required",
      invalid_type_error: "EC 2 User must be a string",
    }).min(3, { message: "EC 2 User is Required" }),
    labIp: string({
      required_error: "HostPublicIp is Required",
      invalid_type_error: "HostPublicIp must be a string",
    }).min(3, { message: "HostPublicIp is Required" }),
    GitlabRegion: z.string().nonempty("Please select an option"),
    GitlabArchitecture: z.string().nonempty("Please select an option"),
    Environment: z.string().nonempty("Please select an option"),
  })
  .required();

export const GithubValidation = z
  .object({
    hubtoken: string({
      required_error: "RegistrationToken is Required",
      invalid_type_error: "RegistrationToken must be a string",
    }).min(3, { message: "RegistrationToken is Required" }),
    hubrepoLink: string({
      required_error: "Repositorylink is Required",
      invalid_type_error: "Repositorylink must be a string",
    }).min(3, { message: "Repositorylink is Required" }),
    hubPemkey: string({
      required_error: "PemKey is Required",
      invalid_type_error: "PemKey must be a string",
    }).min(3, { message: "PemKey is Required" }),
    hubEc2: string({
      required_error: "EC 2 User is Required",
      invalid_type_error: "EC 2 User must be a string",
    }).min(3, { message: "EC 2 User is Required" }),
    hubIp: string({
      required_error: "HostPublicIp is Required",
      invalid_type_error: "HostPublicIp must be a string",
    }).min(3, { message: "HostPublicIp is Required" }),
    GithubRegion: z.string().nonempty("Please select an option"),
    GithubArchitecture: z.string().nonempty("Please select an option"),
  })
  .required();

export const CodecommitValidation = z
  .object({
    codePemkey: string({
      required_error: "PemKey is Required",
      invalid_type_error: "PemKey must be a string",
    }).min(3, { message: "PemKey is Required" }),
    codeEc2: string({
      required_error: "EC 2 User is Required",
      invalid_type_error: "EC 2 User must be a string",
    }).min(3, { message: "EC 2 User is Required" }),
    codeIp: string({
      required_error: "HostPublicIp is Required",
      invalid_type_error: "HostPublicIp must be a string",
    }).min(3, { message: "HostPublicIp is Required" }),
    CodecommitRegion: z.string().nonempty("Please select an option"),
  })
  .required();

export const NullCheckValidtion = z.object({
  TaskName: string({
    required_error: "Taskname is Required",
    invalid_type_error: "Taskname must be a string",
  })
    .min(3, { message: "Taskname is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    }),
  ColumnName: string({
    required_error: "ColumnName is Required",
    invalid_type_error: "ColumnName must be a string",
  }).min(1, { message: "ColumnName is Required" }),
  PrimaryKey: string({
    required_error: "PrimaryKey is Required",
    invalid_type_error: "PrimaryKey must be a string",
  }).min(1, { message: "PrimaryKey is Required" }),
  s3: z.string().optional(),
  S3Output: string({
    required_error: "S3Output is Required",
    invalid_type_error: "S3Output must be a string",
  })
    .min(3, { message: "S3Output is Required" })
    .optional(),
});

export const CustomCheckValidation = z.object({
  TaskName: string({
    required_error: "Taskname is Required",
    invalid_type_error: "Taskname must be a string",
  })
    .min(3, { message: "Taskname is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    }),
  Sqlcondition: string({
    required_error: "Sqlcondition is Required",
    invalid_type_error: "Sqlcondition must be a string",
  }).min(3, { message: "Sqlcondition is Required" }),
  PrimaryKey: string({
    required_error: "PrimaryKey is Required",
    invalid_type_error: "PrimaryKey must be a string",
  }).min(1, { message: "PrimaryKey is Required" }),
  s3: z.string(),
  S3Output: string({
    required_error: "S3Output is Required",
    invalid_type_error: "S3Output must be a string",
  })
    .min(3, { message: "S3Output is Required" })
    .optional(),
});

export const LengthCheckValidation = z.object({
  TaskName: string({
    required_error: "Taskname is Required",
    invalid_type_error: "Taskname must be a string",
  })
    .min(3, { message: "Taskname is Required" })
    .refine((val) => !/\s/.test(val), {
      message: "Value cannot contain spaces",
    }),
  ColumnName: string({
    required_error: "ColumnName is Required",
    invalid_type_error: "ColumnName must be a string",
  }).min(1, { message: "ColumnName is Required" }),
  Sqlcondition: string({
    required_error: "Sqlcondition is Required",
    invalid_type_error: "Sqlcondition must be a string",
  }).min(3, { message: "Sqlcondition is Required" }),
  PrimaryKey: string({
    required_error: "PrimaryKey is Required",
    invalid_type_error: "PrimaryKey must be a string",
  }).min(1, { message: "PrimaryKey is Required" }),
  s3: z.string(),
  S3Output: string({
    required_error: "S3Output is Required",
    invalid_type_error: "S3Output must be a string",
  })
    .min(3, { message: "S3Output is Required" })
    .optional(),
});

export const chooseIamValidation = z.object({
  Role: string({
    required_error: "Role is required",
    invalid_type_error: "Role must be a string",
  }),
});

export const createIamValidation = z.object({
  RoleName: string({
    required_error: "Role Name  is Required",
    invalid_type_error: "Role Name must be a string",
  }).min(3, { message: "Role Name  is Required" }),
});
