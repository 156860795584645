// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storagefields {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 40%;
  grid-column-gap: 70px;
  grid-row-gap: 20px;
  padding: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

.storagebutton {
  background-color: rgb(40, 121, 241);
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.storagebutton:hover {
  background-color: rgba(54, 3, 125, 0.919);
  color: white;
}

.firstfield {
  display: grid;
  grid-row-gap: 20px;
}

.secondfield {
  display: grid;
  grid-row-gap: 30px;
}

.label {
  padding: 10px;
}

.Radiobutton {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
.line {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  margin-top: 30px;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/createstorage/storage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,0BAA0B;EAC1B,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX","sourcesContent":[".storagefields {\n  margin-top: 30px;\n  display: grid;\n  grid-template-columns: 40%;\n  grid-column-gap: 70px;\n  grid-row-gap: 20px;\n  padding: 10px;\n  justify-content: center;\n  margin-bottom: 10px;\n}\n\n.storagebutton {\n  background-color: rgb(40, 121, 241);\n  border: none;\n  color: white;\n  padding: 10px 40px;\n  text-align: center;\n  font-size: 20px;\n  margin-bottom: 30px;\n  border-radius: 5px;\n}\n\n.storagebutton:hover {\n  background-color: rgba(54, 3, 125, 0.919);\n  color: white;\n}\n\n.firstfield {\n  display: grid;\n  grid-row-gap: 20px;\n}\n\n.secondfield {\n  display: grid;\n  grid-row-gap: 30px;\n}\n\n.label {\n  padding: 10px;\n}\n\n.Radiobutton {\n  display: flex;\n  margin-top: 30px;\n  justify-content: center;\n}\n.line {\n  display: grid;\n  grid-template-columns: 45% 10% 45%;\n  margin-top: 30px;\n}\n\n.text-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
