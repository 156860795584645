import React from "react";

export const Line1 = () => {
  return <hr style={{ borderTop: "1px solid black", width: "100%" }} />;
};

export const Line2 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "470%" }} />;
};

export const Line3 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "295%" }} />;
};

export const Line4 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "120%" }} />;
};

export const Line5 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "320%" }} />;
};

export const Line6 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "670%" }} />;
};

export const Line7 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "480%" }} />;
};

export const Line8 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "250%" }} />;
};

export const Line9 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "350%" }} />;
};

export const Line10 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "780%" }} />;
};

export const Line11 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "280%" }} />;
};

export const Line12 = () => {
  return <hr style={{ borderTop: "3px solid black", width: "400%" }} />;
};
