// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper {
  position: absolute;
  /* height: 5vh; */
  padding: 5px 5px;
  /* background-color: red; */
  border-radius: 0 0 5px 0px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.stepperClose {
  position: relative;

  /* margin-top: 10px; */
  border: none;
  box-shadow: inset;
  /* margin-left: ; */

  /* height: 10vh; */
}
`, "",{"version":3,"sources":["webpack://./src/components/stepperComponent/Stepper.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,2BAA2B;EAC3B,0BAA0B;EAC1B,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;;EAElB,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;;EAEnB,kBAAkB;AACpB","sourcesContent":[".stepper {\n  position: absolute;\n  /* height: 5vh; */\n  padding: 5px 5px;\n  /* background-color: red; */\n  border-radius: 0 0 5px 0px;\n  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);\n}\n\n.stepperClose {\n  position: relative;\n\n  /* margin-top: 10px; */\n  border: none;\n  box-shadow: inset;\n  /* margin-left: ; */\n\n  /* height: 10vh; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
