import React, { useState } from "react";
import {
  BsDatabaseFillDown,
  BsDatabaseFillUp,
  BsThreeDots,
} from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import { MdDelete, MdSettingsSuggest, MdVerified } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteEdges,
  deleteNodes,
  deleteTransform,
} from "../../store/reducers/etlSlice";
import { useEffect } from "react";
import { IoAlertCircleSharp } from "react-icons/io5";

const Transform = ({
  id,
  setNodes,
  setEdges,
  edges,
  setData,
  setAddColumn,
  node,
  setSelected,
}) => {
  const dispatch = useDispatch();
  const EtlDetails = useSelector((state) => state.etl);
  const [isHovered, setIsHovered] = useState(false);
  const [removeNode, setRemoveNode] = useState(false);
  const [filled, setFilled] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  let data = id.split("_")[0];
  const ToNavigateSource = () => {
    console.log(data);
    setSelected(data);
    setData(id);
    setAddColumn(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (removeNode) {
      setRemoveNode(false);
    }
  };

  const deleteNode = (e) => {
    setNodes((elements) => elements.filter((element) => element.id !== id));
    // setEdges((elements) => elements.filter((element) => element.source !== id));
    // setEdges((elements) => elements.filter((element) => element.target !== id));

    let a = EtlDetails.edges.filter((element) => element.source === id);
    let b = EtlDetails.edges.filter((element) => element.target === id);

    if (EtlDetails.edges.length > 0) {
      console.log(a, b);
      let a1 = a[0]?.id;
      let b1 = b[0]?.id;
      dispatch(deleteEdges(a1));
      dispatch(deleteEdges(b1));
    }
    if (EtlDetails.transform.length > 0) {
      dispatch(deleteTransform(id));
    }
    dispatch(deleteNodes(id));
  };
  useEffect(() => {
    let a = EtlDetails.transform.some((e) => e.id === id);
    if (a === true) {
      setFilled(true);
    }
  }, [EtlDetails.transform]);

  return (
    <div style={{ marginTop: "-19px" }}>
      <span
        style={{
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          fontSize: "7px",
          fontWeight: "bold",
          marginLeft:
            data === "AddColumn" ||
            data === "RenameColumn" ||
            data === "DropColumn" ||
            data === "SelectColumn" ||
            data === "DropDuplicate"
              ? "-18px"
              : "-6px",
          // display: "flex",
          width: "100px",
          color: filled ? "green" : "red",
        }}
        className="d-flex align-items-center"
      >
        {filled ? (
          <>
            {" "}
            <MdVerified size={7} color="green" /> {node}
          </>
        ) : (
          <>
            {" "}
            <IoAlertCircleSharp size={7} color="red" />
            {node}
          </>
        )}
        {/* {node} */}
      </span>{" "}
      {isHovered ? (
        <div
          onMouseLeave={handleMouseLeave}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            // className="d-flex align-items-center"
            style={{ fontSize: "7px", width: "10px", marginTop: "5px" }}
            onClick={ToNavigateSource}
          >
            <p
              style={{
                fontSize: "7px",
                display: "flex",
                alignItems: "center",
                width: "30px",
              }}
            >
              {" "}
              Open <IoMdArrowDropright />
            </p>
          </div>
          <div style={{ marginTop: "0px" }}>
            <BsThreeDots onClick={() => setRemoveNode((current) => !current)} />
            {removeNode && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  left: "2px",
                  top: "0px",
                  zIndex: 1000,
                  border: "1px solid #ccc",
                  backgroundColor: "whitesmoke",
                  borderRadius: "5px",
                  minWidth: "25px",
                }}
              >
                <MdDelete color="red" onClick={deleteNode} />
                <RiCloseCircleLine
                  onClick={() => setRemoveNode((current) => !current)}
                />
                {/* </button> */}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div onClick={handleMouseEnter} style={{ marginTop: "5px" }}>
          <MdSettingsSuggest size={17} />
        </div>
      )}
    </div>
  );
};

export default Transform;
