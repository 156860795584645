import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../context/ContextApi";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { VpcValidation } from "../../validation";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { getUrl } from "../../api/Endpoint";
import { ipAddress } from "../../api/IpAddress";

const VpcData = ({
  setExpand,
  expand,
  selectedValue,
  mwaaData,
  setshowIam,
  skipOrches,
}) => {
  const { allValues } = useContext(MyContext);
  const [subnetOption, setsubnetOption] = useState([]);
  const [securityOption, setSecurityOption] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedSecurity, setSelectedSecurity] = useState([]);
  const [noOptionsMessage1, setNoOptionsMessage1] = useState("Loading...");
  const [noOptionsMessage2, setNoOptionsMessage2] = useState("Loading...");
  const [vpcid, setVpcId] = useState("");
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({
    resolver: zodResolver(VpcValidation),
  });

  const Subnet = useController({
    name: "Subnet",
    control,
    // defaultValue: allValues.Subnet1.value,
  });
  const Security = useController({
    name: "Security",
    control,
    // defaultValue: allValues.Subnet1.value,
  });

  const handleSelectChange = (option) => {
    Subnet.field.onChange(option);
    setSelectedMembers(option.map((opt) => opt));
  };

  const handleSelectChange2 = (option) => {
    Security.field.onChange(option);
    setSelectedSecurity(option.map((opt) => opt));
  };
  const fetch = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let res = await axios.get(`${getUrl.vpc}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });

      let a = res.data[0];
      setVpcId(a.vpc_id);
      const formattedSubnets = a.subnetids.map((subnet) => ({
        value: subnet,
        label: subnet,
      }));
      setSelectedMembers(formattedSubnets);
      setsubnetOption(formattedSubnets);

      const formattedSecurity = a.securitygroupids.map((security) => ({ value: security, label: security}))
      setSecurityOption(formattedSecurity)
      setSelectedSecurity(formattedSecurity)
      allValues.vpcID = a.vpc_id;
      // console.log("vpc", a);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
     fetch();
  }, []);
  useEffect(() => {
    console.log(selectedMembers, 'select');
    let res = selectedMembers?.map((value) => value.value);
    allValues.Subnets = res;
    console.log("Subnet selected", allValues);
  }, [selectedMembers]);

  useEffect(() => {
    let res1 = selectedSecurity?.map((value) => value.value);
    allValues.SecurityGroups = res1;
    console.log("SecurityGroups selected", allValues);
  }, [selectedSecurity]);

  const subnets = async () => {
    if (subnets.length === 0) {
      if (allValues.vpcID !== "") {
        setNoOptionsMessage1("Loading...");
        setNoOptionsMessage2("Loading...");
        try {
          let token = sessionStorage.getItem("accessToken");
          const result = await axios.get(
            `${ipAddress.dev}/vpc/describe_vpc/${allValues.vpcID}`, {
              headers : {
                Authorization : "Bearer "  + token //Pass Token
              }
            }
          );

          let res1 = result.data.subnet_ids;
          let res2 = result.data.security_groups_ids;
          let data = res1.map((option) => ({
            value: option,
            label: option,
          }));
          // setsubnetOption(data);
          if (data.length === 0) {
            setNoOptionsMessage1("No Subnets Available");
          }
          let data2 = res2.map((option) => ({
            value: option,
            label: option,
          }));
          // setSecurityOption(data2);
          if (data2.length === 0) {
            setNoOptionsMessage2("No Security Groups Available");
          }
        } catch (err) {
          setNoOptionsMessage1("No Subnets Available");
          setNoOptionsMessage2("No Security Groups Available");
          // console.log("Subnet Options", err);
          // if (err.response.status === 500) {
          toast.error("Enter the Valid Vpc ID");
          // }
        }
      }
    }
  };

  const inputChange = () => {
    if (selectedMembers.length !== 0 || securityOption.length !== 0) {
      setSelectedMembers([]);
      setSelectedSecurity([]);
      setsubnetOption([]);
      setSecurityOption([]);
      setValue("Subnet", undefined);
      setValue("Security", undefined);
    }
  };

  const handleVpc = async (formValues) => {
    setExpand(false);
    setshowIam(true);
    // console.log("handleVpc", formValues);
    // console.log("vpc", allValues);
    // await ProjectCreation(allValues, mwaaData, selectedValue);
    setshowIam(true);
  };

  return (
    <form onSubmit={handleSubmit(handleVpc)}>
      {/* <div className="projectfields">
        <div>
          <input
            type="text"
            className="form-control"
            {...register("vpcID")}
            placeholder="VPC ID Name"
            value={vpcid}
            onInput={inputChange}
            readOnly
          />
          <div style={{ color: "red" }}>
          </div>
        </div>
        <div className="select-container">
          <Select
            options={subnetOption}
            isMulti
            {...register("Subnet")}
            value={selectedMembers}
            placeholder="Select Subnet "
            onChange={handleSelectChange}
            className="basic-multi-select"
            classNamePrefix="select"
            noOptionsMessage={() => noOptionsMessage1}
            styles={{
              control: (provided) => ({
                ...provided,
                height: "67px",
                overflow: "auto", 
              }),
            }}
            isDisabled={true}
          />

          <div style={{ color: "red" }}>
            
          </div>
        </div>
        <div>
          <Select
            options={securityOption}
            isMulti
            {...register("Security")}
            value={selectedSecurity}
            placeholder="Select Security Groups"
            onChange={handleSelectChange2}
            className="basic-multi-select"
            classNamePrefix="select"
            noOptionsMessage={() => noOptionsMessage2}
            isDisabled= {true}
            styles={{
              control: (provided) => ({
                ...provided,
                height: "67px",
                overflow: "auto", 
              }),
            }}
          />

          <div style={{ color: "red" }}>
            
          </div>
        </div>
      </div> */}
      <div className="text-center">
        <button
          className="projectbutton "
          type="submit"
          data-toggle="collapse"
          data-target= {
            "#collapseExample3"
          }
          // data-target={`${
          //   allValues.vpcID === "" ||
          //   allValues.Subnets === "" ||
          //   allValues.SecurityGroups === ""
          //     ? ""
          //     : isValid
          //     ? "#collapseExample3"
          //     : ""
          // }`}
        >
          Next
        </button>
        {/* <div style={{ textAlign: "center" }}>
          <div
            className="projectbutton"
            type=""
            onClick={skipOrches}
            data-toggle="collapse"
            data-target="#collapseExample3"
          >
            Skip
          </div>
        </div> */}
      </div>
    </form>
  );
};

export default VpcData;


