import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginRequest } from "./authProvider";
import "./login.scss";
import { showToast } from "../../utils/helpers/helpers";
import { STATUS_CODE } from "../../utils/constants/constantData";
import axios from "axios";

const LoginPage = ({ setLoggedin, isSessionExpired }) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    // Clear session storage when component mounts
    sessionStorage.clear();
  }, []);

  const handleLogin = async () => {
    try {
      const currentAccounts = instance.getAllAccounts();
      if (currentAccounts && currentAccounts.length > 0) {
        // User is already logged in, no need to login again
        return;
      }
      const loginResponse = await instance.loginPopup(loginRequest);
      if (loginResponse && loginResponse.account) {
        const accessToken = loginResponse.accessToken;
        sessionStorage.setItem("accessToken", accessToken);
        const email = loginResponse.account.username;
        handleTokenAndUser(accessToken, email);
      } else {
        // SSO login failed
        showToast('Login failed. Please try again',0);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleTokenAndUser = async (accessToken, email) => {
    try {
      // Make a POST request to pass the token to the backend
      let payload = {
        token: accessToken,
      };
    //   const response = await sendTokenApi(payload);
      const response = await axios.post('https://pharcomm360.agilisiumtech.com:444/auth/authentication', payload);

      if (response?.status === STATUS_CODE.OK) {
        const { message } = response?.data;
        console.log(message, 'msg');
        if (message) {
          sessionStorage.setItem("email", email);
          navigate("/project");
          showToast('User Authenticated Successfully',1);
        }
        let matchingUser;

        const userListResponse = await axios.get('https://pharcomm360.agilisiumtech.com:444/auth/list_user');
        console.log(userListResponse, 'userListResponse');
        if (userListResponse) {
          const users = userListResponse;
          const normalizedUsername = email.toLowerCase();
          matchingUser = users.data.find(
            (user) => user.email.toLowerCase() === normalizedUsername
          );
        } else {
          // Handle the case when userListResponse is undefined or does not contain data
          showToast('An error occurred. Please try again', 0);
        }
        if (matchingUser) {
          const activeAccount = instance.getActiveAccount();
          if (
            activeAccount &&
            activeAccount.idToken &&
            activeAccount.idToken.exp < Date.now() / 1000
          ) {
            // Token is expired, redirect to login page
            showToast('Token Expired',0);
            navigate("/");
            sessionStorage.clear();
            return;
          }

          sessionStorage.setItem("firstname", matchingUser.firstname);
          sessionStorage.setItem("email", matchingUser.email);
          sessionStorage.setItem("admin", matchingUser.is_admin);
          setLoggedin((current) => !current);
          navigate("/project");
        } else {
          // User not found in the fetched user list
          navigate("/");
        }
      } else {
        // If status code is not 200, redirect to login page
        showToast('Invalid Token', 0);
        navigate("/");
      }
    } catch (error) {
      console.error(
        "Error sending token to backend or fetching user list:",
        error
      );
      showToast('An error occurred. Please try again.', 0);
    }
  };

  useEffect(() => {
    if (isSessionExpired || !accounts || accounts.length === 0) {
      handleLogin();
    }

    const handleLogoutEvent = (event) => {
      if (
        event instanceof Event &&
        event.eventType === EventType.LOGOUT_SUCCESS
      ) {
        // Handle logout event
        console.log("Logout event occurred.");
      }
    };

    instance.addEventCallback(handleLogoutEvent);

    return () => {
      instance.removeEventCallback(handleLogoutEvent);
    };
  }, [instance, accounts, isSessionExpired, setLoggedin]);

  return (
    <div className="login">
      <div className="login__bgwrap">
        <p className="login__bgwrap__title">{"Welcome Back to"}</p>
        <h3 className="login__bgwrap__header">{"PharComm"}</h3>
        <button onClick={handleLogin} className="login__bgwrap__gradient-btn">
          {'Sign In with PharComm'}
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
