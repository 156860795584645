import { React, useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import "../editstorage/editstorage.css";
import {
  EditStorageChoosebucketValidation,
  EditStoragePathbucketValidation,
} from "../../validation";
import { editContext } from "../../context/ContextApi";
import axios from "axios";
import EditChoosecompute from "../editchoosecompute/editchoosecompute";
import EditCompute from "../editcompute/editcompute";
import { ipAddress } from "../../api/IpAddress";

const EditStorage = ({ activeStep, setActiveStep, steps }) => {
  const {
    changeHandler,
    allValues,
    exicistingBucket,
    changeexicistingBucket,
    pathfield,
    changePathfield,
    setExistingBucket,
    setPathfield,
  } = useContext(editContext);
  

  const [show, setShow] = useState(true);
  const [s3bucketsOptions, setS3bucketsOptions] = useState();
  const [pathoptions, setPathoptions] = useState([]);
  const [showInput, setShowInput] = useState(false);

  // const [choosebucket, setchoosebucket] = useState(true);
  // const [storedvalue, setStoredvalue] = useState("Choose Bucket");
  const {
    register,
    control,
    handleSubmit,
    formState,
    isSubmitting,
    validateAllFields,
  } = useForm({
    resolver: zodResolver(
      !showInput === false
        ? EditStoragePathbucketValidation
        : EditStorageChoosebucketValidation
    ),
    mode: "all",
  });

  const { errors } = formState;

  // const s3buckets = useController({
  //   name: "s3buckets",
  //   control,
  //   defaultValue: exicistingBucket.value,
  // });

  // const path = useController({
  //   name: "Pathfield",
  //   control,
  //   // defaultValue: pathfield.value,
  // });

  // const handleSelectChange = (option) => {
  //   s3buckets.field.onChange(option.value);
  //   changeexicistingBucket(option);
  //   // setPathfield(null);
  // };
  // const handleSelectChange2 = (option) => {
  //   path.field.onChange(option.value);
  //   changePathfield(option);
  // };
  // const handleChooseBucketChange = (event) => {
  //   const value = event.target.value;
  //   console.log("value", value);
  //   setStoredvalue(value);
  //   setExistingBucket("");
  //   if (value === "Choose Bucket") {
  //     setchoosebucket(true);
  //   } else {
  //     setchoosebucket(false);
  //   }
  // };

  // async function handleRevalidate() {
  //   await validateAllFields();
  // }

  const handleSave = async (formValues, event) => {
    console.log("Editstorage", allValues);
    setShow(true);
  };

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  const s3bucketsdata = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let response = await axios.get(`${ipAddress.dev}/s3/buckets/`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      let data = response.data;
      let result = data.map((option) => ({
        value: option,
        label: option,
      }));
      setS3bucketsOptions(result);
    } catch (err) {
      console.log(err);
    }
  };

  const pathdata = async () => {
    setPathfield(null);
    try {
      let token = sessionStorage.getItem("accessToken");
      let response = await axios.get(
        `${ipAddress.dev}/s3/${exicistingBucket.value}/`, {
          headers : {
            Authorization : "Bearer "  + token //Pass Token
          }
        }
      );

      let data = response.data;
      let result = data.map((option) => ({
        value: option,
        label: option,
      }));
      setPathoptions(result);

      console.log({ pathoptions });
    } catch (err) {
      console.log("Get path err==>", err);
    }
  };

  useEffect(() => {
    s3bucketsdata();
  }, []);
  useEffect(() => {
    pathdata();
  }, [exicistingBucket]);

  return (
    <div className="">
      <div className="editformLayout" style={{ marginTop: "40px" }}>
        <form className="editformDetails" onSubmit={handleSubmit(handleSave)}>
          <div className="editheading">
            <h4>Edit Storage</h4>
          </div>
          {/* <div className="Radiobutton">
            {" "}
            <div className="form-check">
              {" "}
              <input
                type="radio"
                name="optradio"
                value="Choose Bucket"
                defaultChecked={true}
                onChange={handleChooseBucketChange}
              />{" "}
              <label className="label">Choose Bucket</label>{" "}
            </div>{" "}
            <div className="form-check">
              {" "}
              <input
                type="radio"
                name="optradio"
                value="Create Bucket"
                onChange={handleChooseBucketChange}
              />{" "}
              <label className="label">Create Bucket</label>{" "}
            </div>{" "}
          </div> */}

          <div className="editstoragefields">
            <div className="editfirstfield">
              {" "}
              <div>
                {" "}
                <input
                  className="form-control"
                  {...register("exicistingBucket")}
                  placeholder="Select s3buckets"
                  onChange={changeHandler}
                  value={allValues.exicistingBucket}
                  readOnly
                />
              </div>{" "}
              {!showInput && showInput && (
                <div style={{ color: "red" }}>{errors.s3buckets?.message}</div>
              )}
              {!showInput && (
                <div className="editfirstfields">
                  <div>
                    {" "}
                    <input
                      className="form-control"
                      {...register("Pathfield")}
                      placeholder="Enter Pathfield"
                      onChange={changeHandler}
                      value={allValues.Pathfield}
                      readOnly
                    />
                  </div>
                  {!showInput ? (
                    showInput
                  ) : (
                    <div style={{ color: "red" }}>
                      {errors.Pathfield?.message}
                    </div>
                  )}
                </div>
              )}
            </div>

            {showInput && (
              <div className="editsecondfield">
                <div>
                  <input
                    className="form-control"
                    {...register("Path")}
                    placeholder="Enter Path                          (Eg: Path1/Path2/Path3)"
                    onChange={changeHandler}
                    value={allValues.Path}
                  />
                  <div style={{ color: "red" }}>{errors.Path?.message}</div>
                </div>
              </div>
            )}
            <div className="editstoragebtn">
              <div className="text-center">
                <button className="editstoragebutton-one" onClick={toggleInput}>
                  {showInput ? "Choose Path" : "Add Path"}
                </button>
              </div>

              {/* <div className="text-center">
                <button className="editstoragebutton-two" type="submit">
                  Next
                </button>
              </div> */}
            </div>

            {/* {!choosebucket && (<div className="secondfield">
              <div>
                <input
                  className="form-control"
                  {...register("Bucketname")}
                  placeholder="Enter BucketName"
                  onChange={changeHandler}
                  value={allValues.Bucketname}
                />
                {!choosebucket && (<>
                <div style={{ color: "red" }}>{errors.Bucketname?.message}</div>
                </>)}
              </div>

              <div>
                <input
                  className="form-control"
                  {...register("Region")}
                  placeholder="Enter Region"
                  onChange={changeHandler}
                  value={allValues.Region}
                />
                {!choosebucket && (<>
                <div style={{ color: "red" }}>{errors.Region?.message}</div>
                </>)}
              </div>

              <div>
                <input
                  className="form-control"
                  {...register("Path")}
                  placeholder="Enter Path"
                  value={allValues.Path}
                  onChange={changeHandler}
                />
                {!choosebucket && (<>
                <div style={{ color: "red" }}>{errors.Path?.message}</div>
                </>)}
              </div>
            </div>
            )} */}

            {/* {activeStep !== 0 && activeStep !== steps.length - 1 && (
                <button
                  className="storagebutton mx-3"
                  type="button"
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Back
                </button>
              )} */}
          </div>
        </form>
        <div></div>
      </div>
      {!errors.Path?.message && <div>{show && <EditCompute />}</div>}
    </div>
  );
};

export default EditStorage;
