import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import CreateCompute from "../createcompute/compute";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { chooseComputeValidation } from "../validation";
import { MyContext } from "../context/ContextApi";
import "../choosecompute/choosecompute.css";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import CreateOrchestration from "../orchestrationComponent/Create/mwaa";
import { Oval } from "react-loader-spinner";
import { ipAddress } from "../api/IpAddress";

const Choosecompute = () => {
  const [options, setOptions] = useState([]);
  const [show1, setShow1] = useState(true);
  const [showOrchestration, setShowOrchestration] = useState(false);
  const [checked, setChecked] = useState("Choose Cluster");
  const [expand, setExpand] = useState(true);
  const { allValues } = useContext(MyContext);
  const [selectedValue, setSelectedValue] = useState([]);
  const [ChooseselectedValue, setChooseSelectedValue] = useState("");
  const [noOptionsMessage, setNoOptionsMessage] = useState("Loading...");
  const { control, handleSubmit, formState, setValue } = useForm({
    resolver: zodResolver(chooseComputeValidation),
  });
  // const customNoOptionsMessage = () => " Loading.... ";
  const { errors } = formState;

  const chooseCompute = useController({
    name: "chooseCompute",
    control,
    defaultValue: allValues.Choosecluster.value,
  });

  const chooseComputeSource = useController({
    name: "chooseComputeSource",
    control,
    defaultValue: allValues.Chooseclustersource.value,
  });

  const clustersourceOptions = [
    { value: "AWS-EMR-Classic", label: "AWS-EMR-Classic" },
    { value: "AWS-EMR-Serverless", label: "AWS-EMR-Serverless" },
    { value: "Databricks", label: "Databricks" },
  ];

  async function emrgetdata() {
    setOptions([]);
    setNoOptionsMessage("Loading...");
    try {
      let token = sessionStorage.getItem("accessToken");
      if (allValues.Chooseclustersource.value === "AWS-EMR-Classic") {
        let res = await axios.get(
          `${ipAddress.dev}/emr_classic/listing_cluster/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let data = res.data.Clusters;
        const mappedOptions = data.map((option) => ({
          label: option.Name,
          value: option.Id,
        }));
        let result = [...mappedOptions];
        setOptions(result);
        if (result.length === 0) {
          setNoOptionsMessage("No Cluster Available");
        }
      }
      console.log("TOkens", token);
      if (allValues.Chooseclustersource.value === "Databricks") {
        let token = sessionStorage.getItem("accessToken");
        
        let res2 = await axios.get(
          `${ipAddress.dev}/cluster/list_clusters/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let data1 = res2.data;
        const mappedOptions2 = Object.keys(data1).map((key) => ({
          label: key,
          value: data1[key],
        }));
        const filteredOptions = mappedOptions2?.filter(
          (item) => item.label !== ""
        );

        let result = [...filteredOptions];
        setOptions(result);
        if (result.length === 0) {
          setNoOptionsMessage("No Cluster Available");
        }
      }
      if (allValues.Chooseclustersource.value === "AWS-EMR-Serverless") {
        let token = sessionStorage.getItem("accessToken");
        let res3 = await axios.get(
          `${ipAddress.dev}/emr_serverless/listing_cluster_details/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let data1 = res3.data.applications;
        console.log("its serverless data", data1);
        const mappedOptions = data1.map((option) => ({
          label: option.name,
          value: option.id,
        }));
        let result = [...mappedOptions];
        setOptions(result);
        if (result.length === 0) {
          setNoOptionsMessage("No Cluster Available");
        }
      }
    } catch (err) {
      setNoOptionsMessage("No Cluster Available");
      console.log("err", err);
    }
  }

  const handleSelectChange2 = (option) => {
    chooseCompute.field.onChange(option.value);
    allValues.Choosecluster = option;
  };

  const handleSelectChange1 = (option) => {
    chooseComputeSource.field.onChange(option.value);
    allValues.Chooseclustersource = option;
    emrgetdata();
    allValues.Choosecluster = "";
    setValue("chooseCompute", undefined);
    if (showOrchestration === true) {
      setShowOrchestration(false);
    }
  };

  const handleChange = (e) => {
    const target = e.target;
    allValues.ClusterFlag = target.value;
    if (target.checked) {
      setChecked(target.value);
    }
    setShow1((current) => !current);
    if (showOrchestration === true) {
      setShowOrchestration((current) => !current);
    }
  };

  const showValue = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      if (allValues.Chooseclustersource.value === "Databricks") {
        const result = await axios.get(
          `${ipAddress.dev}/cluster/${allValues.Choosecluster.value}/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let res = result.data;

        setChooseSelectedValue(res);
        allValues.ChooseMinWorkers = res?.autoscale?.min_workers
          ? res.autoscale.min_workers
          : 0;
        allValues.ChooseMaxWorkers = res?.autoscale?.max_workers
          ? res.autoscale.max_workers
          : 0;
        allValues.ChooseNodeTypeId = res?.node_type_id ? res.node_type_id : 0;
        allValues.ChooseDriverNodeTypeId = res.driver_node_type_id
          ? res.driver_node_type_id
          : 0;
        allValues.ChooseAutoTerminationMin = res?.autotermination_minutes
          ? res.autotermination_minutes
          : 0;
      } else if (allValues.Chooseclustersource.value === "AWS-EMR-Classic") {
        let token = sessionStorage.getItem("accessToken");
        const result = await axios.get(
          `${ipAddress.dev}/emr_classicfetch_cluster/${allValues.Choosecluster.value}/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let res = result.data;
        
        setChooseSelectedValue(res);
        console.log({ res });
        const {
          "Instance Type": InstanceType,
          // "Slave nodes ": SlaveCount,
          "Master nodes": MasterCount,
        } = res;
        allValues.ChooseMarket = res?.Market;
        allValues.ChooseInstancetype = res.Instance_Type
          ? res.Instance_Type
          : InstanceType;
        allValues.ChooseMasterInstanceCount = MasterCount;
        allValues.ChooseAutoTerminationSec = res?.AutoTerminationPolicy;
        allValues.ChooseSlaveInstanceCount = res['Slave nodes'];
      } else if (allValues.Chooseclustersource.value === "AWS-EMR-Serverless") {
        let token = sessionStorage.getItem("accessToken");
        const result = await axios.get(
          `${ipAddress.dev}/emr_serverless/details_emr_serverless/${allValues.Choosecluster.value}/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }
        );
        let res = result.data.application;
        console.log({ res });
        setChooseSelectedValue(res);
        allValues.ChooseReleaseLabel = res?.releaseLabel;
        allValues.ChooseMaxCPU = res.maximumCapacity.cpu;

        allValues.ChooseMaxMemory = res?.maximumCapacity.memory;
        allValues.ChooseMaxDisk = res?.maximumCapacity.disk;
        allValues.ChooseAutoTerminationMinutesServerless =
          res?.autoStopConfiguration?.idleTimeoutMinutes;
      }
    } catch (Err) {
      console.log("Error in showValue", Err);
    }
  };

  useEffect(() => {
    if (allValues.Choosecluster !== "") {
      setChooseSelectedValue("");
      showValue();
    }
  }, [allValues.Choosecluster]);

  const handleCompute = async (formValues) => {
    console.log("in compute", formValues);
    setExpand(false);
    console.log("allvalues in choosecompute==>", allValues);
    setShowOrchestration(true);
  };

  return (
    <div>
      <div className="formLayout" style={{ marginTop: "40px" }}>
        <div
          className="heading"
          data-toggle="collapse"
          data-target="#collapseExample2"
          onClick={() => setExpand((current) => !current)}
        >
          <h4>Compute</h4>
          {expand ? (
            <BsCaretUpFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample2"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          ) : (
            <BsCaretDownFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample2"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          )}
        </div>
        <div className="formDetails collapse show" id="collapseExample2">
          <div className="">
            <div
              style={{
                display: "flex",
                marginTop: "2%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div className="form-check">
                <input
                  type="radio"
                  id="Choose"
                  value="Choose Cluster"
                  checked={checked === "Choose Cluster"}
                  onChange={handleChange}
                />
                &ensp;<label htmlFor="Choose">Choose Cluster</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  id="Create"
                  value="Create Cluster"
                  checked={checked === "Create Cluster"}
                  onChange={handleChange}
                />
                &ensp;
                <label htmlFor="Create"> Create Cluster</label>
              </div>
            </div>
            {show1 ? (
              <form onSubmit={handleSubmit(handleCompute)}>
                <div className="storagefields">
                  <div>
                    <Select
                      placeholder="Select Cluster Source"
                      value={allValues.Chooseclustersource}
                      noOptionsMessage={() => noOptionsMessage}
                      onChange={handleSelectChange1}
                      options={clustersourceOptions}
                    />
                    <div style={{ color: "red" }}>
                      {errors.chooseComputeSource?.message}
                    </div>{" "}
                  </div>
                  <div>
                    <Select
                      placeholder="Select Cluster"
                      value={allValues.Choosecluster}
                      noOptionsMessage={() => noOptionsMessage}
                      onChange={handleSelectChange2}
                      options={options}
                    />
                    <div style={{ color: "red" }}>
                      {errors.chooseCompute?.message}
                    </div>{" "}
                  </div>
                  {allValues.Chooseclustersource === "" ? (
                    <></>
                  ) : allValues.Chooseclustersource.value === "Databricks" ? (
                    allValues.Choosecluster === "" ? (
                      <></>
                    ) : ChooseselectedValue === "" ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div>
                          <Oval
                            height={30}
                            width={80}
                            color="#0d6efd"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="whitesmoke"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <div className="d-flex">
                          <div className="row">
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Cluster_ID
                              </label>
                              <input
                                type="text"
                                className="form-control mb-2"
                                placeholder=""
                                value={allValues.Choosecluster.value}
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Min_workers
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  ChooseselectedValue?.autoscale?.min_workers
                                    ? ChooseselectedValue?.autoscale
                                        ?.min_workers
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Max_workers
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  ChooseselectedValue?.autoscale?.max_workers
                                    ? ChooseselectedValue?.autoscale
                                        ?.max_workers
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="row">
                            <div className="col ">
                              <label
                                style={{ fontSize: "10px" }}
                                // className="mb-2"
                              >
                                Node_type_id
                              </label>
                              <input
                                type="text"
                                className="form-control mb-2"
                                placeholder=""
                                value={
                                  ChooseselectedValue?.node_type_id
                                    ? ChooseselectedValue.node_type_id
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Driver_Typeid
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  ChooseselectedValue.driver_node_type_id
                                    ? ChooseselectedValue.driver_node_type_id
                                    : 0
                                } // todo
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label
                                style={{ fontSize: "10px", width: "10px" }}
                              >
                                Autotermination_min
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                placeholder=""
                                value={
                                  ChooseselectedValue?.autotermination_minutes
                                    ? ChooseselectedValue.autotermination_minutes
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    )
                  ) : allValues.Chooseclustersource.value ===
                    "AWS-EMR-Classic" ? (
                    allValues.Choosecluster === "" ? (
                      <></>
                    ) : ChooseselectedValue === "" ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div>
                          <Oval
                            height={30}
                            width={80}
                            color="#0d6efd"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="whitesmoke"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="d-flex">
                          <div className="row">
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Cluster_ID
                              </label>
                              <input
                                type="text"
                                className="form-control mb-2"
                                placeholder=""
                                value={allValues.Choosecluster.value}
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>Market</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  ChooseselectedValue.Market
                                    ? ChooseselectedValue.Market
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Instance_type
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                readOnly
                                value={
                                  ChooseselectedValue.Instance_Type
                                    ? ChooseselectedValue.Instance_Type
                                    : 0
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="row">
                            <div className="col">
                              <label
                                style={{ width: "10px", fontSize: "10px" }}
                              >
                                Slave_instance_count
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  ChooseselectedValue["Slave nodes"]
                                    ? ChooseselectedValue["Slave nodes"]
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Master_instance_count
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  ChooseselectedValue["Master nodes"]
                                    ? ChooseselectedValue["Master nodes"]
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label
                                style={{ width: "10px", fontSize: "10px" }}
                              >
                                Autotermination_seconds
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  ChooseselectedValue.AutoTerminationPolicy
                                    ? ChooseselectedValue.AutoTerminationPolicy
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : allValues.Chooseclustersource.value ===
                    "AWS-EMR-Serverless" ? (
                    allValues.Choosecluster === "" ? (
                      <></>
                    ) : ChooseselectedValue === "" ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div>
                          <Oval
                            height={30}
                            width={80}
                            color="#0d6efd"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="whitesmoke"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="d-flex">
                          <div className="row">
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Cluster_ID
                              </label>
                              <input
                                type="text"
                                className="form-control mb-2"
                                placeholder=""
                                value={allValues.Choosecluster.value}
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Release Label
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  allValues.ChooseReleaseLabel
                                    ? allValues.ChooseReleaseLabel
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Max Cpu
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                readOnly
                                value={
                                  allValues.ChooseMaxCPU
                                    ? allValues.ChooseMaxCPU
                                    : 0
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="row">
                            <div className="col">
                              <label
                                style={{ width: "10px", fontSize: "10px" }}
                              >
                                Max_Memory
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  allValues.ChooseMaxMemory
                                    ? allValues.ChooseMaxMemory
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label style={{ fontSize: "10px" }}>
                                Max_Disk
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  allValues.ChooseMaxDisk
                                    ? allValues.ChooseMaxDisk
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                            <div className="col">
                              <label
                                style={{ width: "10px", fontSize: "10px" }}
                              >
                                Autotermination_Minutes
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  allValues.ChooseAutoTerminationMinutesServerless
                                    ? allValues.ChooseAutoTerminationMinutesServerless
                                    : 0
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <></>
                  )}

                  <div className="text-center">
                    <button
                      className="projectbutton"
                      type="submit"
                      data-toggle="collapse"
                      data-target={`${
                        allValues.Chooseclustersource === "" ||
                        allValues.Choosecluster === ""
                          ? ""
                          : "#collapseExample2"
                      }`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <CreateCompute
                showOrchestration={showOrchestration}
                setShowOrchestration={setShowOrchestration}
                setExpand={setExpand}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            )}
          </div>
        </div>
      </div>
      {showOrchestration ? (
        <CreateOrchestration selectedValue={selectedValue} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Choosecompute;
