import React, { useContext, useState } from "react";
import { MyContext } from "../../context/ContextApi";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AirflowValidation } from "../../validation";
import VpcData from "./vpc";
import Select from "react-select";
import axios from "axios";
import { getUrl } from "../../api/Endpoint.js";

const Airflow = ({ setExpand, expand, skipOrches, setshowIam }) => {
  const { allValues, changeHandler } = useContext(MyContext);
  const [showvpc, setShowvpc] = useState(false);
  const {
    control,
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: zodResolver(AirflowValidation),
    mode: "all",
  });
  const [airfloeEnv, setAirfloeEnv] = useState();
  const handleVpcEvent = () => {
    if (showvpc === true) setShowvpc(false);
  };

  // allValues.orchesDatabase = watch("dbConnection");
  allValues.orchesRemoteLogging = watch("Remotelogging");
  // if (watch("dbConnection") === "Choose") {
  //   allValues.dagDatabase = watch("url");
  // }
  if (watch("Remotelogging") === "True") {
    allValues.remotePath = watch("Path");
  }

  const EnvClass = useController({
    name: "Environment",
    control,
    defaultValue: allValues.airflowEnv.value,
  });
  const handleSelectChange = (option) => {
    EnvClass.field.onChange(option.value);
    allValues.airflowEnv = option;
    fetchEnvData();
  };
  const fetchEnvData = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let res = await axios.get(`${getUrl.listeks}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      console.log("res", res);
      let data = res.data;
      let option = data.filter(
        (e) => e.mwaa_environment_class_size === allValues.airflowEnv.value
      );
      setAirfloeEnv(option[0]);
      allValues.airflowMinnode = option[0].eks_min_node_count;
      allValues.airflowMaxnode = option[0].eks_max_node_count;
      console.log("allValues", allValues);
    } catch (Err) {
      console.log("Fetching environment data for EKS", Err);
    }
  };
  const EnvironmentOptions = [
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
  ];
  const handleAirlflow = (formValues) => {
    setShowvpc(true);
    console.log("Formvalues in handleAirlflow==>", formValues);
    console.log("allvalues in handleAirlflow==>", allValues);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleAirlflow)} className="airflowform">
        <div className="airflow">
          <div className="airflowfields">
            <div>
              <input
                type="text"
                className="form-control"
                {...register("dagFolder")}
                placeholder="Enter Dag Folder"
                value={allValues.dagFolder}
                onChange={changeHandler}
              />
              <div style={{ color: "red" }}>
                {errors?.dagFolder && errors.dagFolder.message}
              </div>
            </div>
            <div>
              <Select
                // {...register("costcenter")}
                placeholder="Select Enviroment Class"
                value={allValues.airflowEnv}
                onChange={handleSelectChange}
                options={EnvironmentOptions}
              />

              <div style={{ color: "red" }}>{errors?.Environment?.message}</div>
            </div>
            {/* <div className="d-flex align-items-center">
              <label>DataBase : </label>
              <div
                style={{
                  display: "flex",
                  marginTop: "2%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <div className="form-check">
                  <input
                    {...register("dbConnection", { required: true })}
                    type="radio"
                    value="Create"
                    onClick={() => unregister("url")}
                    id="Create Airflow"
                    // onChange={handleChange}
                  />
                  &ensp;<label htmlFor="Create Airflow">Create</label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    {...register("dbConnection", { required: true })}
                    value="Choose"
                    id="Choose Airflow"
                    onClick={handleVpcEvent}
                    // onChange={handleChange}
                  />
                  &ensp;
                  <label htmlFor="Choose Airflow">Choose</label>
                </div>
              </div>
            </div>
            {errors.dbConnection && (
              <p style={{ color: "red" }}>Please select an option</p>
            )}
            {watch("dbConnection") === "Choose" ? (
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...register("url", { required: true })}
                  placeholder="Database URL"
                  // value={allValues.dbConnection}
                  // onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {watch("dbConnection") === "Choose" ? (
                    errors?.url && errors.url.message
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )} */}
            <div className="d-flex align-items-center">
              <label>Remote Logging : </label>
              <div
                style={{
                  display: "flex",
                  marginTop: "2%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <div className="form-check">
                  <input
                    {...register("Remotelogging", { required: true })}
                    type="radio"
                    value="True"
                    id="true"
                    onClick={handleVpcEvent}
                    //   onChange={handleChange1}
                  />
                  &ensp;<label htmlFor="true">True</label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    {...register("Remotelogging", { required: true })}
                    value="False"
                    onClick={() => unregister("Path")}
                    id="false"
                    //   onChange={handleChange1}
                  />
                  &ensp;
                  <label htmlFor="false">False</label>
                </div>
              </div>
            </div>
            {errors.Remotelogging && (
              <p style={{ color: "red" }}>Please select an option</p>
            )}
            {watch("Remotelogging") === "True" && (
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...register("Path", { required: true })}
                  placeholder="Enter s3 Path"
                  // value={allValues.dbConnection}
                  // onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {errors?.Path && errors.Path.message}
                </div>
              </div>
            )}
            <div>
              <input
                type="text"
                className="form-control"
                {...register("Emailid", { required: true })}
                placeholder="Enter Admin Email ID"
                // value={allValues.dbConnection}
                // onChange={changeHandler}
              />
              <div style={{ color: "red" }}>
                {errors?.Emailid && errors.Emailid.message}
              </div>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                {...register("Firstname", { required: true })}
                placeholder="Enter Admin Firstname"
                // value={allValues.dbConnection}
                // onChange={changeHandler}
              />
              <div style={{ color: "red" }}>
                {errors?.Firstname && errors.Firstname.message}
              </div>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                {...register("Lastname", { required: true })}
                placeholder="Enter Admin Lastname"
                // value={allValues.dbConnection}
                // onChange={changeHandler}
              />
              <div style={{ color: "red" }}>
                {errors?.Lastname && errors.Lastname.message}
              </div>
            </div>
          </div>
          <div className="airflowfields">
            <input
              type="text"
              className="form-control"
              value={`Airflow Version ${allValues.airflowVersion}`}
              readOnly
            />

            <input
              type="text"
              className="form-control"
              value={`Kubernets Version ${allValues.kubernetsVersion}`}
              readOnly
            />

            <input
              type="text"
              className="form-control w-100"
              value={`PostgreSQL Verison ${allValues.postgresSql}`}
              readOnly
            />
            <div className="d-flex" style={{ gap: "2%" }}>
              <input
                type="text"
                className="form-control w-50"
                value={`Min Count : ${
                  allValues.airflowMinnode === "" ? 0 : allValues.airflowMinnode
                }`}
                readOnly
              />
              <input
                type="text"
                className="form-control w-50"
                value={`Max Count : ${
                  allValues.airflowMaxnode === "" ? 0 : allValues.airflowMaxnode
                }`}
                readOnly
              />
            </div>
            Custom Airflow environment Docker File
            <textarea
              style={{ marginTop: "-5%" }}
              rows={6}
              type="text"
              className="form-control"
              {...register("dockerFile", { required: true })}
              value={allValues.dockerFile}
              onChange={changeHandler}
            />
            <div style={{ color: "red" }}>
              {errors?.dockerFile && errors.dockerFile.message}
            </div>
          </div>
        </div>
        <div className="text-center">
          {!errors.dagFolder?.message &&
          !errors.dbConnection?.message &&
          !errors.Remotelogging?.message &&
          !errors.url?.message &&
          !errors.Path?.message ? (
            showvpc ? (
              <></>
            ) : (
              <>
                <button
                  className={`projectbutton `}
                  type="submit"
                  // disabled={disableCluster}
                >
                  Next
                </button>
                {/* <div style={{ textAlign: "center" }}>
                  <div
                    className="projectbutton"
                    type=""
                    onClick={skipOrches}
                    data-toggle="collapse"
                    data-target="#collapseExample3"
                  >
                    Skip
                  </div>
                </div> */}
              </>
            )
          ) : (
            <>
              <button
                className={`projectbutton `}
                type="submit"
                // disabled={disableCluster}
              >
                Next
              </button>
              {/* <div style={{ textAlign: "center" }}>
                <div
                  className="projectbutton"
                  type=""
                  onClick={skipOrches}
                  data-toggle="collapse"
                  data-target="#collapseExample3"
                >
                  Skip
                </div>
              </div> */}
            </>
          )}
        </div>
      </form>
      {!errors.dagFolder?.message &&
        !errors.dbConnection?.message &&
        !errors.Remotelogging?.message &&
        !errors.url?.message &&
        !errors.Path?.message && (
          <div>
            {" "}
            {showvpc ? (
              <VpcData
                setExpand={setExpand}
                setshowIam={setshowIam}
                skipOrches={skipOrches}
              />
            ) : (
              <></>
            )}
          </div>
        )}
    </div>
  );
};

export default Airflow;
