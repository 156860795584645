import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Createproject from "./components/createproject/createproject";
import Project from "./components/createproject/project";
import Editprojects from "./components/editcomponent/editproject/editprojects";
import EditStorage from "./components/editcomponent/editstorage/editstorage";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import "./styles/sass/common.scss";
import "react-toastify/dist/ReactToastify.css";

import Etlindex from "./components/etlModule/index.js";
import Layout from "./components/tableComponent/Layout/layout";
import Cicd from "./components/cicd/github";

import LoginPage from "./components/login/loginPage.js";
import { MsalProvider } from "@azure/msal-react";

function App({ instance }) {
  const [loggedin, setLoggedin] = useState(false);

  useEffect(() => {
    const login = sessionStorage.getItem("email");

    if (login) {
      setLoggedin(true);
    }
  }, [loggedin]);

  return (
    <>
      <MsalProvider instance={instance}>
        <Routes>
          <Route
            path="/"
            index
            element={<LoginPage setLoggedin={setLoggedin} />}
          />
          <Route
            path="/project"
            element={
              <Layout>
                <Project />
              </Layout>
            }
          />
          <Route
            path="/createproject"
            element={
              <Layout>
                <Createproject />
              </Layout>
            }
          />
          <Route
            path="/cicd"
            element={
              <Layout>
                <Cicd />
              </Layout>
            }
          />
          <Route
            path="/editproject"
            element={
              <Layout>
                <Editprojects />
              </Layout>
            }
          />
          <Route
            path="/editstorage"
            element={
              <Layout>
                <EditStorage />
              </Layout>
            }
          />
          <Route
            path="/etl"
            element={
              <Layout>
                <Etlindex />
              </Layout>
            }
          />
        </Routes>
      </MsalProvider>
      <ToastContainer position="top-right" hideProgressBar theme="colored" />
    </>
  );
}

export default App;
