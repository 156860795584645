import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Stepper } from "react-form-stepper";
import CreateCompute from "../createcompute/compute";
import CreateStorage from "../createstorage/storage";
import { motion } from "framer-motion";
import Commit from "../etlModule/commitComponent/Commit";
import "./Stepper.css";
import Pull from "../etlModule/pullComponent/Pull";
const StepperProcess = ({ setStepper }) => {
  const [activeStep, setActiveStep] = useState(0);

  // const [allValues, setAllValues] = useState({
  //   Project: "",
  //   Vpc: "",
  //   Costcenter: "",
  //   CostcenterRegion: "",
  //   exicistingBucket: "",
  //   Pathfield: "",
  //   Bucketname: "",
  //   Region: "",
  //   Path: "",
  //   Createbucket: "",
  //   Clustersource: "",
  //   Clustername: "",
  //   Clustersize: "",
  // });
  // const [Costcenter, setCostcenter] = useState("");
  // const [CostcenterRegion, setCostcenterRegion] = useState("");
  // const [exicistingBucket, setExistingBucket] = useState("");
  // const [clusterSource, setClusterSource] = useState("");
  // const [clusterSize, setClusterSize] = useState("");
  // const changeHandler = (e) => {
  //   const { name, value } = e.target;
  //   setAllValues({ ...allValues, [name]: value });
  //   // console.log({ allValues });
  // };

  // const changeCostcenter = (Costcenter) => {
  //   setCostcenter(Costcenter);
  //   console.log({ Costcenter });
  // };
  // const changeCostcenterRegion = (CostcenterRegion) => {
  //   setCostcenterRegion(CostcenterRegion);
  //   console.log({ CostcenterRegion });
  // };

  // const changeexicistingBucket = (exicistingBucket) => {
  //   setExistingBucket(exicistingBucket);
  //   console.log({ exicistingBucket });
  // };
  // const changeclusterSource = (clusterSource) => {
  //   setClusterSource(clusterSource);
  //   console.log({ clusterSource });
  // };
  // const changeclusterSize = (clusterSize) => {
  //   console.log("data", clusterSize);
  //   setClusterSize(clusterSize);
  //   console.log({ clusterSize });
  // };

  const steps = [
    { label: "Commit" },
    { label: "Pull" },
    // { label: "Orchestration" },
  ];

  function getSectionComponent(activeStep) {
    switch (activeStep) {
      case 0:
        return (
          <Commit
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setStepper={setStepper}
          />
        );
      case 1:
        return (
          <Pull
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setStepper={setStepper}
          />
        );
      //   case 2:
      //     return (
      //       <CreateCompute
      //         activeStep={activeStep}
      //         setActiveStep={setActiveStep}
      //         steps={steps}
      //       />
      //     );
      default:
        return null;
    }
  }

  return (
    <>
      {" "}
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 10 }}
        transition={{ duration: 0.8 }}
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center
          position: "fixed",
          top: 0,
          right: 0,
          width: "60%",
          height: "100%",
          backgroundColor: "#fff",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          // padding: "30px 20px",
          boxSizing: "border-box",
          // zIndex: 9999,
          borderRadius: "20px 0px 0px 20px",
          overflow: "scroll",
        }}
      >
        <div className="stepper">
          <AiOutlineArrowRight
            className="stepperClose"
            size={20}
            onClick={() => setStepper(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          connectorStateColors={true}
          connectorStyleConfig={{
            completedColor: "rgb(40, 121, 241)",
            activeColor: "rgb(40, 121, 241)",
            disabledColor: "#eee",
          }}
          styleConfig={{
            activeBgColor: "rgb(40, 121, 241)",
            completedBgColor: "rgb(40, 121, 241)",
            inactiveBgColor: "#eee",
            activeTextColor: "white",
            completedTextColor: "white",
            inactiveTextColor: "#444",
          }}
        />
        <div>{getSectionComponent(activeStep)}</div>
      </motion.div>
    </>
  );
};

export default StepperProcess;
