import React, { useRef, useCallback, useState, useEffect } from "react";
import ReactFlow, {
  MiniMap,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  MarkerType,
  EdgeLabelRenderer,
} from "reactflow";
import ButtonEdge from "./button";
import { FaCode, FaHandSparkles, FaProjectDiagram } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { updateEdges, addNodes } from "../../store/reducers/etlSlice";
import "./etl.css";
import SourceElement from "./source";
import "reactflow/dist/style.css";
import { TbMathFunction } from "react-icons/tb";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { BsDatabaseFillDown, BsPlayFill } from "react-icons/bs";
import { MdOutlineJoinInner, MdSettingsSuggest } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import ExtractLoad from "./extractload/extractload";
import Transforms from "./transforms/transforms";
import Transform from "./transform";
import Aggregate from "./aggregate";
import Join from "./join";
import AggregateForm from "./aggregate/aggregateform";
import MonacoEditor from "./monaco";
import { Codegenerator } from "./codeGenerator";
import { postUrl } from "../api/Endpoint";
import axios from "axios";
import { toast } from "react-toastify";
import JoinForm from "./join/joinform";
import DataQuality from "./dataquality";
import DataqualtiyForm from "./dataquality/dataform";

const CreateNotebook = ({ clusterID, status }) => {
  const EtlDetails = useSelector((state) => state.etl);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [edges1, setEdges1] = useState([]);
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState();
  const [AddColumn, setAddColumn] = useState(false);
  const [selected, setSelected] = useState("");
  const [aggregateForm, setAggregateForm] = useState(false);
  const [joinForm, setJoinForm] = useState(false);
  const [dataqualityForm, setDataqualityForm] = useState(false);
  const [notebookalert, setNotebookAlert] = useState(false);
  const [nodeEnabled, setNodeEnabled] = useState(true);
  const [nodeBookName, setNodeBookName] = useState("");
  const [code, setCode] = useState("");
  const [output, setOutput] = useState("");
  const [clickable, setClickable] = useState(true);

  const handleSave = (e) => {
    e.preventDefault();
    const inputValue = inputRef.current.value;
    if (inputValue !== "") {
      setNotebookAlert(false);
      setNodeEnabled(false);
      setNodeBookName(`${inputValue}.py`);
    } else {
      setNotebookAlert(true);
    }
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const filledNode = () => {
    const allNodefilled = EtlDetails.nodes.every((obj1) =>
      [
        EtlDetails.extract,
        EtlDetails.transform,
        EtlDetails.join,
        EtlDetails.aggregate,
        EtlDetails.dataQuality,
      ].some((arr) => arr.some((obj2) => obj2.id === obj1.id))
    );
    return allNodefilled;
  };
  const transformEdges = (e) => {
    let id = e.id.split("_")[0];
    let result;
    if (id === "Extract") {
      const res = edges1.some((obj) => obj.source === e.id);
      result = res;
    }
    if (id === "Load") {
      const res = edges1.some((obj) => obj.target === e.id);
      result = res;
    }
    if (
      id === "AddColumn" ||
      id === "RenameColumn" ||
      id === "Concat" ||
      id === "Filter" ||
      id === "Pivot" ||
      id === "DropColumn" ||
      id === "DropDuplicate" ||
      id === "OrderBy" ||
      id === "Aggregate" ||
      id === "Boolean Check" ||
      id === "Custom Check" ||
      id === "Integer Check" ||
      id === "Null Check" ||
      id === "Numeric Check" ||
      id === "Length Check"
    ) {
      const res = edges1.some((obj) => obj.source === e.id);
      const res1 = edges1.some((obj) => obj.target === e.id);
      let a = res && res1;
      result = a;
    }
    if (id === "Join") {
      const res = edges1.filter((obj) => obj.target === e.id);
      const excists = res.length === 2;
      result = excists;
    }
    console.log("result of transformEdges", result);
    return result;
  };
  const generateCode = async () => {
    if (clickable === false) {
      toast.warning(
        "Code generation is in progress.Please wait for completion !!!"
      );
    } else {
      setCode("");
      setOutput("");
      if (nodeBookName !== "") {
        if (EtlDetails.nodes.length > 0) {
          const Fillednodes = filledNode();
          const edgesConnection = nodes.map((e) => {
            return transformEdges(e);
          });
          let data = edgesConnection.includes(false);
          console.log("in data ", data);
          if (Fillednodes && !data) {
            // if (Fillednodes) {
            console.log("else called");
            setClickable(false);
            const toastId = toast.success("Code Generating...", {
              autoClose: false,
            });
            let a = await Codegenerator(nodeBookName, EtlDetails);
            console.log("Final Output===>", a);
            setCode(a);
            toast.dismiss(toastId);
            toast.success("Code Generated !!!", { autoClose: 1000 });
            setClickable(true);
          } else {
            console.log("if called");
            setCode("");
            toast.error("Make Sure all Nodes are Filled and Connected");
          }
        } else {
          // // setClickable(true);
          setClickable(false);
          const toastId = toast.success("Code Generating...", {
            autoClose: false,
          });
          let a = await Codegenerator(nodeBookName, EtlDetails);
          toast.dismiss(toastId);
          setCode(a);
          toast.success("Code Generated !!!", { autoClose: 1000 });
          setClickable(true);
        }
      }
    }
  };
  const attachingCluster = async () => {
    if (code === "" || status === false || filledNode() === false) {
      toast.warning("Make your Code Generated and Cluster Attached", {
        autoClose: 1000,
      });
    } else {
      setOutput("");
      const toastId = toast.warning("Code Executing...", {
        autoClose: false,
        icon: false,
      });

      try {
        let token = sessionStorage.getItem("accessToken");
        const res = await axios.post(`${postUrl.attachCluster}`, {
          cluster_id: clusterID,
          language: "python",
          command: code,
        }, {
          headers : {
            Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
          }
        });

        let result = res.data;
        console.log("result", result);
        toast.dismiss(toastId);
        toast.success("Ouput Generated!!!", { autoClose: 3000 });
        setOutput(result);
        // toast.success("Output displayed");
      } catch (err) {
        console.log("Err", err);
      }
    }
  };

  const edgeTypes = {
    buttonedge: ButtonEdge,
  };

  const handleConnect = (params) => {
    let { source, target } = params;
    // Check if the target node already has a connection
    if (EtlDetails.edges.length > 0) {
      let join = target.split("_")[0];
      let targetNode;
      if (join === "Join") {
        const checkIfIdExistsOnce = () => {
          const count = EtlDetails.edges?.filter(
            (item) => item.target === target
          )?.length;
          console.log("into if", count);
          return count === 2;
        };
        targetNode = checkIfIdExistsOnce();
        console.log("into if", targetNode);
      } else {
        console.log("2");
        targetNode = EtlDetails.edges?.find(
          (element) => element.target === target
        );
        console.log("into else", targetNode);
      }

      const sourceNode = EtlDetails.edges?.find(
        (element) => element.source === source
      );
      if (targetNode || sourceNode) {
        console.log("return null");
        return;
      }
    }
    const newEdge = {
      id: `edge-${source}-${target}`,
      source,
      target,
      type: "buttonedge",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
    };
    // setEdges((prevElements) => [...prevElements, newEdge]);
    dispatch(updateEdges(newEdge));
  };

  const proOptions = { hideAttribution: true };
  let Title = [
    "Extract / Load",
    "Transform",
    "Aggregate",
    "Join",
    "Data Quality",
    "Custom",
  ];

  const generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);
    return parseInt(`${timestamp}${random}`);
  };

  const extractAdd = () => {
    // setClickable(false);
    let intialid = generateUniqueId();
    const newNode = {
      id: `Extract_${intialid}`,
      sourcePosition: "right",
      type: "input",
      data: {
        label: (
          <>
            <SourceElement
              id={`Extract_${intialid}`}
              setNodes={setNodes}
              setEdges={setEdges}
              nodes={nodes}
              edges={edges}
              setPopup={setPopup}
              setData={setData}
            />
          </>
        ),
      },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight - 100,
      },
      style: {
        width: "30px",
        height: "30px",
        padding: "5px",
        border: "1px solid grey",
      },
    };
    setNodes((nds) => nds.concat(newNode));
    dispatch(addNodes(newNode));
  };
  const loadAdd = () => {
    // setClickable(false);
    let intialid = generateUniqueId();
    const newNode = {
      id: `Load_${intialid}`,
      targetPosition: "left",
      type: "output",
      background: "red",
      data: {
        label: (
          <>
            <SourceElement
              id={`Load_${intialid}`}
              edges={edges}
              setNodes={setNodes}
              setEdges={setEdges}
              setPopup={setPopup}
              setData={setData}
            />
          </>
        ),
        connectionLimit: 1,
      },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight - 100,
      },
      style: {
        width: "30px",
        height: "30px",
        padding: "5px",
        border: "1px solid grey",
      },
    };
    setNodes((nds) => nds.concat(newNode));
    dispatch(addNodes(newNode));
  };
  const transformAdd = (e) => {
    // setClickable(false);
    let intialid = generateUniqueId();
    const newNode = {
      id: `${e}_${intialid}`,
      sourcePosition: "right",
      targetPosition: "left",
      type: "default",
      data: {
        label: (
          <Transform
            id={`${e}_${intialid}`}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            setPopup={setPopup}
            setData={setData}
            setAddColumn={setAddColumn}
            node={e}
            nodes={nodes}
            selected={selected}
            setSelected={setSelected}
          />
        ),
        connectionLimit: 1,
      },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight - 100,
      },
      style: {
        width: "30px",
        height: "30px",
        padding: "5px",
        border: "1px solid grey",
      },
    };
    setNodes((nds) => nds.concat(newNode));
    dispatch(addNodes(newNode));
  };
  const aggregateAdd = () => {
    let intialid = generateUniqueId();
    const newNode = {
      id: `Aggregate_${intialid}`,
      sourcePosition: "right",
      targetPosition: "left",
      type: "default",
      data: {
        label: (
          <>
            <Aggregate
              id={`Aggregate_${intialid}`}
              edges={edges}
              setNodes={setNodes}
              setEdges={setEdges}
              setPopup={setPopup}
              setData={setData}
              setAggregateForm={setAggregateForm}
            />
          </>
        ),
        connectionLimit: 1,
      },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight - 100,
      },
      style: {
        width: "30px",
        height: "30px",
        padding: "5px",
        border: "1px solid grey",
      },
    };
    setNodes((nds) => nds.concat(newNode));
    dispatch(addNodes(newNode));
  };
  const joinAdd = (e) => {
    let intialid = generateUniqueId();
    const newNode = {
      id: `Join_${intialid}`,
      sourcePosition: "right",
      targetPosition: "left",
      type: "default",
      data: {
        label: (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Join
                id={`Join_${intialid}`}
                edges={edges}
                setNodes={setNodes}
                setEdges={setEdges}
                setPopup={setPopup}
                setData={setData}
                setJoinForm={setJoinForm}
                node={e}
              />
            </div>
          </>
        ),
      },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight - 100,
      },

      style: {
        width: "30px",
        height: "30px",
        padding: "5px",
        border: "1px solid grey",
      },
      targetHandle: {
        id: "target",
        maxConnections: 2,
      },
    };
    setNodes((nds) => nds.concat(newNode));
    dispatch(addNodes(newNode));
  };
  const dataqualityAdd = (e) => {
    // setClickable(false);
    let intialid = generateUniqueId();
    const newNode = {
      id: `${e}_${intialid}`,
      sourcePosition: "right",
      targetPosition: "left",
      type: "default",
      data: {
        label: (
          <DataQuality
            id={`${e}_${intialid}`}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            setPopup={setPopup}
            setData={setData}
            setDataqualityForm={setDataqualityForm}
            nodes={nodes}
          />
        ),
        connectionLimit: 1,
      },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight - 100,
      },
      style: {
        width: "30px",
        height: "30px",
        padding: "5px",
        border: "1px solid grey",
      },
    };
    setNodes((nds) => nds.concat(newNode));
    dispatch(addNodes(newNode));
  };
  useEffect(() => {
    let a = EtlDetails.edges;
    setEdges1(a);
  }, [EtlDetails.edges]);
  return (
    <>
      <div className="code">
        <form onSubmit={handleSave} style={{ fontSize: "0.9rem" }}>
          <div className="d-flex">
            <div style={{ height: "36.56px" }}>
              <input
                style={{ height: "36.56px" }}
                className="form-control col-9"
                type="text"
                placeholder="Notebook Name"
                w="50"
                // name="notebookName"
                ref={inputRef}
                disabled={nodeEnabled ? false : true}
              />
              {notebookalert ? (
                <p style={{ color: "red" }}>Enter the Notebook Name</p>
              ) : (
                ""
              )}
            </div>

            <button
              className=" "
              type="submit"
              onClick={handleSave}
              style={{
                backgroundColor: "#8b5ccc",
                color: "white",
                height: "36.56px",
                padding: "5px 27px",
                border: "none",
                borderRadius: "5px",
              }}
              disabled={nodeEnabled ? false : true}
            >
              Save
            </button>
          </div>
        </form>

        <ul
          className="nav nav-pills"
          id="ex1"
          role="tablist"
          style={{ fontSize: "0.9rem" }}
        >
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="ex1-tab-1"
              data-toggle="pill"
              href="#ex1-pills-1"
              role="tab"
              aria-controls="ex1-pills-1"
              aria-selected="true"
              // style={{ backgroundColor: "#8b5ccc" }}
            >
              <FaProjectDiagram /> Visual
            </a>
          </li>
          <li className="nav-item" role="presentation" onClick={generateCode}>
            <a
              className="nav-link d-flex align-items-center"
              id="ex1-tab-2"
              data-toggle="pill"
              href="#ex1-pills-2"
              role="tab"
              aria-controls="ex1-pills-2"
              aria-selected="false"
              // style={{ pointerEvents: clickable ? "" : "none" }}
            >
              <FaCode />
              &ensp;Code
            </a>
          </li>
        </ul>
        <div style={{ paddingLeft: "0px" }}>
          <button
            className={
              nodeEnabled
                ? "btn btn-secondary"
                : EtlDetails.extract.length > 0
                ? status
                  ? ""
                  : "btn btn-secondary"
                : "btn btn-secondary"
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(3, 10, 29)",
              height: "36.65px",
              borderRadius: "5px",
              minWidth: "80px",
              // cursor: filledNode() ? "pointer" : "not-allowed",
            }}
            onClick={attachingCluster}
            // disabled={
            //   code === "" ? true : status ? (filledNode() ? false : true) : true
            // }
            // disabled={nodes.length === 0 ? true : false}
          >
            <span className="title">
              {" "}
              <BsPlayFill fontSize={25} color="white" />
              Run
            </span>
          </button>
        </div>
      </div>

      <div className="tab-content" id="ex1-content">
        <div
          className="tab-pane fade show active"
          id="ex1-pills-1"
          role="tabpanel"
          aria-labelledby="ex1-tab-1"
          style={{ height: "72vh" }}
        >
          {!popup && (
            <>
              <div className="nodeOptions">
                <ButtonToolbar>
                  <DropdownButton
                    title={
                      <div className="d-flex align-items-center">
                        <BsDatabaseFillDown />
                        &ensp; <span className="title">{Title[0]}</span>
                      </div>
                    }
                    id="dropdown-size-medium"
                    disabled={nodeEnabled}
                  >
                    <Dropdown.Item eventKey={1} onClick={extractAdd}>
                      Extract
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={2} onClick={loadAdd}>
                      Load
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonToolbar>

                <ButtonToolbar>
                  <DropdownButton
                    title={
                      <div className="d-flex align-items-center">
                        <MdSettingsSuggest size={20} />
                        &ensp; <span className="title">{Title[1]}</span>
                      </div>
                    }
                    disabled={nodes.length === 0 ? true : false}
                    id="dropdown-size-medium1"
                    className="dropdownOptions"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => {
                        transformAdd("AddColumn");
                      }}
                    >
                      Add Column
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => {
                        transformAdd("RenameColumn");
                      }}
                    >
                      Rename Column
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => {
                        transformAdd("Concat");
                      }}
                    >
                      Concat
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => {
                        transformAdd("Filter");
                      }}
                    >
                      Filter
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="5"
                      onClick={() => {
                        transformAdd("DropColumn");
                      }}
                    >
                      Drop Column
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="6"
                      onClick={() => {
                        transformAdd("DropDuplicate");
                      }}
                    >
                      Drop Duplicate
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="7"
                      onClick={() => {
                        transformAdd("Pivot");
                      }}
                    >
                      Pivot
                    </Dropdown.Item>

                    <Dropdown.Item
                      eventKey="8"
                      onClick={() => {
                        transformAdd("OrderBy");
                      }}
                    >
                      OrderBy
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="8"
                      onClick={() => {
                        transformAdd("SelectColumn");
                      }}
                    >
                      Select Column
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonToolbar>
                <button
                  className={nodes.length === 0 ? "btn btn-secondary" : ""}
                  style={{
                    backgroundColor: "rgb(3, 10, 29)",
                    height: "38px",
                    borderRadius: "5px",
                    minWidth: "130px",
                  }}
                  onClick={aggregateAdd}
                  disabled={nodes.length === 0 ? true : false}
                >
                  <span className="title">
                    {" "}
                    <TbMathFunction size={20} color="white" />
                    &ensp;
                    {Title[2]}
                  </span>
                </button>
                <ButtonToolbar>
                  <DropdownButton
                    title={
                      <div className="d-flex align-items-center">
                        <MdOutlineJoinInner size={20} />
                        &ensp;
                        <span className="title">{Title[3]}</span>
                      </div>
                    }
                    id="dropdown-size-medium"
                    className="dropdownOptions"
                    disabled={nodes.length === 0 ? true : false}
                  >
                    <Dropdown.Item eventKey="1" onClick={() => joinAdd("Join")}>
                      Join
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonToolbar>
                <ButtonToolbar>
                  <DropdownButton
                    title={
                      <div className="d-flex align-items-center">
                        <IoMdCheckmarkCircleOutline size={20} />
                        &ensp;
                        <span className="title">{Title[4]}</span>
                      </div>
                    }
                    id="dropdown-size-medium3"
                    className="dropdownOptions"
                    disabled={nodes.length === 0 ? true : false}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => dataqualityAdd("Null Check")}
                    >
                      Null Check
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => dataqualityAdd("Boolean Check")}
                    >
                      Boolean Check
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="3"
                      onClick={() => dataqualityAdd("Custom Check")}
                    >
                      Custom Check
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => dataqualityAdd("Integer Check")}
                    >
                      Integer Check
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="5"
                      onClick={() => dataqualityAdd("Numeric Check")}
                    >
                      Numeric Check
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="6"
                      onClick={() => dataqualityAdd("Length Check")}
                    >
                      Length Check
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonToolbar>
                <ButtonToolbar>
                  <DropdownButton
                    title={
                      <div className="d-flex align-itmes-center">
                        <FaHandSparkles size={20} />
                        &ensp;
                        <span className="title">{Title[5]}</span>
                      </div>
                    }
                    id="dropdown-size-medium3"
                    className="dropdownOptions"
                    disabled={nodes.length === 0 ? true : false}
                  >
                    <Dropdown.Item eventKey="1">Custom</Dropdown.Item>
                  </DropdownButton>
                </ButtonToolbar>
                {/* <div style={{ marginLeft: "auto", paddingLeft: "10px" }}>
                  <button
                    className={
                      nodeEnabled
                        ? "btn btn-secondary"
                        : EtlDetails.extract.length > 0
                        ? status
                          ? ""
                          : "btn btn-secondary"
                        : "btn btn-secondary"
                    }
                    style={{
                      backgroundColor: "rgb(3, 10, 29)",
                      height: "38px",
                      borderRadius: "5px",
                      minWidth: "80px",
                    }}
                    onClick={attachingCluster}
                    disabled={
                      nodeEnabled
                        ? true
                        : EtlDetails.extract.length > 0
                        ? status
                          ? false
                          : true
                        : true
                    }
                    // disabled={nodes.length === 0 ? true : false}
                  >
                    <span className="title">
                      {" "}
                      <BsPlayFill fontSize={25} color="white" />
                      Run
                    </span>
                  </button>
                </div> */}
              </div>
              <ReactFlow
                nodes={nodes}
                edges={edges1}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={handleConnect}
                deleteKeyCode={null}
                edgeTypes={edgeTypes}
                proOptions={proOptions}
                fitView
                attributionPosition="bottom-left"
              >
                <Background variant="cross" />
                <MiniMap
                  nodeStrokeWidth={3}
                  nodeColor="rgb(3, 10, 29)"
                  zoomable
                  pannable
                />
                <Controls />
              </ReactFlow>
              {/* <div>Fi</div> */}
            </>
          )}
          {popup && <ExtractLoad setPopup={setPopup} data={data} />}
        </div>
        <div
          className="tab-pane fade"
          id="ex1-pills-2"
          role="tabpanel"
          aria-labelledby="ex1-tab-2"
        >
          <MonacoEditor
            code={code}
            output={output}
            nodeBookName={nodeBookName}
          />
        </div>
      </div>

      {/* {selectedEventKey === 1 && <Transforms/>} */}
      {AddColumn && (
        <Transforms
          setAddColumn={setAddColumn}
          selected={selected}
          data={data}
          AddColumn={AddColumn}
        />
      )}
      {aggregateForm && (
        <AggregateForm setAggregateForm={setAggregateForm} data={data} />
      )}
      {joinForm && <JoinForm setJoinForm={setJoinForm} data={data} />}
      {dataqualityForm && (
        <DataqualtiyForm setDataqualityForm={setDataqualityForm} data={data} />
      )}
    </>
  );
};

export default CreateNotebook;
