import React, { useEffect } from "react";
import "../createproject/project.css";
import { useState } from "react";
import { getUrl } from "../api/Endpoint";
import { MdAdd, MdAddCircle } from "react-icons/md";
import "../../components/createproject/project.css";
import ProjectTable from "../tableComponent/ProjectTable";
import { useNavigate } from "react-router";
import axios from "axios";

function Project() {
  const [records, setRecords] = useState([]);
  const [Loading, setLoading] = useState("true");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();
  const columns = [
    // "Id",
    "Project Name",
    "Bucket Name",
    "Compute",
    "Cost Center",
    "Environment",
    "Orchestration",
    "Created By",
    "Status",
    "Action",
  ];

  const fetchData = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let result = await axios.get(`${getUrl.projectReport}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      console.log("datttttt",result)
      setRecords(result.data.data);
      setLoading("false");
      // return result;
    } catch (err) {
      console.log("err===>", err);
    }
  };

  useEffect(() => {
    const timer = () =>
      setTimeout(() => {
        fetchData();
      }, 500);
    timer();
    return () => clearTimeout(timer);
  }, [response]);
  console.log("rendering records.....");
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "30px",
          // fontFamily: " Poppins, sans-serif",
        }}
      >
        {" "}
        <h3
          style={{
            color: "#030A1D",
            fontSize: "22px",
            fontWeight: "600",
          }}
        >
          Projects
        </h3>
        <button
          className="btn "
          onClick={() => navigate("/createproject")}
          style={{
            backgroundColor: "#8B5CCC",
            color: "white",
            borderRadius: "20px",
          }}
        >
          <MdAddCircle style={{ fontSize: "20px", fontWeight: "bold" }} />{" "}
          Create Project
        </button>
      </div>

      <div>
        <ProjectTable
          records={records}
          fetching={Loading}
          columns={columns}
          setResponse={setResponse}
        />
      </div>
    </>
  );
}

export default Project;
