import React from "react";
// import ContentLoader from "react-content-loader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = () => {
  return (
    <div>
      {" "}
      <Skeleton height={30} baseColor="#DFDFDF " />
      {/* <SkeletonTheme />
      </Skeleton> */}
    </div>
  );
};

export default Loader;
