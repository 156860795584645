import React, { useContext, useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { CgCloseO } from "react-icons/cg";
import { motion } from "framer-motion";
import { Line10 } from "../line";
import { useSelector, useDispatch } from "react-redux";
import { updateJoin } from "../../../store/reducers/etlSlice";
import { zodResolver } from "@hookform/resolvers/zod";
import { JoinFormValidation } from "../../validation";
import Select from "react-select";
import "../join/joinform.css";
import { previousConnections } from "../codeGenerator";

const JoinForm = ({ setJoinForm, data }) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: zodResolver(JoinFormValidation) });
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const EtlDetails = useSelector((state) => state.etl);
  const joinCache = EtlDetails.join?.find((ele) => ele.id === data);
  const [joinExcistence, setJoinExcistence] = useState(false);
  const [source1, setSouce1] = useState("");
  const [source2, setSouce2] = useState("");

  const JoinType = useController({
    name: "JoinType",
    control,
    defaultValue: joinCache ? joinCache.JoinType : "",
  });

  const JoinTypeOptions = [
    { value: "inner", label: "inner" },
    { value: "right", label: "right" },
    { value: "left", label: "left" },
    { value: "outer", label: "outer" },
  ];
  const JoinFormSubmit = (value) => {
    console.log("JoinFormSubmit Values", value);
    console.log("Selected Option:", selectedOption);
    reset();
    setJoinForm(false);
    let a = { id: data, ...value };
    dispatch(updateJoin(a));
    // setSelectedOption("");
    // setJoinForm(false);
  };

  const handleSelectChange = (selectedOption) => {
    JoinType.field.onChange(selectedOption.value);
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    if (joinCache) {
      let a = JoinTypeOptions.filter((e) => e.value === joinCache.JoinType);
      setSelectedOption(a);
    }
    const matches = EtlDetails.edges.filter((ele) => ele.target === data);
    let joinExcistence1 = matches.length === 2;
    if (joinExcistence1 === true) {
      setJoinExcistence(true);
    }
    console.log("JoinExcistence", joinExcistence);
    if (joinExcistence1) {
      let result = matches.map((ele) => ele.source);
      const matchingObjects = result.map((e) => {
        let connected = previousConnections(e, EtlDetails);
        let res = connected.filter((item) => item.id === e);
        return res[0];
      });
      console.log("matchingObjects", matchingObjects);
      let a =
        matchingObjects[0]?.path === "Redshift"
          ? matchingObjects[0]?.RedShiftTaskName
          : matchingObjects[0]?.path === "s3Bucket"
          ? matchingObjects[0]?.TaskFileName
          : matchingObjects[0]?.path === "Metastore"
          ? matchingObjects[0]?.MetaTaskFileName
          : matchingObjects[0]?.TaskName;
      setSouce1(a);
      let a1 =
        matchingObjects[1]?.path === "Redshift"
          ? matchingObjects[1]?.RedShiftTaskName
          : matchingObjects[1]?.path === "s3Bucket"
          ? matchingObjects[1]?.TaskFileName
          : matchingObjects[1]?.path === "Metastore"
          ? matchingObjects[1]?.MetaTaskFileName
          : matchingObjects[1]?.TaskName;
      console.log("a", a);
      setSouce2(a1);
    }
  }, []);

  return (
    <div>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 0.8 }}
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center
          position: "fixed",
          top: 0,
          right: 0,
          width: "35%",
          height: "100%",
          backgroundColor: "#fff",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          // padding: "30px 20px",
          boxSizing: "border-box",
          // zIndex: 9999,
          borderRadius: "20px 0px 0px 20px",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
            height: "70px",
          }}
        >
          <h4 className="Heading1">Join</h4>
          <CgCloseO
            size={20}
            onClick={() => setJoinForm(false)}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div>
          {/* {dummyEventKey === 1 && ( */}
          <div className="formLayout">
            {/* <div className="Heading">
              <h3 className="Heading1">Join</h3>
              <Line10 />
            </div> */}
            <form onSubmit={handleSubmit(JoinFormSubmit)}>
              <div className="aggregatefields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...register("TaskName")}
                    placeholder="e.g Join"
                    defaultValue={joinCache ? joinCache.TaskName : ""}
                  />
                  <div style={{ color: "red" }}>{errors.TaskName?.message}</div>
                </div>

                <div>
                  <label className="fields">Column 1 :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...register("SourceColumn1")}
                    placeholder="e.g Customer Id"
                    defaultValue={joinCache ? joinCache.SourceColumn1 : ""}
                  />
                  <div style={{ color: "red" }}>
                    {errors.SourceColumn1?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Column 2 :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...register("SourceColumn2")}
                    placeholder="e.g Customer Id"
                    defaultValue={joinCache ? joinCache.SourceColumn2 : ""}
                  />
                  <div style={{ color: "red" }}>
                    {errors.SourceColumn2?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Join Type:</label>
                  <Select
                    options={JoinTypeOptions}
                    value={selectedOption}
                    onChange={handleSelectChange}
                    className="fields mt-1"
                    placeholder="Select Join Type"
                  />
                  {errors.JoinType && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
                {joinExcistence && (
                  <>
                    {" "}
                    <div>
                      <label>Source 1 :</label>
                      <input
                        type="text"
                        className="form-control mt-1 fields"
                        {...register("Source1")}
                        // placeholder="Enter Source 1"
                        disabled={true}
                        value={source1}
                      />
                    </div>
                    <div>
                      <label>Source 2 :</label>
                      <input
                        type="text"
                        className="form-control mt-1 fields"
                        {...register("Source2")}
                        // placeholder="Enter Source 2"
                        value={source2}
                        disabled={true}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="transformsfieldbutton">
                <div>
                  <button
                    className="transformsbutton"
                    onClick={() => setJoinForm(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* )} */}
        </div>
      </motion.div>
    </div>
  );
};

export default JoinForm;
