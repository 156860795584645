import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  useForm,
  useController,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { cicdGithubValidation } from "../validation";
import { MdRemoveCircleOutline } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { IoAddCircle } from "react-icons/io5";
import Gitlab from "./gitlab";
import Select from "react-select";
import axios from "axios";
import { getUrl } from "../api/Endpoint";
import { CicdGithub } from "../api/PostAction";
import { toast } from "react-toastify";

const Cicd = ({ mwaaData, selectedValue, ownorchestration }) => {
  const [expand, setExpand] = useState(true);
  const [checked, setChecked] = useState("Github");
  const [projectOption, setProjectOption] = useState([]);
  const [noOptionmessage, setNoOptionMessage] = useState("Loading...");
  const [visibleFieldIndex, setVisibleFieldIndex] = useState(null);

  // Toggle field visibility
  const toggleFieldVisibility = (index) => {
    if (visibleFieldIndex === index) {
      setVisibleFieldIndex(null);
    } else {
      setVisibleFieldIndex(index);
    }
  };

  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(cicdGithubValidation),
    mode: "all",
    defaultValues: {
      cicdData: [
        {
          Project: [],
          Repository: "",
          Branch: "",
          Token: "",
          Username: "",
          Status: "",
          Source: "",
          Destination: "",
          Flowdata: [],
          Filename: "github.yml",
        },
      ],
    },
  });

  // const [flow, setFlow] = useState("");
  // const [selectEnvironment, setselectEnvironment] = useState([]);

  const fetchProject = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let res = await axios.get(`${getUrl.projectReport}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      let a = res.data.data;
      let data = a?.filter((e) => e.status === "Active");
      if (data?.length > 0) {
        let options = data.map((e) => ({
          value: e.project_id,
          label: e.project_name,
        }));
        setProjectOption(options);
      } else {
        setNoOptionMessage("No Data");
        setProjectOption([]);
      }
    } catch (err) {
      console.log("Error in Fetching Project in CICD", err);
    }
  };
  useEffect(() => {
    fetchProject();
  }, []);

  const { fields, append, remove } = useFieldArray({
    name: "cicdData",
    control,
  });
  // const FlowData = useController({
  //   name: "cicdData.FlowData",
  //   control,
  // });
  // const Status = useController({
  //   name: "Status",
  //   control,
  // });
  // const flowchange = (option) => {
  //   FlowData.field.onChange(option.value);
  //   setFlow(option);
  // };
  // const handleSelectChange = (option) => {
  //   Status.field.onChange(option.value);
  //   setselectEnvironment(option.map((opt) => opt));
  // };

  const statusOptions = [
    { value: "Development", label: "Development" },
    { value: "QA", label: "QA" },
    { value: "Production", label: "Production" },
  ];
  const flowOptions = [
    { value: "ETL_to_s3", label: "ETL_to_s3" },
    { value: "ETL_to_databricks", label: "ETL_to_databricks" },
    { value: "DAG_to_s3", label: "DAG_to_s3" },
    { value: "DAG_to_airflow", label: "DAG_to_ec2" },
  ];
  const handleChange = (e) => {
    const target = e.target;
    if (target.checked) {
      setChecked(target.value);
    }
  };

  const handleSave = async (data) => {
    setExpand((current) => !current);
    let formValues = data.cicdData;
    console.log("formValues fo cicd", formValues);
    let a;
    const promises = [];
    formValues.map(async (e, index) => {
      let cicdData = {
        cicd: "GitHub",
        ProjectID: e.Project.value,
        Repository: e.Repository,
        Branch: e.Branch,
        Token: e.Token,
        Username: e.Username,
        Filename: e.Filename,
        Status: e.Status.map((e) => e.value),
        Source: e.Source,
        Destination: e.Destination,
        Flowdata: e.Flowdata.value,
      };
      a = CicdGithub(cicdData);

      promises.push(a);
      await Promise.all(promises);
      // promises.length = 0;
      // console.log(`cicdData ===> ${index}`, cicdData);
    });
    if (a === undefined) {
      toast.error("Creation Failed");
    } else {
      toast.success("CICD Created Successfully");
      navigate("/project");
    }
    // await ProjectCreation(cicdData);

    // console.log("in storage", allValues);
  };

  return (
    <div>
      <div className="formLayout" style={{ marginTop: "40px" }}>
        <div
          className="heading"
          data-toggle="collapse"
          data-target="#collapseExample4"
          onClick={() => setExpand((current) => !current)}
        >
          <h4>CI / CD</h4>
          {expand ? (
            <BsCaretUpFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample4"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          ) : (
            <BsCaretDownFill
              className="icon"
              data-toggle="collapse"
              data-target="#collapseExample4"
              aria-expanded="false"
              aria-controls="collapseExample"
            />
          )}
        </div>
        <div className="formDetails collapse show" id="collapseExample4">
          <div
            style={{
              display: "flex",
              marginTop: "2%",
              aligncicdData: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <div className="form-check">
              <input
                type="radio"
                value="Github"
                id="Github"
                checked={checked === "Github"}
                onChange={handleChange}
              />
              &ensp;
              <label htmlFor="Github" className="">
                Github
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                id="Gitlab"
                value="Gitlab"
                checked={checked === "Gitlab"}
                onChange={handleChange}
              />
              &ensp;
              <label htmlFor="Gitlab" className="">
                Gitlab
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                value="Code Commit"
                id="Code Commit"
                checked={checked === "Code Commit"}
                onChange={handleChange}
                disabled
              />
              &ensp;
              <label htmlFor="Code Commit" className="">
                CodeCommit
              </label>
            </div>
          </div>
          {checked === "Github" && (
            <form onSubmit={handleSubmit(handleSave)}>
              <div className="align-items-center">
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Note : Use a New YML for different repository under ETL &
                  Airflow Details
                </p>
                {fields.map((field, index) => {
                  return (
                    <>
                      <p style={{ marginLeft: "80px", fontWeight: "Bold" }}>
                        CICD Pipeline {index + 1}
                      </p>
                      <div className="projectfields" key={field.id}>
                        <div>
                          <Controller
                            control={control}
                            name={`cicdData.${index}.Project`}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={projectOption}
                                placeholder="Select Project"
                                noOptionsMessage={() => noOptionmessage}
                              />
                            )}
                          />
                          <div style={{ color: "red" }}>
                            {errors.cicdData?.[index]?.Project &&
                              "Select an option"}
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`cicdData.${index}.Repository`)}
                            placeholder="Repository Name"
                          />
                          <div style={{ color: "red" }} key={index}>
                            {errors.cicdData?.[index]?.Repository &&
                              errors.cicdData?.[index]?.Repository.message}
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`cicdData.${index}.Branch`)}
                            placeholder="Branch"
                          />
                          <div style={{ color: "red" }}>
                            {errors.cicdData?.[index]?.Branch &&
                              errors.cicdData?.[index]?.Branch.message}
                          </div>
                        </div>
                        <div>
                          <div
                            className="d-flex align-items-center"
                            style={{ position: "relative" }}
                          >
                            <input
                              type={
                                visibleFieldIndex === index
                                  ? "text"
                                  : "password"
                              }
                              className="form-control"
                              {...register(`cicdData.${index}.Token`)}
                              placeholder="Token"
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: "10px",
                              }}
                            >
                              {visibleFieldIndex === index ? (
                                <AiFillEye
                                  size={20}
                                  onClick={() => toggleFieldVisibility(index)}
                                />
                              ) : (
                                <AiFillEyeInvisible
                                  size={20}
                                  onClick={() => toggleFieldVisibility(index)}
                                />
                              )}
                            </div>
                          </div>

                          <div style={{ color: "red" }}>
                            {errors.cicdData?.[index]?.Token &&
                              errors.cicdData?.[index]?.Token.message}
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`cicdData.${index}.Username`)}
                            placeholder="Username"
                          />
                          <div style={{ color: "red" }}>
                            {errors.cicdData?.[index]?.Username &&
                              errors.cicdData?.[index]?.Username.message}
                          </div>
                        </div>
                        <div>
                          <Controller
                            control={control}
                            name={`cicdData.${index}.Status`}
                            render={({ field }) => (
                              <Select
                                options={statusOptions}
                                // {...register(`cicdData.${index}.Status`)}
                                isMulti
                                {...field}
                                // value={selectEnvironment}
                                placeholder="Select Environment"
                                // onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    height: "38px",
                                    overflow: "auto", // Specify the desired height here
                                  }),
                                }}
                              />
                            )}
                          />

                          <div style={{ color: "red" }}>
                            {errors.cicdData?.[index]?.Status &&
                              "Select an option"}
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`cicdData.${index}.Filename`)}
                            placeholder="Filename"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <label
                        style={{
                          marginLeft: "80px",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        YML details
                      </label>
                      <div className="projectfields">
                        <div>
                          <Controller
                            control={control}
                            name={`cicdData.${index}.Flowdata`}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={flowOptions}
                                placeholder="Select Flow"
                              />
                            )}
                          />
                          {/* <Select
                            // name={`cicdData.${index}.Flowdata`}
                            {...register(`cicdData.${index}.Flowdata`)}
                            placeholder="Select Flow"
                            options={flowOptions}
                            // onChange={flowchange}
                            // ref={register()}
                            // value={flow}
                          /> */}
                          <div style={{ color: "red" }}>
                            {errors.cicdData?.[index]?.Flowdata &&
                              "Select an option"}
                          </div>
                        </div>

                        <div
                          className="grid-column-end"
                          // style={{ marginTop: "90px" }}
                        >
                          {fields.length !== 1 && (
                            <MdRemoveCircleOutline
                              className="ml-3"
                              style={{ fontSize: "30px", color: "red" }}
                              onClick={() => remove(index)}
                            />
                          )}
                          {fields.length - 1 === index && (
                            <IoAddCircle
                              className="ml-3"
                              onClick={() => {
                                append({
                                  Project: [],
                                  Repository: "",
                                  Branch: "",
                                  Token: "",
                                  Username: "",
                                  Status: "",
                                  Source: "",
                                  Destination: "",
                                  Flowdata: [],
                                  Filename: "github.yml",
                                });
                              }}
                              style={{ fontSize: "30px", color: "#8b5ccc" }}
                            />
                          )}
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`cicdData.${index}.Source`)}
                            placeholder="Source"
                          />
                          <div
                            style={{
                              color: "red",
                              minHeight: "20px",
                              maxheight: "40px",
                            }}
                          >
                            {errors.cicdData?.[index]?.Source &&
                              errors.cicdData?.[index]?.Source.message}
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`cicdData.${index}.Destination`)}
                            placeholder="Destination"
                          />
                          <div style={{ color: "red" }}>
                            {errors.cicdData?.[index]?.Destination &&
                              errors.cicdData?.[index]?.Destination.message}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="text-center">
                  <button className="projectbutton" type="submit">
                    Create
                  </button>
                </div>
              </div>
            </form>
          )}
          {checked === "Gitlab" && (
            <Gitlab
              setExpand={setExpand}
              mwaaData={mwaaData}
              selectedValue={selectedValue}
              ownorchestration={ownorchestration}
              projectOption={projectOption}
              noOptionmessage={noOptionmessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Cicd;
