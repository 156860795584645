import React, { useState } from "react";
import source from "../../assets/source.png";
import "./etl.css";
import {
  BsDatabaseFillDown,
  BsDatabaseFillUp,
  BsThreeDots,
} from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import { MdDelete, MdVerified } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteEdges,
  deleteExtract,
  deleteNodes,
} from "../../store/reducers/etlSlice";
import { useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { TiTick } from "react-icons/ti";
import { IoAlertCircleSharp } from "react-icons/io5";

const SourceElement = ({
  id,
  setNodes,
  setEdges,
  edges,
  setPopup,
  setData,
}) => {
  const dispatch = useDispatch();
  const EtlDetails = useSelector((state) => state.etl);
  const [isHovered, setIsHovered] = useState(false);
  const [removeNode, setRemoveNode] = useState(false);
  const [filled, setFilled] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const ToNavigateSource = () => {
    //  window.location.href = '/Sourcetarget';
    setData(id);
    setPopup(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (removeNode) {
      setRemoveNode(false);
    }
  };
  const deleteNode = () => {
    setNodes((elements) => elements.filter((element) => element.id !== id));
    // setNodes((elements) => elements.filter((element) => element.source !== id));

    let a;

    if (id.startsWith("Extract")) {
      a = EtlDetails.edges?.filter((element) => element.source === id);
    } else {
      a = EtlDetails.edges?.filter((element) => element.target === id);
    }
    let data = a[0];
    console.log("deleted edges", a);
    if (EtlDetails.extract.length > 0) {
      dispatch(deleteExtract(id));
    }
    if (EtlDetails.edges.length > 0) {
      dispatch(deleteEdges(data?.id));
    }
    dispatch(deleteNodes(id));
  };
  let value = id.split("_")[0];
  useEffect(() => {
    let a = EtlDetails.extract.some((e) => e.id === id);
    if (a === true) {
      setFilled(true);
    }
  }, []);
  return (
    <div style={{ marginTop: "-18px" }}>
      <span
        style={{
          fontSize: "7px",
          fontWeight: "bold",
          marginLeft: value === "Extract" ? "-12px" : "-9px",
          // display: "flex",
          width: "200px",
          color: filled ? "green" : "red",
        }}
        className="d-flex align-items-center"
      >
        {filled ? (
          <MdVerified size={7} color="green" />
        ) : (
          <IoAlertCircleSharp size={7} color="red" />
        )}
        &ensp;{value}
      </span>
      {isHovered ? (
        <div
          onMouseLeave={handleMouseLeave}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "7px",
              width: "10px",
              marginTop: "5px",
            }}
            onClick={ToNavigateSource}
          >
            <p
              style={{
                fontSize: "7px",
                display: "flex",
                alignItems: "center",
                width: "30px",
              }}
            >
              {" "}
              Open <IoMdArrowDropright />
            </p>
          </div>
          <div style={{ marginTop: "0px" }}>
            <BsThreeDots onClick={() => setRemoveNode((current) => !current)} />
            {removeNode && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  left: "2px",
                  top: "0px",
                  zIndex: 1000,
                  border: "1px solid #ccc",
                  backgroundColor: "whitesmoke",
                  borderRadius: "5px",
                  minWidth: "25px",
                }}
              >
                <MdDelete color="red" onClick={deleteNode} />
                <RiCloseCircleLine
                  onClick={() => setRemoveNode((current) => !current)}
                />
                {/* </button> */}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          onClick={handleMouseEnter}
          style={{
            marginTop: "5px",
            // backgroundColor: "red",
            // backgroundColor: filled ? "#00ff00" : "red",
            paddingBottom: "3px",
          }}
        >
          {id.startsWith("Extract") ? (
            <BsDatabaseFillDown size={13} />
          ) : (
            <BsDatabaseFillUp size={13} />
          )}
        </div>
      )}
    </div>
  );
};

export default SourceElement;
