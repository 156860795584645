// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ownorchestrationradiobtn{
    display: flex;
    grid-template-columns: 100%;
    grid-column-gap: 50px;
    justify-content: center;
    margin-top: 30px;
    padding: 20px;
}

.Orchestrationfields {
    display: grid;
    grid-template-columns: 90% 90%;
    grid-column-gap: 70px;
    grid-row-gap: 20px;
    padding: 10px;
    justify-content: center;
    margin-bottom: 30px;
  }

  .Orchestrationbutton{
    background-color: #8b5ccc;
    border: none;
    color: white;
    padding: 5px 27px;
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    border-radius: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/components/orchestrationComponent/ownorchestration.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,qBAAqB;IACrB,uBAAuB;IACvB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":[".ownorchestrationradiobtn{\n    display: flex;\n    grid-template-columns: 100%;\n    grid-column-gap: 50px;\n    justify-content: center;\n    margin-top: 30px;\n    padding: 20px;\n}\n\n.Orchestrationfields {\n    display: grid;\n    grid-template-columns: 90% 90%;\n    grid-column-gap: 70px;\n    grid-row-gap: 20px;\n    padding: 10px;\n    justify-content: center;\n    margin-bottom: 30px;\n  }\n\n  .Orchestrationbutton{\n    background-color: #8b5ccc;\n    border: none;\n    color: white;\n    padding: 5px 27px;\n    text-align: center;\n    font-size: 20px;\n    margin-bottom: 30px;\n    border-radius: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
