import React from "react";
import { ChooseNotebookValidation } from "../validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

const ChooseNotebok = () => {
  const { register, handleSubmit, formState } = useForm({
    resolver: zodResolver(ChooseNotebookValidation),
    mode: "all",
  });
  const { errors } = formState;
  const Notebook = (formValues) => {
    console.log({ formValues });
  };
  return (
    <>
      <form onSubmit={handleSubmit(Notebook)}>
        <div className="form-group row d-flex align-items-center mt-3">
          <div className="col-3">
            <input
              type="text"
              {...register("s3Path")}
              className="form-control"
              id="inputpath3"
              placeholder="s3 Path"
            />
            <div style={{ color: "red", height: "" }}>
              {errors.s3Path?.message}
            </div>
          </div>
          <div className="col-1">
            <button className="savebutton">Save</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChooseNotebok;
