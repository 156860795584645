import { createSlice } from "@reduxjs/toolkit";

const projectData = createSlice({
  name: "ProjectDetails",
  initialState: {
    projectName: "",
    costCenter: "",
    region: {},
  },
  reducers: {
    updateProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    updateCostCenter: (state, action) => {
      state.costCenter = action.payload;
    },
    updateRegion: (state, action) => {
      state.region = action.payload;
    },
  },
});

export const { updateProjectName, updateCostCenter, updateRegion } =
  projectData.actions;

export default projectData.reducer;
