import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller, useController } from "react-hook-form";
import { CgCloseO } from "react-icons/cg";
import { motion } from "framer-motion";
import { Line9 } from "../line";
import { zodResolver } from "@hookform/resolvers/zod";
import { AggregateFormValidation } from "../../validation";
import Select from "react-select";
import "../aggregate/aggregateform.css";
import { useSelector, useDispatch } from "react-redux";
import { updateAggregate } from "../../../store/reducers/etlSlice";
const AggregateForm = ({ setAggregateForm, data }) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(AggregateFormValidation) });
  const EtlDetails = useSelector((state) => state.etl);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const aggregateCache = EtlDetails.aggregate?.find((ele) => ele.id === data);
  const Aggregate = useController({
    name: "Aggregate",
    control,
    defaultValue: aggregateCache ? aggregateCache.Aggregate : "",
  });

  const AggregateOptions = [
    { value: "min", label: "min" },
    { value: "sum", label: "sum" },
    { value: "max", label: "max" },
    { value: "avg", label: "avg" },
    { value: "count", label: "count" },
  ];

  const AggregateFormSubmit = (value) => {
    console.log("AggregateFormSubmit Values", value);
    console.log("Selected Option:", selectedOption);
    reset();
    setSelectedOption("");
    setAggregateForm(false);
    let a = { id: data, ...value };
    dispatch(updateAggregate(a));
    console.log("Aggregate form", EtlDetails.aggregate);
  };

  const handleSelectChange = (selectedOption) => {
    Aggregate.field.onChange(selectedOption.value);
    setSelectedOption(selectedOption);
  };
  useEffect(() => {
    if (aggregateCache) {
      let a = AggregateOptions.filter(
        (e) => e.value === aggregateCache.Aggregate
      );
      setSelectedOption(a);
      console.log("into if");
    }
  }, []);

  return (
    <div>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 0.8 }}
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center
          position: "fixed",
          top: 0,
          right: 0,
          width: "35%",
          height: "100%",
          backgroundColor: "#fff",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          // padding: "30px 20px",
          boxSizing: "border-box",
          // zIndex: 9999,
          borderRadius: "20px 0px 0px 20px",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CgCloseO
            size={30}
            onClick={() => setAggregateForm(false)}
            style={{ cursor: "pointer" }}
          />
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
            height: "70px",
          }}
        >
          <h4 className="Heading1">Aggregate</h4>
          <CgCloseO
            size={20}
            onClick={() => setAggregateForm(false)}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div>
          {/* {dummyEventKey === 1 && ( */}
          <div className="formLayout">
            {/* <div className="Heading">
              <h3 className="Heading1">Aggregate</h3>
              <Line9 />
            </div> */}
            <form onSubmit={handleSubmit(AggregateFormSubmit)}>
              <div className="aggregatefields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...register("TaskName")}
                    placeholder="e.g Aggregate"
                    defaultValue={
                      aggregateCache ? aggregateCache?.TaskName : ""
                    }
                  />
                  <div style={{ color: "red" }}>{errors.TaskName?.message}</div>
                </div>

                <div>
                  <label className="fields">Aggregate Column :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...register("AggregateColumn")}
                    placeholder="e.g Maxprice"
                    defaultValue={
                      aggregateCache ? aggregateCache?.AggregateColumn : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {errors.AggregateColumn?.message}
                  </div>
                </div>

                <div className="fields">
                  <label>GroupBy :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...register("GroupBy")}
                    placeholder="e.g Category"
                    defaultValue={aggregateCache ? aggregateCache?.GroupBy : ""}
                  />
                  <div style={{ color: "red" }}>{errors.GroupBy?.message}</div>
                </div>
                <div>
                  <label className="fields ">Aggregate:</label>
                  <Select
                    // {...register("Aggregate")}
                    // defaultValue={null}
                    className="mt-1 fields"
                    options={AggregateOptions}
                    value={selectedOption}
                    placeholder="Select Aggregate"
                    // className="mt-1 fields"
                    onChange={handleSelectChange}
                  />
                  {errors.Aggregate && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
                <div>
                  <label className="fields">Target Column Name:</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...register("TargetColumn")}
                    placeholder="e.g Agg-price"
                    defaultValue={
                      aggregateCache ? aggregateCache?.TargetColumn : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {errors.TargetColumn?.message}
                  </div>
                </div>
              </div>

              <div className="transformsfieldbutton">
                <div>
                  <button
                    className="transformsbutton"
                    onClick={() => setAggregateForm(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* )} */}
        </div>
      </motion.div>
    </div>
  );
};

export default AggregateForm;
