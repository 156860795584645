import { ipAddress } from "./IpAddress";

export const postUrl = {
  createUser: `${ipAddress.dev}/auth/`,
  login: `${ipAddress.dev}/auth/token`,
  wrapper: `${ipAddress.dev}/api/infra/create_records/`,
  costcenter: `${ipAddress.dev}/cost_center/create_cost_center/`,
  project: `${ipAddress.dev}/project/create_project/`,
  exicistingbucket: `${ipAddress.dev}/s3/bring_bucket/`,
  newbucket: `${ipAddress.dev}/s3/create_bucket/`,
  updatePath: `${ipAddress.dev}/s3/create_path/`,
  exicistingcluster: `${ipAddress.dev}/emr_classic/bring_cluster/`,
  classiccluster: `${ipAddress.dev}/emr_classic/create_cluster/`,
  serverlesscluster: `${ipAddress.dev}/emr_serverless/create_cluster/`,
  databricksAllpurpose: `${ipAddress.dev}/cluster/create_cluster/`,
  databricksJobcluster:
    `${ipAddress.dev}/job_cluster/creating_job_cluster/`,
  MWaa: `${ipAddress.dev}/mwaa/create_environment/`,
  notebook: `${ipAddress.dev}/jdbc/file_creation/`,
  jdbcUsenameCreate: `${ipAddress.dev}/jdbc/extract_username/`,
  jdbcUsenameChoose: `${ipAddress.dev}/jdbc/choose_extract_username/`,
  jdbcAws: `${ipAddress.dev}/jdbc/extract_aws_secret/`,
  jdbcDb: `${ipAddress.dev}/jdbc/extract_db_secret/`,
  redshiftUsenameCreate:
    `${ipAddress.dev}/jdbc/redshift_extract_username/`,
  redshiftUsenameChoose:
    `${ipAddress.dev}/jdbc/choose_redshift_extract_username/`,
  redshiftAws: `${ipAddress.dev}/jdbc/redshift_extract_aws_secrets/`,
  redshiftDb:
    `${ipAddress.dev}/jdbc/redshift_extract_databricks_secrets/`,
  redshiftloadUsenameCreate:
    `${ipAddress.dev}/jdbc/redshift_load_username/`,
  redshiftloadUsenameChoose:
    `${ipAddress.dev}/jdbc/choose_redshift_load_username/`,
  redshiftloadAws: `${ipAddress.dev}/jdbc/redshift_load_aws_secrets/`,
  redshiftloadDb:
    `${ipAddress.dev}/jdbc/redshift_load_databricks_secrets/`,
  jdbcS3: `${ipAddress.dev}/jdbc/s3_extract/`,
  loadS3: `${ipAddress.dev}/jdbc/s3_load/`,
  loadUsenameCreate: `${ipAddress.dev}/jdbc/jdbc_load_username/`,
  loadUsenameChoose:
    `${ipAddress.dev}/jdbc/choose_jdbc_load_username/`,
  loadAws: `${ipAddress.dev}/jdbc/jdbc_load_aws_secrets/`,
  loadDb: `${ipAddress.dev}/jdbc/jdbc_load_databricks_secrets/`,

  transformAddColumn: `${ipAddress.dev}/transformation/add_column/`,
  transformRenameColumn:
    `${ipAddress.dev}/transformation/rename_column/`,
  transformFilter: `${ipAddress.dev}/transformation/filter_function/`,
  transformConcat: `${ipAddress.dev}/transformation/concat_function/`,
  transformdropColumn: `${ipAddress.dev}/transformation/drop_column/`,
  transformdropDuplicate:
    `${ipAddress.dev}/transformation/drop_duplicates/`,
  transformPivot: `${ipAddress.dev}/transformation/pivot_function/`,
  transfromSelectColumn:
    `${ipAddress.dev}/transformation/column_filter`,
  transformOrderBy: `${ipAddress.dev}/transformation/order_by/`,
  join: `${ipAddress.dev}/transformation/joins/`,
  aggregate: `${ipAddress.dev}/transformation/groupby_aggregate/`,
  attachCluster: `${ipAddress.dev}/databricks/cluster/submit_job/`,
  finalService: `${ipAddress.dev}/api/infra_service/create_service/`,
  chooseService: `${ipAddress.dev}/api/choose_infra_service/choose_service/`,
  notebookGithub:
    `${ipAddress.dev}/git_commit/upload_notebook_in_github/`,
  notebookGitlab:
    `${ipAddress.dev}/git_commit/upload_notebook_in_gitlab/`,
  nullCheck: `${ipAddress.dev}/data_quality/null_check/`,
  booleanCheck: `${ipAddress.dev}/data_quality/boolean_check/`,
  integerCheck: `${ipAddress.dev}/data_quality/integer_check/`,
  numericCheck: `${ipAddress.dev}/data_quality/numeric_check/`,
  lengthCheck: `${ipAddress.dev}/data_quality/length_check/`,
  customCheck: `${ipAddress.dev}/data_quality/custom_check/`,
  githubCicd: `${ipAddress.dev}/github/cicd_github`,
  gitlabCicd: `${ipAddress.dev}/gitlab/cicd_gitlab`,
  rejectNotify: `${ipAddress.dev}/api/infra_service/notify_description`,
  metaExtract: `${ipAddress.dev}/jdbc/extract_deltatable/`,
  metaLoadPath: `${ipAddress.dev}/jdbc/load_deltatable_path/`,
  metaLoadTable: `${ipAddress.dev}/jdbc/load_deltatable/`,
};

export const getUrl = {
  userList: `${ipAddress.dev}/auth/`,
  costcenter: `${ipAddress.dev}/cost_center/list_get/`,
  project: `${ipAddress.dev}/project/list_get/`,
  projectReport: `${ipAddress.dev}/project_report/`,
  gitDetails: `${ipAddress.dev}/git_commit/get_github/`,
  exicistingbucket: `${ipAddress.dev}/s3/buckets/`,
  bucketpath: `${ipAddress.dev}/s3`,
  emrcluster: `${ipAddress.dev}/emr_classic/listing_cluster/`,
  databricksCluster: `${ipAddress.dev}/cluster/list_clusters/`,
  serverlessCluster:
    `${ipAddress.dev}/emr_serverless/listing_cluster_details/`,
  clusterOptions: `${ipAddress.dev}/emr_classic/list_sml/`,
  databricksStatus:
    `${ipAddress.dev}/databricks/cluster/cluster_status/`,
  finalizeReport: `${ipAddress.dev}/api/infra/cost/get_records/`,
  listeks: `${ipAddress.dev}/customize_record/list_eks/`,
  iam: `${ipAddress.dev}/iam/list_iam_roles`,
  vpc: 'https://pharcomm360.agilisiumtech.com:444/vpc/fetch_vpc/',
};

export const putUrl = {
  finalizeInprogress: `${ipAddress.dev}/api/infra/cost/accept_record/`,
};
