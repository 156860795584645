import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { CgCloseO } from "react-icons/cg";
import s3Bucketlogo from "../../../assets/s3bucket.png";
import MetastoreLogo from "../../../assets/MetaStore.png"
import RDBMSlogo from "../../../assets/data1.png";
import Redshiftlogo from "../../../assets/redshift.png";
import PostgreSqllogo from "../../../assets/postgresql.png";
import Sqllogo from "../../../assets/sql.png";
import { SiMysql, SiOracle } from "react-icons/si";
import oraclelogo from "../../../assets/oracle.png";
import veevalogo from "../../../assets/veeva.png";
import "../extractload/extractload.css";
import { Line1, Line2, Line3, Line4 } from "../line";
import { useSelector, useDispatch } from "react-redux";
import { updateExtract } from "../../../store/reducers/etlSlice";
import "./extractload.css";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  s3BucketValidation,
  PostgresqlValidation,
  Postgresql1Validation,
  RedShiftValidation,
  RedShift1Validation,
  MetaStoreValidation,
  MetaValidationForTable,
  MetaValidationForPath,
} from "../../validation";
import { BsBucketFill } from "react-icons/bs";
import { ImDatabase } from "react-icons/im";
import { BiLogoAws, BiLogoPostgresql } from "react-icons/bi";
import { RiDatabase2Fill } from "react-icons/ri";
import { TbBrandVue } from "react-icons/tb";

const ExtractLoad = ({ setPopup, data }) => {
  const dispatch = useDispatch();
  const EtlDetails = useSelector((state) => state.etl);
  const extractCache = EtlDetails.extract?.find((ele) => ele.id === data);
  console.log("&&", extractCache)
  const [isOpen, setIsOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [load, setLoad] = useState(true);
  const [selectedForm, setSelectedForm] = useState(
    extractCache ? extractCache.db : ""
  );
  const [showForms, setShowForms] = useState(extractCache ? true : false);
  const [radioselectedOption, setRadioSelectedOption] = useState(
    extractCache ? extractCache.CdcOption : ""
  );
  const [redShiftradioselectedOption, setredShiftRadioSelectedOption] =
    useState(extractCache ? extractCache.RedShiftCdcOption : "");

  const [valutOptions, setValutOptions] = useState(
    extractCache ? extractCache.VaultOptions : ""
  );
  const [redshiftvalutOptions, setredshiftValutOptions] = useState(
    extractCache ? extractCache.RedShiftVaultOptions : ""
  );
  const [postgresqlOption, setPostgresqlOption] = useState(
    extractCache ? extractCache.credential : "Databricks"
  );
  const [metaStoreData, setMetaStoreData] = useState(
    extractCache ? extractCache.meta : "SaveAsPath"
  );

  const [extractData, setExtractData] = useState({
    id: data, // node id
    path: "", // s3 or rdms
    db: extractCache ? extractCache.db : "", // postgresql or mysql or oracle
    meta: extractCache ? extractCache.meta : "SaveAsPath",
    credential: extractCache ? extractCache.credential : "Databricks", //
    // CdcOption: extractCache ? extractCache.CdcOption : "",
    // valutOptions: extractCache ? extractCache.VaultOptions : "",
  });

  let value = data.split("_")[0];
  console.log(currentForm, "hi");
  const { handleSubmit, reset } = useForm({});

  const s3Bucket = useForm({
    resolver: zodResolver(s3BucketValidation),
  });

  const metaValue = useForm({
    resolver: zodResolver(MetaStoreValidation),
  });

  const metaStoreFormValue = useForm({
    resolver: zodResolver(
      extractData.meta === "SaveAsPath"
      ? MetaValidationForPath
      : MetaValidationForTable
    ),
  });

  const postgresql = useForm({
    resolver: zodResolver(
      extractData.credential === "Username and Password"
        ? Postgresql1Validation
        : PostgresqlValidation
    ),
  });
  const redshift = useForm({
    resolver: zodResolver(
      extractData.credential === "Username and Password"
        ? RedShift1Validation
        : RedShiftValidation
    ),
  });

  const navigateToComponent2 = () => {
    setPopup(false);
  };
  const RDBMSSubmit = (data) => {
    console.log(data);
    setShowForms(true);
    reset();
  };

  const s3Submit = (data) => {
    console.log("s3Form Values", data);
    // s3Bucket.reset();
    console.log("PostgreSqlSubmit", data);
    let a = { ...extractData, ...data };
    console.log("postgresql", a);
    dispatch(updateExtract(a));
    setIsOpen(false);
    setPopup(false);
  };

  const metaStoreFormSubmit = (data) => {
    console.log("MetaStoreValueSubmit", data);
    let a = { ...extractData, ...data };
    console.log("MetaStoreValue", a);
    dispatch(updateExtract(a));
    setIsOpen(false);
    setPopup(false);
  };

  const PostgreSqlSubmit = (data) => {
    // postgresql.reset();
    console.log("PostgreSqlSubmit", data);
    let a = { ...extractData, ...data };
    console.log("postgresql", a);
    dispatch(updateExtract(a));
    setIsOpen(false);
    setPopup(false);
  };

  const toggleForm = (form) => {
    setCurrentForm(form);
    setIsOpen(!isOpen);
    extractData.path = form;
  };

  const handleOptionChange = (event) => {
    setredShiftRadioSelectedOption(event.target.value);
    setRadioSelectedOption(event.target.value);
    extractData.CdcOption = event.target.value;
  };

  const handleOptionChange1 = (event) => {
    setredshiftValutOptions(event.target.value);
    setValutOptions(event.target.value);
    extractData.valutOptions = event.target.value;
  };
  const PostgresqlhandleOptionChange = (event) => {
    setPostgresqlOption(event.target.value);
    extractData.credential = event.target.value;
  };

  const handleMetaDataValueChange = (e) => {
    setMetaStoreData(e.target.value);
    extractData.meta = e.target.value;
  }

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedForm(value);
    setRadioSelectedOption("");
    extractData.db = value;
    reset();
  };
  console.log("***", metaStoreFormValue.formState.errors)
  const forms = {
    s3Bucket: (
      <div className="formLayout">
        {/* <div className="Heading">
          <h3 className="Heading1">
            <img
              src={s3Bucketlogo}
              alt="s3Bucketlogo"
              className="s3Bucketlogo"
            />
            s3
          </h3>
          <Line2 />
        </div> */}
        <form onSubmit={s3Bucket.handleSubmit(s3Submit)}>
          <div className="s3Bucketfields">
            {value === "Extract" ? (
              <div>
                <label className="fields">Task / File Name :</label>
                <input
                  style={{height: "36.56px"}}
                  type="text"
                  className="form-control fields mt-1"
                  {...s3Bucket.register("TaskFileName")}
                  placeholder="Enter Task / File Name"
                  defaultValue={extractCache ? extractCache.TaskFileName : ""}
                />
                <div style={{ color: "red" }}>
                  {s3Bucket.formState.errors.TaskFileName?.message}
                </div>
              </div>
            ) : (
              ""
            )}
            <div>
              <label className="fields">Path :</label>
              <input
                style={{height: "36.56px"}}
                type="text"
                className="form-control fields mt-1"
                {...s3Bucket.register("Path")}
                placeholder="Enter Path"
                defaultValue={extractCache ? extractCache.Path : ""}
              />
              <div style={{ color: "red" }}>
                {s3Bucket.formState.errors.Path?.message}
              </div>
            </div>

            <div className="s3radiobutton">
              <div className="s3radio">
                <input
                  type="radio"
                  value="csv"
                  {...s3Bucket.register("FileType")}
                  id="csv"
                  defaultChecked={
                    extractCache && extractCache.FileType === "csv"
                      ? true
                      : false
                  }
                />
                <label htmlFor="csv">&ensp;csv</label>
              </div>
              <div className="s3radio">
                <input
                  type="radio"
                  value="json"
                  {...s3Bucket.register("FileType")}
                  id="json"
                  defaultChecked={
                    extractCache && extractCache.FileType === "json"
                      ? true
                      : false
                  }
                />
                <label htmlFor="json">
                  {" "}
                  <span style={{ marginLeft: "5px" }}>json</span>
                </label>
              </div>
              <div className="s3radio">
                <input
                  type="radio"
                  value="avro"
                  {...s3Bucket.register("FileType")}
                  id="avro"
                  defaultChecked={
                    extractCache && extractCache.FileType === "avro"
                      ? true
                      : false
                  }
                />
                <label htmlFor="avro">
                  {" "}
                  <span style={{ marginLeft: "5px" }}>avro</span>
                </label>
              </div>
              <div className="s3radio">
                <input
                  type="radio"
                  value="parquet"
                  {...s3Bucket.register("FileType")}
                  id="parquet"
                  defaultChecked={
                    extractCache && extractCache.FileType === "parquet"
                      ? true
                      : false
                  }
                />
                <label htmlFor="parquet">
                  {" "}
                  <span style={{ marginLeft: "5px" }}>parquet</span>
                </label>
              </div>
              <div className="s3radio">
                <input
                  type="radio"
                  value="orc"
                  {...s3Bucket.register("FileType")}
                  id="orc"
                  defaultChecked={
                    extractCache && extractCache.FileType === "orc"
                      ? true
                      : false
                  }
                />
                <label htmlFor="orc">
                  {" "}
                  <span style={{ marginLeft: "5px" }}>orc</span>
                </label>
              </div>
              <div className="s3radio">
                <input
                  type="radio"
                  value="delta"
                  {...s3Bucket.register("FileType")}
                  id="delta"
                  defaultChecked={
                    extractCache && extractCache.FileType === "delta"
                      ? true
                      : false
                  }
                />
                <label htmlFor="delta">
                  {" "}
                  <span style={{ marginLeft: "5px" }}>delta</span>
                </label>
              </div>
            </div>
            <div style={{ color: "red", marginLeft: "8s0px" }}>
              {s3Bucket.formState.errors.FileType?.message &&
                "Please Select an option"}
            </div>
          </div>
          <div className="transformsfieldbutton">
            <div>
              <button
                className="transformsbutton"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
            <div>
              <button className="transformsbutton" type="Submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    ),
    Rdbms: (
      <div className="formLayout">
        {/* <div className="Heading"> */}
        {/* <h3 className="Heading1">
            <img src={RDBMSlogo} alt="s3Bucketlogo" className="RDBMSlogo" />
            RDBMS
          </h3>
          <Line3 /> */}
        {/* </div> */}
        <form onSubmit={handleSubmit(RDBMSSubmit)}>
          <div className="checkboxfields">
            <div>
              <label>
                <input
                  type="radio"
                  value="postgresql"
                  onChange={handleCheckboxChange}
                  checked={selectedForm === "postgresql"}
                />
                <span style={{ marginTop: "10px", marginLeft: "10px" }}> PostgreSQL</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="SQL"
                  onChange={handleCheckboxChange}
                  checked={selectedForm === "SQL"}
                />
                <span style={{ marginLeft: "10px" }}> MySQL</span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="Oracle"
                  onChange={handleCheckboxChange}
                  checked={selectedForm === "Oracle"}
                />
                <span style={{ marginLeft: "10px" }}> Oracle</span>
              </label>
            </div>
            <div
              className="transformsfieldbutton"
              style={{ paddingLeft: "26%" }}
            >
              <div>
                <button
                  className="transformsbutton"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="transformsbutton" type="submit">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>

        {showForms && selectedForm && (
          <div>
            {selectedForm !== "" && (
              <div className="RDBMSformLayout">
                <div className="RDBMSHeading">
                  <h3 className="RDBMSHeading1">
                    {selectedForm === "postgresql" && (
                      <div className="ml-1">
                        <img
                          src={PostgreSqllogo}
                          alt="s3Bucketlogo"
                          className="PostgreSqllogo"
                        />
                        {/* <BiLogoPostgresql /> */}
                        PostgreSQL
                      </div>
                    )}
                    {selectedForm === "SQL" && (
                      <div className="ml-1 d-flex align-items-center">
                        <img
                          src={Sqllogo}
                          alt="s3Bucketlogo"
                          className="Sqllogo"
                        />
                        {/* <SiMysql size={40} /> */}
                        MySQL
                      </div>
                    )}
                    {selectedForm === "Oracle" && (
                      <div className="ml-1">
                        <img
                          src={oraclelogo}
                          alt="s3Bucketlogo"
                          className="oraclelogo"
                        />
                        {/* <SiOracle size={35} /> */}
                        Oracle
                      </div>
                    )}
                  </h3>
                  {/* <Line4 /> */}
                </div>
                <form onSubmit={postgresql.handleSubmit(PostgreSqlSubmit)}>
                  <div className="postgresqlcheckboxfields">
                    <div>
                      <input
                        type="radio"
                        value="Databricks"
                        checked={postgresqlOption === "Databricks"}
                        onChange={PostgresqlhandleOptionChange}
                        id="Databricks"
                      />
                      <label htmlFor="Databricks">
                        {" "}
                        <span style={{ marginLeft: "10px" }}> Databricks</span>
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="AWS Secret manager"
                        checked={postgresqlOption === "AWS Secret manager"}
                        onChange={PostgresqlhandleOptionChange}
                        id="AWS Secret manager"
                      />
                      <label htmlFor="AWS Secret manager">
                        {" "}
                        <span style={{ marginLeft: "10px" }}>
                          {" "}
                          AWS Secret Manager
                        </span>
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="Username and Password"
                        checked={postgresqlOption === "Username and Password"}
                        onChange={PostgresqlhandleOptionChange}
                        id="Username and Password"
                      />
                      <label htmlFor="Username and Password">
                        {" "}
                        <span style={{ marginLeft: "10px" }}> Vault</span>
                      </label>
                    </div>
                  </div>

                  {postgresqlOption === "AWS Secret manager" ||
                  postgresqlOption === "Databricks" ? (
                    <div className="RDBMSfields">
                      <div>
                        <input
                          type="text"
                          className="form-control fields"
                          {...postgresql.register("JdbcUrl")}
                          placeholder="Jdbc Url"
                          defaultValue={
                            extractCache ? extractCache.JdbcUrl : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {postgresql.formState.errors.JdbcUrl?.message}
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control fields"
                          {...postgresql.register("DriverName")}
                          placeholder="Driver Name"
                          defaultValue={
                            extractCache ? extractCache.DriverName : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {postgresql.formState.errors.DriverName?.message}
                        </div>
                      </div>
                      {extractData.credential !== "AWS Secret manager" && (
                        <>
                          <div>
                            <input
                              type="text"
                              className="form-control fields"
                              {...postgresql.register("UserName")}
                              placeholder="User Name"
                              defaultValue={
                                extractCache ? extractCache.UserName : ""
                              }
                            />
                            <div style={{ color: "red" }}>
                              {postgresql.formState.errors.UserName?.message}
                            </div>
                          </div>
                          <div>
                            <input
                              type="password"
                              className="form-control fields"
                              {...postgresql.register("Password")}
                              placeholder="Password"
                              defaultValue={
                                extractCache ? extractCache.Password : ""
                              }
                            />
                            <div style={{ color: "red" }}>
                              {postgresql.formState.errors.Password?.message}
                            </div>
                          </div>
                        </>
                      )}

                      <div>
                        <input
                          type="text"
                          className="form-control fields"
                          {...postgresql.register("Credential")}
                          placeholder="Credential"
                          defaultValue={
                            extractCache ? extractCache.Credential : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {postgresql.formState.errors.Credential?.message}
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control fields"
                          {...postgresql.register("TableName")}
                          placeholder="TableName"
                          defaultValue={
                            extractCache ? extractCache.TableName : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {postgresql.formState.errors.TableName?.message}
                        </div>
                      </div>
                      {value !== "Load" && (
                        <div>
                          <input
                            type="text"
                            className="form-control fields"
                            {...postgresql.register("TaskName")}
                            placeholder="TaskName"
                            defaultValue={
                              extractCache ? extractCache.TaskName : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {postgresql.formState.errors.TaskName?.message}
                          </div>
                        </div>
                      )}

                      {/* <div>
                        <input
                          type="text"
                          className="form-control fields"
                          {...postgresql.register("Vaultpath")}
                          placeholder="Vault path"
                          defaultValue={
                            extractCache ? extractCache.Vaultpath : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {postgresql.formState.errors.Vaultpath?.message}
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    ""
                  )}

                  {postgresqlOption === "Username and Password" && (
                    <>
                      <div className="radiobutton">
                        <div>
                          <input
                            type="radio"
                            value="Create"
                            {...postgresql.register("VaultOptions", {
                              required: true,
                            })}
                            checked={valutOptions === "Create"}
                            // onChange={handleOptionChange1}
                            onClick={handleOptionChange1}
                            id="Create"
                          />
                          <label htmlFor="Create">
                            {" "}
                            <span style={{ marginLeft: "10px" }}> Create</span>
                          </label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            value="Choose"
                            {...postgresql.register("VaultOptions", {
                              required: true,
                            })}
                            checked={valutOptions === "Choose"}
                            // onChange={handleOptionChange1}
                            onClick={handleOptionChange1}
                            id="Choose"
                          />
                          <label htmlFor="Choose">
                            <span style={{ marginLeft: "10px" }}> Choose</span>
                          </label>
                        </div>
                      </div>
                      {postgresql.formState.errors.VaultOptions &&
                        postgresql.formState.errors.VaultOptions && (
                          <p style={{ color: "red", marginLeft: "200px" }}>
                            Please select an option
                          </p>
                        )}
                      <div className="RDBMSfields">
                        <div>
                          <input
                            type="text"
                            className="form-control fields"
                            {...postgresql.register("JdbcUrl")}
                            placeholder="Jdbc Url"
                            defaultValue={
                              extractCache ? extractCache.JdbcUrl : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {postgresql.formState.errors.JdbcUrl?.message}
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="form-control fields"
                            {...postgresql.register("DriverName")}
                            placeholder="Driver Name"
                            defaultValue={
                              extractCache ? extractCache.DriverName : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {postgresql.formState.errors.DriverName?.message}
                          </div>
                        </div>
                        {valutOptions === "Create" && (
                          <>
                            <div>
                              <input
                                type="text"
                                className="form-control fields"
                                {...postgresql.register("UserName")}
                                placeholder="User Name"
                                defaultValue={
                                  extractCache ? extractCache.UserName : ""
                                }
                              />
                              <div style={{ color: "red" }}>
                                {postgresql.formState.errors.UserName?.message}
                              </div>
                            </div>
                            <div>
                              <input
                                type="password"
                                className="form-control fields"
                                {...postgresql.register("Password")}
                                placeholder="Password"
                                defaultValue={
                                  extractCache ? extractCache.Password : ""
                                }
                              />
                              <div style={{ color: "red" }}>
                                {postgresql.formState.errors.Password?.message}
                              </div>
                            </div>
                          </>
                        )}

                        <div>
                          <input
                            type="text"
                            className="form-control fields"
                            {...postgresql.register("TableName")}
                            placeholder="TableName"
                            defaultValue={
                              extractCache ? extractCache.TableName : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {postgresql.formState.errors.TableName?.message}
                          </div>
                        </div>
                        {value !== "Load" && (
                          <div>
                            <input
                              type="text"
                              className="form-control fields"
                              {...postgresql.register("TaskName")}
                              placeholder="TaskName"
                              defaultValue={
                                extractCache ? extractCache.TaskName : ""
                              }
                            />
                            <div style={{ color: "red" }}>
                              {postgresql.formState.errors.TaskName?.message}
                            </div>
                          </div>
                        )}

                        <div>
                          <input
                            type="text"
                            className="form-control fields"
                            {...postgresql.register("Vaultpath")}
                            placeholder="Vault path"
                            defaultValue={
                              extractCache ? extractCache.Vaultpath : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {postgresql.formState.errors.Vaultpath?.message}
                          </div>
                        </div>
                      </div>
                      <p
                        style={{
                          marginLeft: "10%",
                          fontSize: "11px",
                          fontWeight: "bold",
                          marginTop: "-5%",
                        }}
                      >
                        {valutOptions === "Create" ? (
                          <>
                            Creates new username and password
                            <br /> in the respective vault path
                          </>
                        ) : (
                          <>Provide an Existing Vault Path</>
                        )}
                      </p>
                    </>
                  )}
                  {load === true && (
                    <div
                      className="radioformlayout"
                      // style={{ marginLeft: "-22%" }}
                    >
                      <div className="radiobutton">
                        <div>
                          <input
                            type="radio"
                            value="CDC"
                            {...postgresql.register("CdcOption", {
                              required: true,
                            })}
                            checked={radioselectedOption === "CDC"}
                            // onChange={handleOptionChange}
                            onClick={handleOptionChange}
                            id="cdc"
                          />
                          <label htmlFor="cdc">
                            {" "}
                            <span style={{ marginLeft: "10px" }}> CDC</span>
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            value="History"
                            {...postgresql.register("CdcOption", {
                              required: true,
                            })}
                            checked={radioselectedOption === "History"}
                            // onChange={handleOptionChange}
                            onClick={handleOptionChange}
                            id="History"
                          />
                          <label htmlFor="History">
                            <span style={{ marginLeft: "10px" }}>
                              {" "}
                              Full Load
                            </span>
                          </label>
                        </div>
                      </div>
                      {postgresql.formState.errors?.CdcOption &&
                        postgresql.formState.errors.CdcOption?.message && (
                          <p style={{ color: "red", marginLeft: "200px" }}>
                            Please select an option
                          </p>
                        )}
                      <div>
                        {radioselectedOption === "CDC" && (
                          <div
                            className="radiofields1"
                            style={{ marginLeft: "10%", marginTop: "3%" }}
                          >
                            <div>
                              <input
                                type="text"
                                className="form-control fields"
                                {...postgresql.register("ColumnName")}
                                placeholder="column_name>2023-06-12"
                                defaultValue={
                                  extractCache ? extractCache.ColumnName : ""
                                }
                              />
                              <div style={{ color: "red" }}>
                                {
                                  postgresql.formState.errors.ColumnName
                                    ?.message
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    className="transformsfieldbutton"
                    style={{ marginLeft: "26%", marginTop: "7%" }}
                  >
                    <div>
                      <button
                        className="transformsbutton"
                        onClick={() => setIsOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button className="transformsbutton" type="submit">
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    ),
    Redshift: (
      <div className="formLayout">
        <div>
          <div className="RDBMSformLayout">
            {/* <div className="RDBMSHeading">
              <h3 className="RDBMSHeading1">
                <>
                  <img
                    src={Redshiftlogo}
                    alt="RedShift"
                    // className="oraclelogo"
                    width={40}
                  />{" "}
                  &ensp; RedShift
                </>
              </h3>
              <Line4 />
            </div> */}
            <form onSubmit={redshift.handleSubmit(PostgreSqlSubmit)}>
              <div className="postgresqlcheckboxfields">
                <div>
                  <input
                    type="radio"
                    value="Databricks"
                    checked={postgresqlOption === "Databricks"}
                    onChange={PostgresqlhandleOptionChange}
                    id="Databricks"
                  />
                  <label htmlFor="Databricks">
                    {" "}
                    <span style={{ marginLeft: "10px" }}> Databricks</span>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="AWS Secret manager"
                    checked={postgresqlOption === "AWS Secret manager"}
                    onChange={PostgresqlhandleOptionChange}
                    id="AWS Secret manager"
                  />
                  <label htmlFor="AWS Secret manager">
                    {" "}
                    <span style={{ marginLeft: "10px" }}>
                      {" "}
                      AWS Secret Manager
                    </span>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="Username and Password"
                    checked={postgresqlOption === "Username and Password"}
                    onChange={PostgresqlhandleOptionChange}
                    id="Username and Password"
                  />
                  <label htmlFor="Username and Password">
                    {" "}
                    <span style={{ marginLeft: "10px" }}>Vault</span>
                  </label>
                </div>
              </div>

              {postgresqlOption === "AWS Secret manager" ||
              postgresqlOption === "Databricks" ? (
                <div className="RDBMSfields">
                  <div>
                    <input
                      type="text"
                      className="form-control fields"
                      {...redshift.register("RedShiftJdbcUrl")}
                      placeholder="Jdbc Url"
                      defaultValue={
                        extractCache ? extractCache.RedShiftJdbcUrl : ""
                      }
                    />
                    <div style={{ color: "red" }}>
                      {redshift.formState.errors.RedShiftJdbcUrl?.message}
                    </div>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control fields"
                      {...redshift.register("RedShiftTempDirectory")}
                      placeholder="Temp directory"
                      defaultValue={
                        extractCache ? extractCache.RedShiftTempDirectory : ""
                      }
                    />
                    <div style={{ color: "red" }}>
                      {redshift.formState.errors.RedShiftTempDirectory?.message}
                    </div>
                  </div>
                  {extractData.credential !== "AWS Secret manager" && (
                    <>
                      <div>
                        <input
                          type="text"
                          className="form-control fields"
                          {...redshift.register("RedShiftUserName")}
                          placeholder="User Name"
                          defaultValue={
                            extractCache ? extractCache.RedShiftUserName : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {redshift.formState.errors.RedShiftUserName?.message}
                        </div>
                      </div>
                      <div>
                        <input
                          type="password"
                          className="form-control fields"
                          {...redshift.register("RedShiftPassword")}
                          placeholder="Password"
                          defaultValue={
                            extractCache ? extractCache.RedShiftPassword : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {redshift.formState.errors.RedShiftPassword?.message}
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <input
                      type="text"
                      className="form-control fields"
                      {...redshift.register("RedShiftCredential")}
                      placeholder="Credential"
                      defaultValue={
                        extractCache ? extractCache.RedShiftCredential : ""
                      }
                    />
                    <div style={{ color: "red" }}>
                      {redshift.formState.errors.RedShiftCredential?.message}
                    </div>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control fields"
                      {...redshift.register("RedShiftTableName")}
                      placeholder="TableName"
                      defaultValue={
                        extractCache ? extractCache.RedShiftTableName : ""
                      }
                    />
                    <div style={{ color: "red" }}>
                      {redshift.formState.errors.RedShiftTableName?.message}
                    </div>
                  </div>
                  {value !== "Load" && (
                    <div>
                      <input
                        type="text"
                        className="form-control fields"
                        {...redshift.register("RedShiftTaskName")}
                        placeholder="TaskName"
                        defaultValue={
                          extractCache ? extractCache.RedShiftTaskName : ""
                        }
                      />
                      <div style={{ color: "red" }}>
                        {redshift.formState.errors.RedShiftTaskName?.message}
                      </div>
                    </div>
                  )}

                  {/* <div>
                      <input
                        type="text"
                        className="form-control fields"
                        {...redshift.register("Vaultpath")}
                        placeholder="Vault path"
                        defaultValue={
                          extractCache ? extractCache.Vaultpath : ""
                        }
                      />
                      <div style={{ color: "red" }}>
                        {redshift.formState.errors.Vaultpath?.message}
                      </div>
                    </div> */}
                </div>
              ) : (
                ""
              )}

              {postgresqlOption === "Username and Password" && (
                <>
                  <div className="radiobutton">
                    <div>
                      <input
                        type="radio"
                        value="Create"
                        {...redshift.register("RedShiftVaultOptions", {
                          required: true,
                        })}
                        checked={redshiftvalutOptions === "Create"}
                        // onChange={handleOptionChange1}
                        onClick={handleOptionChange1}
                        id="Create"
                      />
                      <label htmlFor="Create">
                        {" "}
                        <span style={{ marginLeft: "10px" }}> Create</span>
                      </label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        value="Choose"
                        {...redshift.register("RedShiftVaultOptions", {
                          required: true,
                        })}
                        checked={redshiftvalutOptions === "Choose"}
                        // onChange={handleOptionChange1}
                        onClick={handleOptionChange1}
                        id="Choose"
                      />
                      <label htmlFor="Choose">
                        <span style={{ marginLeft: "10px" }}> Choose</span>
                      </label>
                    </div>
                  </div>
                  {redshift.formState.errors.RedShiftVaultOptions &&
                    redshift.formState.errors.RedShiftVaultOptions && (
                      <p style={{ color: "red", marginLeft: "200px" }}>
                        Please select an option
                      </p>
                    )}
                  <div className="RDBMSfields">
                    <div>
                      <input
                        type="text"
                        className="form-control fields"
                        {...redshift.register("RedShiftJdbcUrl")}
                        placeholder="Jdbc Url"
                        defaultValue={
                          extractCache ? extractCache.RedShiftJdbcUrl : ""
                        }
                      />
                      <div style={{ color: "red" }}>
                        {redshift.formState.errors.RedShiftJdbcUrl?.message}
                      </div>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="form-control fields"
                        {...redshift.register("RedShiftTempDirectory")}
                        placeholder="Temp Directory"
                        defaultValue={
                          extractCache ? extractCache.RedShiftTempDirectory : ""
                        }
                      />
                      <div style={{ color: "red" }}>
                        {
                          redshift.formState.errors.RedShiftTempDirectory
                            ?.message
                        }
                      </div>
                    </div>
                    {redshiftvalutOptions === "Create" && (
                      <>
                        <div>
                          <input
                            type="text"
                            className="form-control fields"
                            {...redshift.register("RedShiftUserName")}
                            placeholder="User Name"
                            defaultValue={
                              extractCache ? extractCache.RedShiftUserName : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {
                              redshift.formState.errors.RedShiftUserName
                                ?.message
                            }
                          </div>
                        </div>
                        <div>
                          <input
                            type="password"
                            className="form-control fields"
                            {...redshift.register("RedShiftPassword")}
                            placeholder="Password"
                            defaultValue={
                              extractCache ? extractCache.RedShiftPassword : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {
                              redshift.formState.errors.RedShiftPassword
                                ?.message
                            }
                          </div>
                        </div>
                      </>
                    )}

                    <div>
                      <input
                        type="text"
                        className="form-control fields"
                        {...redshift.register("RedShiftTableName")}
                        placeholder="TableName"
                        defaultValue={
                          extractCache ? extractCache.RedShiftTableName : ""
                        }
                      />
                      <div style={{ color: "red" }}>
                        {redshift.formState.errors.RedShiftTableName?.message}
                      </div>
                    </div>
                    {value !== "Load" && (
                      <div>
                        <input
                          type="text"
                          className="form-control fields"
                          {...redshift.register("RedShiftTaskName")}
                          placeholder="TaskName"
                          defaultValue={
                            extractCache ? extractCache.RedShiftTaskName : ""
                          }
                        />
                        <div style={{ color: "red" }}>
                          {redshift.formState.errors.RedShiftTaskName?.message}
                        </div>
                      </div>
                    )}

                    <div>
                      <input
                        type="text"
                        className="form-control fields"
                        {...redshift.register("RedShiftVaultpath")}
                        placeholder="Vault path"
                        defaultValue={
                          extractCache ? extractCache.RedShiftVaultpath : ""
                        }
                      />
                      <div style={{ color: "red" }}>
                        {redshift.formState.errors.RedShiftVaultpath?.message}
                      </div>
                    </div>
                  </div>
                  <p
                    style={{
                      marginLeft: "10%",
                      fontSize: "11px",
                      fontWeight: "bold",
                      marginTop: "-5%",
                    }}
                  >
                    {valutOptions === "Create" ? (
                      <>
                        Creates new username and password <br /> in the
                        respective vault path
                      </>
                    ) : (
                      <>Provide an Existing Vault Path</>
                    )}
                  </p>
                </>
              )}
              {load === true && (
                <div className="radioformlayout">
                  <div className="radiobutton">
                    <div>
                      <input
                        type="radio"
                        value="CDC"
                        {...redshift.register("RedShiftCdcOption", {
                          required: true,
                        })}
                        checked={redShiftradioselectedOption === "CDC"}
                        // onChange={handleOptionChange}
                        onClick={handleOptionChange}
                        id="cdc"
                      />
                      <label htmlFor="cdc">
                        {" "}
                        <span style={{ marginLeft: "10px" }}> CDC</span>
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="History"
                        {...redshift.register("RedShiftCdcOption", {
                          required: true,
                        })}
                        checked={redShiftradioselectedOption === "History"}
                        // onChange={handleOptionChange}
                        onClick={handleOptionChange}
                        id="History"
                      />
                      <label htmlFor="History">
                        <span style={{ marginLeft: "10px" }}>Full Load</span>
                      </label>
                    </div>
                  </div>
                  {redshift.formState.errors?.RedShiftCdcOption &&
                    redshift.formState.errors.RedShiftCdcOption?.message && (
                      <p style={{ color: "red", marginLeft: "200px" }}>
                        Please select an option
                      </p>
                    )}
                  <div>
                    {radioselectedOption === "CDC" && (
                      <div
                        className="radiofields1"
                        style={{ marginLeft: "10%", marginTop: "3%" }}
                      >
                        <div>
                          <input
                            type="text"
                            className="form-control fields"
                            {...redshift.register("RedShiftColumnName")}
                            placeholder="column_name>2023-06-12"
                            defaultValue={
                              extractCache
                                ? extractCache.RedShiftColumnName
                                : ""
                            }
                          />
                          <div style={{ color: "red" }}>
                            {
                              redshift.formState.errors.RedShiftColumnName
                                ?.message
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div
                className="transformsfieldbutton"
                style={{ marginLeft: "26%", marginTop: "7%" }}
              >
                <div>
                  <button
                    className="transformsbutton"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    ),
    Metastore: (
      <div className="formLayout"> 
            {value === "Extract" ? (
              <form onSubmit={metaValue.handleSubmit(metaStoreFormSubmit)}>
              <>
              <div className="metaBucketfields">
              <div>
                <label className="fields">Task / File Name :</label>
                <input
                  style={{ height: "36.56px" }}
                  type="text"
                  className="form-control fields mt-1"
                  {...metaValue.register("MetaTaskFileName")}
                  placeholder="Enter Task / File Name"
                  defaultValue={extractCache ? extractCache.MetaTaskFileName : ""} />

                <div style={{ color: "red" }}>
                  {metaValue.formState.errors.MetaTaskFileName?.message}
                </div>
              </div>
                <div>
                  <label className="fields">Path :</label>
                  <input
                    style={{ height: "36.56px" }}
                    type="text"
                    className="form-control fields mt-1"
                    {...metaValue.register("MetaPath")}
                    placeholder="Enter Path"
                    defaultValue={extractCache ? extractCache.MetaPath : ""} />

                  <div style={{ color: "red" }}>
                    {metaValue.formState.errors.MetaPath?.message}
                  </div>
                </div>
                <div className="transformsfieldbutton">
               <div>
              <button
                className="transformsbutton"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
            <div>
              <button className="transformsbutton" type="Submit">
                Save
              </button>
            </div>
            </div>
            </div>
            </>
            </form>
            ) : (
              <form onSubmit={metaStoreFormValue.handleSubmit(metaStoreFormSubmit)}>    
              <>
                  <div className="radioinputfields">
                    <div>
                      <input
                        type="radio"
                        value="SaveAsPath"
                        checked={metaStoreData === "SaveAsPath"}
                        onChange={handleMetaDataValueChange}
                        id="SaveAsPath" />
                      <label htmlFor="SaveAsPath">
                        {" "}
                        <span style={{ marginLeft: "10px" }}> Save As Path</span>
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="SaveAsTable"
                        disabled
                        checked={metaStoreData === "SaveAsTable"}
                        onChange={handleMetaDataValueChange}
                        id="SaveAsTable" />
                      <label htmlFor="SaveAsTable">
                        {" "}
                        <span style={{ marginLeft: "10px" }}>
                          {" "}
                          Save As Table
                        </span>
                      </label>
                    </div>
                  </div><div className="metaBucketfields">
                    {metaStoreData === "SaveAsPath" ? (
                      <>
                        <div>
                          <label className="fields">Path :</label>
                          <input
                            style={{ height: "36.56px" }}
                            type="text"
                            className="form-control fields mt-1"
                            {...metaStoreFormValue.register("MetaPath")}
                            placeholder="Enter Path"
                            defaultValue={extractCache ? extractCache.MetaPath : ""} />

                          <div style={{ color: "red" }}>
                            {metaStoreFormValue.formState.errors.MetaPath?.message}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <label className="fields">Table Name :</label>
                          <input
                            style={{ height: "36.56px" }}
                            type="text"
                            className="form-control fields mt-1"
                            {...metaStoreFormValue.register("Table")}
                            placeholder="Enter Table Name"
                            defaultValue={extractCache ? extractCache.Table : ""} />

                          <div style={{ color: "red" }}>
                            {metaStoreFormValue.formState.errors.Table?.message}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  </>
                  <div className="transformsfieldbutton">
                    <div>
                      <button
                        className="transformsbutton"
                        onClick={() => setIsOpen(false)}
                      >
                      Cancel
                      </button>
                      </div>
                    <div>
                      <button className="transformsbutton" type="Submit">
                      Save
                      </button>
                    </div>
                  </div>
                </form>
            )}
      </div>
    ),
  };

  useEffect(() => {
    if (postgresqlOption === "AWS Secret manager") {
      console.log("into unregistering...");
      postgresql.unregister("UserName");
      postgresql.unregister("Password");
      redshift.unregister("RedShiftUserName");
      redshift.unregister("RedShiftPassword");
      redshift.unregister("RedShiftTaskName");
    }
  }, [postgresqlOption]);
  useEffect(() => {
    if (radioselectedOption === "History") {
      console.log("into unregistering", radioselectedOption);
      postgresql.unregister("ColumnName");
      postgresql.unregister("Condition");
    }
  }, [radioselectedOption]);

  useEffect(() => {
    if (value === "Load") {
      setLoad(false);
      postgresql.unregister("CdcOption");
      postgresql.unregister("ColumnName");
      postgresql.unregister("Condition");
      postgresql.unregister("TaskName");
      s3Bucket.unregister("TaskFileName");
    }
  }, []);

  useEffect(() => {
    console.log("into Value", valutOptions);
    if (valutOptions === "Choose") {
      console.log("into unregistering", valutOptions);
      postgresql.unregister("UserName");
      postgresql.unregister("Password");
    }
    if (redshiftvalutOptions === "Choose") {
      redshift.unregister("RedShiftUserName");
      redshift.unregister("RedShiftPassword");
    }
  }, [valutOptions]);

  return (
    <div
      // style={{ backgroundColor: "red", height: "79vh", marginTop: "5vh" }}
      className="card-background"
    >
      <div>
        <div className="mainformlayout">
          <div className="cardlayout">
            <div className="mainHeading">
              <h4> {value} </h4>
              <div>
                <CgCloseO
                  size={25}
                  onClick={navigateToComponent2}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <Line1 />
            <div className="button-container">
              <div
                className={`top-button ${
                  extractCache && extractCache.path === "s3Bucket"
                    ? "activepath"
                    : ""
                }`}
                onClick={() => toggleForm("s3Bucket")}
              >
                <div className="extractOptions">
                  <img src={s3Bucketlogo} alt="s3" height="20" />
                  {/* <BsBucketFill size={22} /> */}
                </div>
                <div className="extractOptionsContent">&ensp;S3</div>
              </div>

              <div
                className={`top-button ${
                  extractCache && extractCache.path === "Rdbms"
                    ? "activepath"
                    : ""
                }`}
                onClick={() => toggleForm("Rdbms")}
              >
                {/* <img src={RDBMSlogo} alt="RDBMS" style={{ height: "30px" }} /> */}
                <div className="extractOptions">
                  <img src={RDBMSlogo} alt="RDBMS" style={{ height: "30px" }} />
                  {/* <ImDatabase size={22} /> */}
                </div>
                <div className="extractOptionsContent">&ensp; RDBMS</div>
              </div>

              <div
                className={`top-button ${
                  extractCache && extractCache.path === "Redshift"
                    ? "activepath"
                    : ""
                }`}
                onClick={() => toggleForm("Redshift")}
                // disabled
              >
                <div className="extractOptions">
                  <img src={Redshiftlogo} alt="RedShift" />
                  {/* &ensp; <BiLogoAws size={29} className="mt-1" /> */}
                </div>
                {/* <RiDatabase2Fill size={18} /> */}
                <div className="extractOptionsContent">&ensp;RedShift</div>
              </div>

              <div
                className="top-button"
                onClick={() => toggleForm("Veeva")}
                style={{ cursor: "not-allowed", pointerEvents: "none" }}
              >
                <div className="extractOptions">
                  <img src={veevalogo} alt="Veeva" height={15} />
                  {/* <TbBrandVue size={22} /> */}
                </div>
                <div className="extractOptionsContent">&ensp;Veeva</div>
              </div>
              {/* Add Metastore*/}
              <div
                className={`top-button ${
                  extractCache && extractCache.path === "Metastore"
                    ? "activepath"
                    : ""
                }`}
                onClick={() => toggleForm("Metastore")}
              >
                <div className="extractOptions">
                  <img src={MetastoreLogo} alt="Metastore" height="20" />
                  {/* <BsBucketFill size={22} /> */}
                </div>
                <div className="extractOptionsContent">&ensp;Metastore</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center
            position: "fixed",
            top: 0,
            right: 0,
            width: "35%",
            height: "100%",
            backgroundColor: "#fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
            // padding: "30px 20px",
            boxSizing: "border-box",
            // zIndex: 9999,
            borderRadius: "20px 0px 0px 20px",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 20px",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              height: "70px",
            }}
          >
            <h4 className="Heading1">
              {currentForm === "s3Bucket" && (
                <div className="d-flex align-items-center">
                  {" "}
                  {/* <BsBucketFill size={22} /> */}
                  <img src={s3Bucketlogo} alt="s3" height="35" />
                  &ensp;S3
                </div>
              )}
              {currentForm === "Rdbms" && (
                <div className="d-flex align-items-center">
                  {" "}
                  {/* <ImDatabase size={22} /> */}
                  <img src={RDBMSlogo} alt="RDBMS" height="30" />
                  RDBMS
                </div>
              )}
              {currentForm === "Redshift" && (
                <div className="d-flex align-items-center">
                  {/* <BiLogoAws size={29} className="mt-1" /> */}
                  <img
                    src={Redshiftlogo}
                    alt="RedShift"
                    // className="oraclelogo"
                    width={35}
                  />{" "}
                  &ensp;Redshift{" "}
                </div>
              )}
              {currentForm === "Veeva" && (
                <div className="d-flex align-items-center">
                  <TbBrandVue size={22} />
                  &ensp;Veeva
                </div>
              )}
              {currentForm === "Metastore" && (
                <div className="d-flex align-items-center">
                  {" "}
                  {/* <BsBucketFill size={22} /> */}
                  <img src={MetastoreLogo} alt="s3" height="35" />
                  &ensp;Metastore
                </div>
              )}
            </h4>

            <CgCloseO
              size={20}
              onClick={() => setIsOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          {forms[currentForm]}
        </motion.div>
      )}
    </div>
  );
};

export default ExtractLoad;
