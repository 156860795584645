import { createSlice } from "@reduxjs/toolkit";
import { actions } from "react-redux-toastr";

const initialState = {
  nodes: [],
  edges: [],
  extract: [],
  transform: [],
  aggregate: [],
  join: [],
  dataQuality: [],
  custom: [],
};

const etlData = createSlice({
  name: '"EtlDetails',
  initialState: initialState,
  reducers: {
    addNodes: (state, actions) => {
      state.nodes = [...state.nodes, actions.payload];
      // state.nodes = actions.payload;
    },
    deleteNodes: (state, actions) => {
      const objectId = actions.payload;
      const index = state.nodes.findIndex((obj) => obj.id === objectId);
      if (index !== -1) {
        state.nodes.splice(index, 1);
      }
    },
    updateEdges: (state, actions) => {
      state.edges = [...state.edges, actions.payload];
    },
    deleteEdges: (state, actions) => {
      const objectId = actions.payload;
      const index = state.edges.findIndex((obj) => obj.id === objectId);
      if (index !== -1) {
        state.edges.splice(index, 1);
      }
    },
    updateExtract: (state, actions) => {
      const newObject = actions.payload;
      const existingIndex = state.extract.findIndex(
        (obj) => obj.id === newObject.id
      );

      if (existingIndex !== -1) {
        state.extract[existingIndex] = newObject;
      } else {
        state.extract.push(newObject);
      }
    },
    deleteExtract: (state, actions) => {
      const objectId = actions.payload;
      const index = state.extract.findIndex((obj) => obj.id === objectId);
      if (index !== -1) {
        state.extract.splice(index, 1);
      }
    },
    updateTransform: (state, actions) => {
      const newObject = actions.payload;
      const existingIndex = state.transform.findIndex(
        (obj) => obj.id === newObject.id
      );
      if (existingIndex !== -1) {
        state.transform[existingIndex] = newObject;
      } else {
        state.transform.push(newObject);
      }
    },
    deleteTransform: (state, actions) => {
      const objectId = actions.payload;
      const index = state.transform.findIndex((obj) => obj.id === objectId);
      if (index !== -1) {
        state.transform.splice(index, 1);
      }
    },
    updateAggregate: (state, actions) => {
      const newObject = actions.payload;
      const existingIndex = state.aggregate.findIndex(
        (obj) => obj.id === newObject.id
      );
      if (existingIndex !== -1) {
        state.aggregate[existingIndex] = newObject;
      } else {
        state.aggregate.push(newObject);
      }
    },
    deleteAggregate: (state, actions) => {
      const objectId = actions.payload;
      const index = state.aggregate.findIndex((obj) => obj.id === objectId);
      if (index !== -1) {
        state.aggregate.splice(index, 1);
      }
    },
    updateJoin: (state, actions) => {
      const newObject = actions.payload;
      const existingIndex = state.join.findIndex(
        (obj) => obj.id === newObject.id
      );
      if (existingIndex !== -1) {
        state.join[existingIndex] = newObject;
      } else {
        state.join.push(newObject);
      }
    },
    deleteJoin: (state, actions) => {
      const objectId = actions.payload;
      const index = state.join.findIndex((obj) => obj.id === objectId);
      if (index !== -1) {
        state.join.splice(index, 1);
      }
    },
    updateDataQuality: (state, actions) => {
      const newObject = actions.payload;
      const existingIndex = state.dataQuality.findIndex(
        (obj) => obj.id === newObject.id
      );
      if (existingIndex !== -1) {
        state.dataQuality[existingIndex] = newObject;
      } else {
        state.dataQuality.push(newObject);
      }
    },
    deleteDataQuality: (state, actions) => {
      const objectId = actions.payload;
      const index = state.dataQuality.findIndex((obj) => obj.id === objectId);
      if (index !== -1) {
        state.dataQuality.splice(index, 1);
      }
    },
    updateCustom: (state, actions) => {
      state.custom = [...state.custom, actions.payload];
    },
    resetMyState: (state) => {
      state.nodes = initialState.nodes;
      state.edges = initialState.edges;
      state.aggregate = initialState.aggregate;
      state.extract = initialState.extract;
      state.transform = initialState.transform;
      state.join = initialState.join;
      state.dataQuality = initialState.dataQuality;
      state.custom = initialState.custom;
    },
  },
});

export const {
  addNodes,
  deleteNodes,
  updateEdges,
  deleteEdges,
  updateExtract,
  deleteExtract,
  updateTransform,
  deleteTransform,
  updateAggregate,
  deleteAggregate,
  updateJoin,
  deleteJoin,
  updateDataQuality,
  deleteDataQuality,
  updateCustom,
  resetMyState,
} = etlData.actions;

export default etlData.reducer;
