import React, {useEffect, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  BsCloudCheckFill,
  BsDatabaseFillAdd,
  BsClipboard2Data,
} from "react-icons/bs";
import {
  IoMdCodeWorking,
  IoMdInfinite,
  IoMdLogOut,
  IoMdNotifications,
} from "react-icons/io";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import companyLogo from "../../assets/Agilisium02.png";
import profile from "../../assets/Ellipse 49.png";
import { BiCategory } from "react-icons/bi";
import "./Sidebar.scss";
import { ImTerminal } from "react-icons/im";
import axios from "axios";
import { getUrl } from "../api/Endpoint";

export default function Sidebar({ name, handleClose, show, ...props }) {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate()

  const [users, setUsers] = useState(false);
  
  const fetchUsers = async () => {
    try {
      const {data} = await axios.get('https://pharcomm360.agilisiumtech.com:444/auth/list_user')
      setUsers(data)
    } catch (err) {
      alert(err)
    }
  }

  const logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    fetchUsers()
  },[])

  return (
    <div
      className="d-md-block"
      style={{
        // height: "727px",
        // minWidth: "281px",
        // maxWidth: "281px",
        background: "#030A1D",
      }}
    >
      <Container style={{ paddingLeft: "15px" }}>
        <Row
          style={{
            marginTop: "25px",
            fontSize: "30px",
            fontStyle: "bold",
          }}
          className="d-flex  text-light font"
        >
          <img
            src={companyLogo}
            alt="BigCo Inc. logo"
            style={{ width: "70%" }} />
        </Row>
        <Row
          style={{
            marginTop: "45px",
          }}
          className=" text-light font"
        >
          {/* <Col> */}
          <div className="d-flex profile">
            <img src={profile} alt="BigCo Inc. logo" style={{ width: "20%" }} />
            <span style={{ fontSize: "15px" }}>
              Welcome
              <br /> {users ? users?.find(e => e.email === sessionStorage.getItem("email"))?.first_name : ""}
            </span>
            <div className="notification">
              <IoMdNotifications />
              &ensp;&ensp;
              <IoMdLogOut onClick={logout} />
            </div>
          </div>
          {/* </Col> */}
        </Row>
        {/* <Row
      style={{
        marginTop: "70px",
        height: "48px",
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
      }}
      className={`d-flex align-items-center nav-items ${
        splitLocation[1] === "" ||
        splitLocation[1] === "editcostcenter" ||
        splitLocation[1] === "createCostcenter"
          ? " activeitem"
          : " inactive"
      }`}
    >
      <Col>
        <NavLink to="/project" style={{ textDecoration: "none" }}>
          <div className="d-flex ms-2">
            <div className="text-light titlename font ms-2">
              Cost Center
            </div>
          </div>
        </NavLink>
      </Col>
    </Row> */}
        <NavLink to="/project" style={{ textDecoration: "none" }}>
          <Row
            style={{
              marginTop: "70px",
              height: "48px",
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              fontSize: "16px",
            }}
            className={`d-flex align-items-center nav-items ${splitLocation[1] === "" ||
                splitLocation[1] === "createproject" ||
                splitLocation[1] === "editproject"
                ? " activeitem"
                : " inactive"}`}
          >
            <Col>
              <div className="d-flex align-items-center">
                {/* <BsCloudCheckFill className="text-light mt-1" /> */}
                <BiCategory className="text-light mt-1" size={18} />
                <div
                  className="text-light titlename font ms-2"
                  style={{ height: "18px" }}
                >
                  Project
                </div>
              </div>
            </Col>
          </Row>
        </NavLink>
        <NavLink to="/cicd" style={{ textDecoration: "none" }}>
          <Row
            style={{
              // marginTop: "70px",
              height: "48px",
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              fontSize: "16px",
            }}
            className={`d-flex align-items-center nav-items ${splitLocation[1] === "cicd"
                ? // splitLocation[1] === "createproject" ||

                // splitLocation[1] === "editproject"
                " activeitem"
                : " inactive"}`}
          >
            <Col>
              <div className="d-flex align-items-center">
                {/* <BsCloudCheckFill className="text-light mt-1" /> */}
                <IoMdInfinite className="text-light mt-1" size={20} />
                <div
                  className="text-light titlename font ms-2"
                  style={{ height: "20px" }}
                >
                  CI-CD
                </div>
              </div>
            </Col>
          </Row>
        </NavLink>
        <NavLink to="/etl" style={{ textDecoration: "none", fontSize: "16px" }}>
          <Row
            style={{
              height: "48px",
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            }}
            className={`d-flex align-items-center nav-items ${splitLocation[1] === "etl" ? " activeitem" : " inactive"}`}
          >
            <Col>
              <div className="d-flex align-items-center">
                <IoMdCodeWorking className="text-light mt-1" size={20} />
                <div
                  className="text-light titlename font ms-2"
                  style={{ height: "21px" }}
                >
                  ETL
                </div>
              </div>
            </Col>
          </Row>
        </NavLink>
        {/* <NavLink to="" style={{ textDecoration: "none" }}>
      <Row
        style={{
          height: "48px",
          borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          fontSize: "16px",
        }}
        className={`d-flex align-items-center nav-items ${
          splitLocation[1] === "job" ? " activeitem" : " inactive"
        }`}
      >
        <Col>
          <div className="d-flex ms-2">
            <div className="text-light titlename font ms-2 ">JOB</div>
          </div>
        </Col>
      </Row>
    </NavLink>
    <Row
      style={{
        height: "48px",
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        fontSize: "16px",
      }}
      className={`d-flex align-items-center nav-items ${
        splitLocation[1] === "goverence" ? " activeitem" : " inactive"
      }`}
    >
      <Col>
        <NavLink to="" style={{ textDecoration: "none" }}>
          <div className="d-flex ms-2 font">
            <div className="text-light titlename ms-2">Goverence & Tag</div>
          </div>
        </NavLink>
      </Col>
    </Row>
    <Row
      style={{
        height: "48px",
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        fontSize: "16px",
      }}
      className={`d-flex align-items-center nav-items ${
        splitLocation[1] === "dashboard" ? " activeitem" : " inactive"
      }`}
    >
      <Col>
        <NavLink to="" style={{ textDecoration: "none" }}>
          <div className="d-flex  ms-2  font">
            <div className="text-light titlename ms-2">Dashboard</div>
          </div>
        </NavLink>
      </Col>
    </Row> */}
      </Container>
      {/* <Modal
      show={logoutModal}
      onHide={() => setLogoutModal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Logout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to Logout ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setLogoutModal(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={logout}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal> */}
    </div>
  );
}
