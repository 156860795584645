import React, { useContext, useEffect, useState } from "react";
import { chooseIamValidation, createIamValidation } from "../validation";
import { useForm, useController } from "react-hook-form";
import Select from "react-select";
import { zodResolver } from "@hookform/resolvers/zod";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { MyContext } from "../context/ContextApi";
import axios from "axios";
import { getUrl } from "../api/Endpoint";
import { ProjectCreation } from "../api/PostAction";
import { useNavigate } from "react-router";

const IAM = ({ mwaaData, selectedValue, ownorchestration, users }) => {
  const [expand, setExpand] = useState(true);
  const { allValues } = useContext(MyContext);
  const [roleoptions, setRoleOptions] = useState([]);
  const [noOptionsMessage, setNoOptionsMessage] = useState("Loading...");
  const [chooseIAM, setChooseIAM] = useState("Choose Iam");
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      chooseIAM === "Choose Iam" ? chooseIamValidation : createIamValidation
    ),
    mode: "all",
  });
  
  const Role = useController({
    name: "Role",
    control,
  });

  const handleChooseIamChange = (e) => {
    let value = e.target.value;
    setChooseIAM(value);
  };

  const fetchData = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let res = await axios.get(`${getUrl.iam}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      });
      let data = res.data;
      let e = data.map((e) => ({
        value: e,
        label: e,
      }));
      setRoleOptions(e);
    } catch (err) {
      setNoOptionsMessage("No Data");
      console.log("Error in Fetching IAM Role", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleSelectChange = (option) => {
    Role.field.onChange(option.value);
  };

  const handleSave = async (formValues) => { 
    formValues.iamOption = chooseIAM;
    navigate("/project");
    await ProjectCreation(
      allValues,
      mwaaData,
      selectedValue,
      ownorchestration,
      formValues,
      users
    );
  };

  return (
    <div className="formLayout" style={{ marginTop: "40px" }}>
      <div
        className="heading"
        data-toggle="collapse"
        data-target="#collapseExample4"
        onClick={() => setExpand((current) => !current)}
      >
        <h4>IAM</h4>
        {expand ? (
          <BsCaretUpFill
            className="icon"
            data-toggle="collapse"
            data-target="#collapseExample4"
            aria-expanded="false"
            aria-controls="collapseExample"
          />
        ) : (
          <BsCaretDownFill
            className="icon"
            data-toggle="collapse"
            data-target="#collapseExample4"
            aria-expanded="false"
            aria-controls="collapseExample"
          />
        )}
      </div>
      <form
        className="formDetails collapse show"
        id="collapseExample4"
        onSubmit={handleSubmit(handleSave)}
      >
        <div className="Radiobutton">
          <div className="form-check">
            <input
              type="radio"
              name="optradio"
              id="Choose Iam"
              value="Choose Iam"
              checked={chooseIAM === "Choose Iam"}
              defaultChecked={chooseIAM}
              onChange={handleChooseIamChange}
            />
            <label className="label" htmlFor="Choose Iam">
              Choose
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              name="optradio"
              id="Create Iam"
              value="Create Iam"
              checked={chooseIAM === "Create Iam"}
              onChange={handleChooseIamChange}
            />
            <label className="label" htmlFor="Create Iam">
              Create
            </label>
          </div>
        </div>

        <div className="">
          <div className="projectfields">
            {chooseIAM === "Choose Iam" && (
              <div>
                <Select
                  placeholder="Select Role"
                  onChange={handleSelectChange}
                  options={roleoptions}
                  noOptionsMessage={() => noOptionsMessage}
                />
                <div style={{ color: "red" }}>{errors.Role?.message}</div>
              </div>
            )}
            {chooseIAM === "Create Iam" && (
              <div>
                <input
                  name="Project"
                  type="text"
                  className="form-control"
                  {...register("RoleName")}
                  placeholder="Enter Role Name"
                  defaultValue={allValues.Project}
                  readOnly
                />
                <div style={{ color: "red" }}>{errors.RoleName?.message}</div>
              </div>
            )}
          </div>
          <div className="text-center">
            <button
              className="projectbutton"
              type="submit"
              data-toggle="collapse"
            >
              Create Project
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default IAM;
