import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useNavigate } from "react-router";
import {
  useForm,
  useController,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { cicdGitlabValidation } from "../validation";
import { MdRemoveCircleOutline } from "react-icons/md";
import { IoAddCircle } from "react-icons/io5";
import { CicdGitlab, ProjectCreation } from "../api/PostAction";
import Select from "react-select";
import { toast } from "react-toastify";
const Gitlab = ({
  setExpand,
  mwaaData,
  selectedValue,
  ownorchestration,
  noOptionmessage,
  projectOption,
}) => {
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(cicdGitlabValidation),
    mode: "all",
    defaultValues: {
      cicdData: [
        {
          Project: [],
          ProjectID: "",
          Branch: "",
          PersonalToken: "",
          Status: "",
          Filename: ".gitlab-ci.yml",
          Source: "",
          Destination: "",
          Flowdata: [],
        },
      ],
    },
  });
  // const defaultValue = { value: "Development", label: "Development" };
  const statusOptions = [
    { value: "Development", label: "Development" },
    { value: "QA", label: "QA" },
    { value: "Production", label: "Production" },
  ];
  const flowOptions = [
    { value: "ETL_to_s3", label: "ETL_to_s3" },
    { value: "ETL_to_databricks", label: "ETL_to_databricks" },
    { value: "DAG_to_s3", label: "DAG_to_s3" },
    { value: "DAG_to_airflow", label: "DAG_to_ec2" },
  ];
  const { fields, append, remove } = useFieldArray({
    name: "cicdData",
    control,
  });
  // const status = useController({
  //   name: "Status",
  //   control,
  //   // defaultValue: { value: "Development", label: "Development" },
  // });

  // const handleSelectChange = (option) => {
  //   status.field.onChange(option.value);
  //   // setProgress(option.value);
  // };

  const handleSave = async (data, event) => {
    setExpand((current) => !current);
    let formValues = data.cicdData;
    console.log("formValues", formValues);
    let a;
    await formValues.map(async (e, index) => {
      let cicdData = {
        cicd: "GitLab",
        Projectids: e.Project.value,
        ProjectID: e.ProjectID,
        Branch: e.Branch,
        PersonalToken: e.PersonalToken,
        Filename: e.Filename,
        Status: e.Status.map((e) => e.value),
        Source: e.Source,
        Destination: e.Destination,
        Flowdata: e.Flowdata.value,
      };
      a = await CicdGitlab(cicdData);

      // if (a !== undefined) {
      //   toast.success("");
      //   navigate("/project");
      // } else {
      //   toast.error("Creation Failed");
      // }
      // console.log(`cicdData git lab ${index}`, cicdData);
    });
    // if (a === undefined) {
    //   toast.error("Creation Failed");
    // } else {
    toast.success("CICD Created Successfully");
    navigate("/project");
    // }
  };
  return (
    <div>
      <div className="">
        <div className=" collapse show" id="collapseExample4">
          <form onSubmit={handleSubmit(handleSave)}>
            <div className="align-items-center">
              <p
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Note : Use a New YML for different repository under ETL &
                Airflow Details
              </p>
              {fields.map((field, index) => {
                return (
                  <>
                    <p style={{ marginLeft: "80px", fontWeight: "Bold" }}>
                      CICD Pipeline {index + 1}
                    </p>
                    <div className="projectfields" key={field.id}>
                      <div>
                        <Controller
                          control={control}
                          name={`cicdData.${index}.Project`}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={projectOption}
                              placeholder="Select Project"
                              noOptionsMessage={() => noOptionmessage}
                            />
                          )}
                        />
                        <div style={{ color: "red" }}>
                          {errors.cicdData?.[index]?.Project &&
                            "Select an option"}
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.ProjectID`)}
                          placeholder="Project ID"
                        />
                        <div style={{ color: "red" }} key={index}>
                          {errors.cicdData?.[index]?.ProjectID &&
                            errors.cicdData?.[index]?.ProjectID.message}
                        </div>
                      </div>
                      {/* <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.Repository`)}
                          placeholder="Repository Name"
                        />
                        <div style={{ color: "red" }} key={index}>
                          {errors.cicdData?.[index]?.Repository &&
                            errors.cicdData?.[index]?.Repository.message}
                        </div>
                      </div> */}
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.Branch`)}
                          placeholder="Branch"
                        />
                        <div style={{ color: "red" }}>
                          {errors.cicdData?.[index]?.Branch &&
                            errors.cicdData?.[index]?.Branch.message}
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.PersonalToken`)}
                          placeholder="PersonalToken"
                        />
                        <div style={{ color: "red" }}>
                          {errors.cicdData?.[index]?.PersonalToken &&
                            errors.cicdData?.[index]?.PersonalToken.message}
                        </div>
                      </div>

                      {/* <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.RegistrationToken`)}
                          placeholder="RegistrationToken"
                        />
                        <div style={{ color: "red" }}>
                          {errors.cicdData?.[index]?.RegistrationToken &&
                            errors.cicdData?.[index]?.RegistrationToken.message}
                        </div>
                      </div> */}

                      <div>
                        <Controller
                          control={control}
                          name={`cicdData.${index}.Status`}
                          render={({ field }) => (
                            <Select
                              options={statusOptions}
                              // {...register(`cicdData.${index}.Status`)}
                              isMulti
                              {...field}
                              // value={selectEnvironment}
                              placeholder="Select Environment"
                              // onChange={handleSelectChange}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  height: "38px",
                                  overflow: "auto", // Specify the desired height here
                                }),
                              }}
                            />
                          )}
                        />

                        <div style={{ color: "red" }}>
                          {errors.cicdData?.[index]?.Status &&
                            "Select an option"}
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.Filename`)}
                          placeholder="PersonalToken"
                          disabled="true"
                        />
                      </div>
                    </div>
                    <label
                      style={{
                        marginLeft: "80px",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      YML details
                    </label>
                    <div className="projectfields">
                      <div>
                        <Controller
                          control={control}
                          name={`cicdData.${index}.Flowdata`}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={flowOptions}
                              placeholder="Select Flow"
                            />
                          )}
                        />
                        {/* <Select
                            // name={`cicdData.${index}.Flowdata`}
                            {...register(`cicdData.${index}.Flowdata`)}
                            placeholder="Select Flow"
                            options={flowOptions}
                            // onChange={flowchange}
                            // ref={register()}
                            // value={flow}
                          /> */}
                        <div style={{ color: "red" }}>
                          {errors.cicdData?.[index]?.Flowdata &&
                            "Select an option"}
                        </div>
                      </div>
                      <div
                        className="grid-column-end"
                        // style={{ marginTop: "90px" }}
                      >
                        {fields.length !== 1 && (
                          <MdRemoveCircleOutline
                            className="ml-3"
                            style={{ fontSize: "30px", color: "red" }}
                            onClick={() => remove(index)}
                          />
                        )}
                        {fields.length - 1 === index && (
                          <IoAddCircle
                            className="ml-3"
                            onClick={() => {
                              append({
                                Project: [],
                                ProjectID: "",
                                Branch: "",
                                PersonalToken: "",
                                Status: "",
                                Filename: ".gitlab-ci.yml",
                                Source: "",
                                Destination: "",
                                Flowdata: [],
                              });
                            }}
                            style={{ fontSize: "30px", color: "#8b5ccc" }}
                          />
                        )}
                      </div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.Source`)}
                          placeholder="Source"
                        />
                        <div
                          style={{
                            color: "red",
                            minHeight: "20px",
                            maxheight: "40px",
                          }}
                        >
                          {errors.cicdData?.[index]?.Source &&
                            errors.cicdData?.[index]?.Source.message}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          {...register(`cicdData.${index}.Destination`)}
                          placeholder="Destination"
                        />
                        <div style={{ color: "red" }}>
                          {errors.cicdData?.[index]?.Destination &&
                            errors.cicdData?.[index]?.Destination.message}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="text-center">
                <button className="projectbutton" type="submit">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Gitlab;

// .refine(
//   (data) => !!data.Etlscripts || !!data.AirflowDags || !!data.Databricks,
//   {
//     message: "Atleast one Required",
//     path: ["Databricks"],
//   }
// )
