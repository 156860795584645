import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { editContext } from "../../context/ContextApi";
import { useForm, useController } from "react-hook-form";
import Select from "react-select";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProjectValidation } from "../../validation";
import { MdArrowBackIos } from "react-icons/md";
import "../editproject/editproject.css";
import axios from "axios";
import EditStorage from "../editstorage/editstorage";
import { ipAddress } from "../../api/IpAddress";

const Editprojects = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editvalues = location.state;
  console.log("InitialEditvalue1", editvalues);

  const [allValues, setAllValues] = useState({
    Project: editvalues.project_name,
    Costcenter: {
      value: editvalues.cost_center,
      label: editvalues.cost_center,
    },
    goverence_tag: "",
    exicistingBucket: editvalues.bucket_name,
    Pathfield: editvalues.path,
    Path: "",
    Clustersource: editvalues.cluster_source,
    Clustername: editvalues.cluster_name,
    Clustersize: {
      value: editvalues.cluster_size,
      label: editvalues.cluster_size,
    },
    Market: editvalues.market,
    Instancetype: editvalues.instance_type,
    SlaveInstanceCount: editvalues.slave_instance_count,
    MasterInstanceCount: editvalues.master_instance_count,
    AutominationSeconds: editvalues.autotermination_seconds,
    NodeTypeId: editvalues.node_type_id,
    DriverNodeTypeId: editvalues.driver_node_type_id,
    AutoTerminationMin: editvalues.autotermination_minutes,
    MinWorkers: editvalues.min_workers,
    MaxWorkers: editvalues.max_workers,
    run_time_version: editvalues.run_time_version,
    ReleaseLabel: editvalues.run_time_version,
    MaxCPU: editvalues.max_cpu,
    MaxMemory: editvalues.max_memory,
    MaxDisk: editvalues.max_disk,
    AutoTerminationSeconds: editvalues.autotermination_seconds,
  });
  console.log("qwertyhgtrew", allValues.Clustersize);
  const [Costcenter, setCostcenter] = useState("");
  const [CostcenterRegion, setCostcenterRegion] = useState("");
  // const [exicistingBucket, setExistingBucket] = useState("");
  const [pathfield, setPathfield] = useState("");
  const [chooseClustersource, setchooseClustersource] = useState("");
  const [Choosecluster, setChoosecluster] = useState("");
  // const [clustername, setClustername] = useState("");
  const [clusterSize, setClusterSize] = useState("");
  //   const [component, setComponent] = useState(false);

  const [costcenterOptions, setCostcenterOptions] = useState();

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setAllValues({ ...allValues, [name]: value });
    console.log("EditProject", allValues);
  };

  const changeCostcenter = (Costcenter) => {
    setCostcenter(Costcenter);
    // setAllValues({ ...allValues, goverence_tag: Costcenter.value });
  };
  const changeCostcenterRegion = (CostcenterRegion) => {
    setCostcenterRegion(CostcenterRegion);
  };

  // const changeexicistingBucket = (exicistingBucket) => {
  //   setExistingBucket(exicistingBucket);
  //   console.log({ exicistingBucket });
  // };

  // const changePathfield = (pathfield) => {
  //   setPathfield(pathfield);
  // };
  // const changeclusterSource = (clusterSource) => {
  //   setClusterSource(clusterSource);
  //   console.log({ clusterSource });
  // };
  const changeclusterSize = (clusterSize) => {
    setClusterSize(clusterSize);
  };

  const changeCluster = (Choosecluster) => {
    setChoosecluster(Choosecluster);
  };

  const chooseClustersource1 = (chooseClustersource) => {
    setchooseClustersource(chooseClustersource);
  };

  const CostcenterData = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let response = await axios.get(
        `${ipAddress.dev}/cost_center/list_get/`, {
          headers : {
            Authorization : "Bearer "  + token //Pass Token
          }
        }
      );

      let data = response.data;
      const mappedOptions = data.map((option) => ({
        value: option.governance_tag.toString(),
        label: option.cost_center,
      }));
      let result = [...mappedOptions];
      setCostcenterOptions(result);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    CostcenterData();
  }, []);

  // const [dataArray, setDataArray] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get('${ipAddress.dev}/cost_center/list_get/');
  //       setDataArray(response.data);
  //       for(let i=0 ; i<dataArray.length; i++){
  //         const values = dataArray[i].cost_center;
  //          console.log(values)
  //        }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchData();
  // },[] );

  const { register, control, handleSubmit, formState, isSubmitting } = useForm({
    resolver: zodResolver(ProjectValidation),
    mode: "all",
  });

  const { errors } = formState;

  const costcenter = useController({
    name: "costcenter",
    control,
    // defaultValue: Costcenter.value,
  });

  const handleSelectChange = (option) => {
    costcenter.field.onChange(option.value);
    allValues.Costcenter = option;
  };

  const Editprojectdata = (formValues) => {
    console.log("FinalEditProject", allValues);
    // setComponent(true);
    // setActiveStep(activeStep + 1);
  };

  return (
    <div className="main">
      {" "}
      <span
        style={{
          cursor: "pointer",
          fontFamily: "Poppins, sans-serif",
          marginLeft: "29px",
        }}
        onClick={() => navigate("/project")}
      >
        <MdArrowBackIos />
        Back
      </span>
      <div className="layout">
        <div className="editformLayout ">
          <form
            className="editformDetails"
            onSubmit={handleSubmit(Editprojectdata)}
          >
            <div className="editheading">
              <h4>Edit Project Details</h4>
            </div>
            <hr className="my-hr" />

            <div className="editprojectfields">
              <div>
                <input
                  className="form-control"
                  {...register("Project")}
                  placeholder="Enter Project Name"
                  onChange={changeHandler}
                  value={allValues.Project}
                  disabled
                />
                <div style={{ color: "red" }}>{errors.Project?.message}</div>
              </div>

              <div>
                <div>
                  <Select
                    placeholder="Select CostCenter"
                    onChange={handleSelectChange}
                    options={costcenterOptions}
                    value={allValues.Costcenter}
                    isDisabled
                  />
                </div>
                <div style={{ color: "red" }}>{errors.costcenter?.message}</div>
              </div>
            </div>

            {/* <div className="text-center">
              <button
                className="editprojectbutton"
                type="submit"
                disabled={isSubmitting}
              >
                Next
              </button>
            </div> */}

            {/* <button
                type="button"
                className="projectbutton mx-3"
                onClick={() => navigate("/project")}
              >
                Back
              </button>{" "} */}
            {/* <div className="text-center"></div>
              <button className="projectbutton" type="submit" 
              disabled={isSubmitting} 
              onClick={handleRevalidate}
              >
                Next
              </button>
            </div> */}
          </form>
        </div>
        <editContext.Provider
          value={{
            changeHandler,
            allValues,
            changeCostcenter,
            Costcenter,
            changeCostcenterRegion,
            CostcenterRegion,
            // changeexicistingBucket,
            // changeclusterSource,
            changeclusterSize,
            // exicistingBucket,
            // clusterSource,
            clusterSize,
            Choosecluster,
            changeCluster,
            setChoosecluster,
            pathfield,
            // changePathfield,
            setPathfield,
            chooseClustersource1,
            chooseClustersource,
            setClusterSize,
            // setExistingBucket,
          }}
        >
          {!errors.Project?.message && <div>{<EditStorage />}</div>}
        </editContext.Provider>
      </div>
    </div>
  );
};

export default Editprojects;
