import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm, useController } from "react-hook-form";
import Select from "react-select";
import "../ownorchestration.css";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  GitlabValidation,
  GithubValidation,
  CodecommitValidation,
} from "../../validation";
import { ipAddress } from "../../api/IpAddress";

const OwnOrchestration = ({
  setExpand,
  expand,
  selectedValue,
  setshowIam,
  ownorchestration,
  changeOwnorchestration,
}) => {
  const [selectedOption, setSelectedOption] = useState("Github");
  const [regionOptions, setRegionOptions] = useState([]);
  const [noOptionsMessage, setNoOptionsMessage] = useState("Loading...");
  const Github = useForm({
    resolver: zodResolver(GithubValidation),
  });

  const Gitlab = useForm({
    resolver: zodResolver(GitlabValidation),
  });

  const Codecommit = useForm({
    resolver: zodResolver(CodecommitValidation),
  });

  const ArchitectureOptions = [
    { value: "Linux x86-64 : amd64", label: "Linux x86-64 : amd64" },
    { value: "Linux x86 : 386", label: "Linux x86 : 386" },
    { value: "Linux arm : arm", label: "Linux arm : arm" },
    { value: "Linux arm64 : arm64", label: "Linux arm64 : arm64" },
  ];

  const EnvironmentOptions = [
    { value: "Dev", label: "Dev" },
    { value: "Prod", label: "Prod" },
    { value: "QA", label: "QA" },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setshowIam(false);
    ownorchestration.source = event.target.value;
  };

  const GitlabRegion = useController({
    name: "GitlabRegion",
    control: Gitlab.control,
    defaultValue: ownorchestration.labRegion.value,
  });

  const handleSelectGitlabRegion = (option) => {
    GitlabRegion.field.onChange(option.value);
    ownorchestration.labRegion = option;
  };

  const GithubRegion = useController({
    name: "GithubRegion",
    control: Github.control,
    defaultValue: ownorchestration.hubRegion.value,
  });

  const handleSelectGithubRegion = (option) => {
    GithubRegion.field.onChange(option.value);
    ownorchestration.hubRegion = option;
  };

  const CodecommitRegion = useController({
    name: "CodecommitRegion",
    control: Codecommit.control,
    defaultValue: ownorchestration.codeRegion.value,
  });

  const handleSelectCodecommitRegion = (option) => {
    CodecommitRegion.field.onChange(option.value);
    ownorchestration.codeRegion = option;
  };

  const GithubArchitecture = useController({
    name: "GithubArchitecture",
    control: Github.control,
    defaultValue: ownorchestration.hubarchitecture.value,
  });

  const handleSelectGithubArchitecture = (option) => {
    GithubArchitecture.field.onChange(option.value);
    ownorchestration.hubarchitecture = option;
  };

  const GitlabArchitecture = useController({
    name: "GitlabArchitecture",
    control: Gitlab.control,
    defaultValue: ownorchestration.labarchitecture.value,
  });

  const handleSelectGitlabArchitecture = (option) => {
    GitlabArchitecture.field.onChange(option.value);
    ownorchestration.labarchitecture = option;
  };

  const Environment = useController({
    name: "Environment",
    control: Gitlab.control,
    defaultValue: ownorchestration.labEnv.value,
  });

  const handleSelectEnvironment = (option) => {
    Environment.field.onChange(option.value);
    ownorchestration.labEnv = option;
  };

  const GitlabSubmit = async (data) => {
    setExpand(false);
    setshowIam(true);
    console.log("ownorchestration", ownorchestration);
    console.log("Gitlab Values", data);
    // navigate("/project");
    // await ProjectCreation(allValues, selectedValue);
    // Gitlab.reset();
  };

  const GithubSubmit = async (data) => {
    setExpand(false);
    setshowIam(true);
    console.log("ownorchestration", ownorchestration);
    console.log("Github Values", data);
    // navigate("/project");
    // await ProjectCreation(allValues, selectedValue);
    // Github.reset();
  };

  const CodecommitSubmit = async (data) => {
    setExpand(false);
    setshowIam(true);
    console.log("ownorchestration", ownorchestration);
    console.log("Codecommit Values", data);
    // navigate("/project");
    // await ProjectCreation(allValues, selectedValue);
    // Codecommit.reset();
  };

  const RegionData = async () => {
    try {
      let token = sessionStorage.getItem("accessToken");
      let [regions] = await Promise.all([
        axios.get(`${ipAddress.dev}/project/availability_zones/`, {
          headers : {
            Authorization : "Bearer "  + token //Pass Token
          }
        }),
      ]);

      let regiondata = regions.data;
      const regionoptions = regiondata.map((option) => ({
        value: option,
        label: option,
      }));
      let result = [...regionoptions];
      setRegionOptions(result);
      if (result.length === 0) {
        setNoOptionsMessage("No data");
      }
    } catch (err) {
      setNoOptionsMessage("No data");
      console.log(err);
    }
  };

  useEffect(() => {
    RegionData();
  }, []);

  return (
    <>
      <div className="ownorchestrationradiobtn">
        <label>
          <input
            type="checkbox"
            value="Github"
            checked={selectedOption === "Github"}
            onChange={handleOptionChange}
          />
          <span style={{ marginLeft: "5px" }}> GitHub</span>
        </label>
        <label>
          <input
            type="checkbox"
            value="Gitlab"
            checked={selectedOption === "Gitlab"}
            onChange={handleOptionChange}
          />
          <span style={{ marginLeft: "5px" }}> GitLab</span>
        </label>
        <label>
          <input
            type="checkbox"
            value="Codecommit"
            checked={selectedOption === "Codecommit"}
            onChange={handleOptionChange}
          />
          <span style={{ marginLeft: "5px" }}> CodeCommit</span>
        </label>
      </div>

      {selectedOption === "Gitlab" && (
        <div className="formLayout">
          <form onSubmit={Gitlab.handleSubmit(GitlabSubmit)}>
            <div className="Orchestrationfields">
              <div>
                <div>
                  <Select
                    placeholder="Select Region"
                    value={ownorchestration.labRegion}
                    onChange={handleSelectGitlabRegion}
                    options={regionOptions}
                    noOptionsMessage={() => noOptionsMessage}
                  />
                  {Gitlab.formState.errors.GitlabRegion?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Gitlab.register("labIp")}
                  value={ownorchestration.labIp}
                  placeholder="Host Public IP"
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Gitlab.formState.errors.labIp?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Gitlab.register("labEc2")}
                  value={ownorchestration.labEc2}
                  placeholder="EC 2 User"
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Gitlab.formState.errors.labEc2?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Gitlab.register("labPemkey")}
                  placeholder="Pem Key"
                  value={ownorchestration.labPemkey}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Gitlab.formState.errors.labPemkey?.message}
                </div>
              </div>
              <div>
                <div>
                  <Select
                    placeholder="Select Architecture"
                    value={ownorchestration.labarchitecture}
                    onChange={handleSelectGitlabArchitecture}
                    options={ArchitectureOptions}
                  />
                  {Gitlab.formState.errors.GitlabArchitecture?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Gitlab.register("labrepoLink")}
                  value={ownorchestration.labrepoLink}
                  onChange={changeOwnorchestration}
                  placeholder="https://gitlab.com"
                />
                <div style={{ color: "red" }}>
                  {Gitlab.formState.errors.BaseLocation?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Gitlab.register("labtoken")}
                  placeholder="Registration Token"
                  value={ownorchestration.labtoken}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Gitlab.formState.errors.labtoken?.message}
                </div>
              </div>
              <div>
                <div>
                  <Select
                    placeholder="Select Environment"
                    value={ownorchestration.labEnv}
                    onChange={handleSelectEnvironment}
                    options={EnvironmentOptions}
                  />
                  {Gitlab.formState.errors.Environment?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                className="Orchestrationbutton"
                type="submit"
                data-toggle="collapse"
                data-target={`${Gitlab.isValid ? "" : "#collapseExample3"}`}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      )}

      {selectedOption === "Github" && (
        <div className="formLayout">
          <form onSubmit={Github.handleSubmit(GithubSubmit)}>
            <div className="Orchestrationfields">
              <div>
                <div>
                  <Select
                    placeholder="Select Region"
                    onChange={handleSelectGithubRegion}
                    value={ownorchestration.hubRegion}
                    options={regionOptions}
                    noOptionsMessage={() => noOptionsMessage}
                  />
                  {Github.formState.errors.GithubRegion?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Github.register("hubIp")}
                  placeholder="Host Public IP"
                  value={ownorchestration.hubIp}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Github.formState.errors.hubIp?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Github.register("hubEc2")}
                  placeholder="EC 2 User"
                  value={ownorchestration.hubEc2}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Github.formState.errors.hubEc2?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Github.register("hubPemkey")}
                  placeholder="Pem Key"
                  value={ownorchestration.hubPemkey}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Github.formState.errors.hubPemkey?.message}
                </div>
              </div>
              <div>
                <div>
                  <Select
                    placeholder="Select Architecture"
                    value={ownorchestration.hubarchitecture}
                    onChange={handleSelectGithubArchitecture}
                    options={ArchitectureOptions}
                  />
                  {Github.formState.errors.GithubArchitecture?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Github.register("hubrepoLink")}
                  placeholder="Repository link"
                  value={ownorchestration.hubrepoLink}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Github.formState.errors.hubrepoLink?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Github.register("hubtoken")}
                  placeholder="RegistrationToken"
                  value={ownorchestration.hubtoken}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Github.formState.errors.RegistrationToken?.message}
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                className="Orchestrationbutton"
                type="submit"
                data-toggle="collapse"
                data-target={`${Github.isValid ? "" : "#collapseExample3"}`}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      )}

      {selectedOption === "Codecommit" && (
        <div className="formLayout">
          <form onSubmit={Codecommit.handleSubmit(CodecommitSubmit)}>
            <div className="Orchestrationfields">
              <div>
                <div>
                  <Select
                    placeholder="Select Region"
                    value={ownorchestration.codeRegion}
                    onChange={handleSelectCodecommitRegion}
                    options={regionOptions}
                    noOptionsMessage={() => noOptionsMessage}
                  />
                  {Codecommit.formState.errors.CodecommitRegion?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Codecommit.register("codeIp")}
                  placeholder="Host Public IP"
                  value={ownorchestration.codeIp}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Codecommit.formState.errors.codeIp?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Codecommit.register("codeEc2")}
                  placeholder="EC 2 User"
                  value={ownorchestration.codeEc2}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Codecommit.formState.errors.codeEc2?.message}
                </div>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...Codecommit.register("codePemkey")}
                  placeholder="Pem Key"
                  value={ownorchestration.codePemkey}
                  onChange={changeOwnorchestration}
                />
                <div style={{ color: "red" }}>
                  {Codecommit.formState.errors.codePemkey?.message}
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                className="Orchestrationbutton"
                type="submit"
                data-toggle="collapse"
                data-target={`${
                  Codecommit.formState.isValid ? "#collapseExample3" : ""
                }`}
                // data-target=
              >
                {" "}
                Next
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default OwnOrchestration;
