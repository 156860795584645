import axios, { all } from "axios";
import { postUrl, getUrl, putUrl } from "./Endpoint.js";
import { toast } from "react-toastify";

export async function ProjectCreation(
  allValues,
  mwaaData,
  selectedValue,
  Iam,
) {
  console.log("sele",allValues)
  let now = new Date();
  let userEmail = sessionStorage.getItem("email");

  console.log("Creating project section", allValues,
  mwaaData,
  selectedValue);
  console.log(userEmail);

  let projectBody = {
    tenant_id: 1,
    region: allValues.Region.value,
    project_name: allValues.Project,
    governance_tag: Number(allValues.goverence_tag),
    created_by: userEmail,
    created_date: now.toISOString().slice(0, -1),
    last_updated_by: userEmail,
    last_updated_date: now.toISOString().slice(0, -1),
    bring_your_own: Iam.iamOption === "Create Iam" ? false : true,
  };
  let storageBody;
    storageBody = {
      tenant_id: 1,
      bucket_name:allValues.BucketFlag === "Choose Bucket"? allValues.exicistingBucket.value : allValues.Bucketname,
      region: allValues.Region.value,
      location:allValues.BucketFlag === "Choose Bucket"? [allValues.Pathfield.value] : allValues.Path,
      created_by: userEmail,
      bring_your_own: allValues.BucketFlag === "Choose Bucket" ? true : false, 
      created_date: now.toISOString().slice(0, -1),
      last_updated_by: userEmail,
      last_updated_date: now.toISOString().slice(0, -1),
    };
 
  let computeBody;
  let computeSize;

  if (allValues.ClusterFlag === "Choose Cluster") {
    
    if (allValues.Chooseclustersource.value === "AWS-EMR-Classic") {
      computeBody = {
        tenant_id: 1,
        cluster_id: allValues.Choosecluster.value,
        created_by: userEmail,
        bring_your_own: allValues.ClusterFlag === "Choose Cluster" ? true :false, 
        created_date: now.toISOString().slice(0, -1),
        last_updated_by: userEmail,
        last_updated_date: now.toISOString().slice(0, -1),
      };
      computeSize = {
        cluster_size:
          allValues.Clustersize.label === "Custom"
            ? allValues.EMRClustersizeName
            : "Own Size",
        cluster_name: allValues.Choosecluster.label,
        cluster_source: allValues.Chooseclustersource.label,
        market:
          allValues.Clustersize.label === "Custom"
            ? allValues.Market
            : allValues.ChooseMarket,
            
        instance_type:
          allValues.Clustersize.label === "Custom"
            ? allValues.Instancetype
            : allValues.ChooseInstancetype,
        master_instance_count:
          allValues.Clustersize.label === "Custom"
            ? allValues.MasterInstanceCount
            : allValues.ChooseMasterInstanceCount,
        slave_instance_count:
          allValues.Clustersize.label === "Custom"
            ? allValues.SlaveInstanceCount
            : allValues.ChooseSlaveInstanceCount,
        autotermination_seconds:
          allValues.Clustersize.label === "Custom"
            ? allValues.AutoterminationSeconds
            : allValues.ChooseAutoTerminationSec,
      };
    }
    if (allValues.Chooseclustersource.value === "Databricks") {
      console.log("Data@@@");
      computeBody = {
        tenant_id: 1,
        cluster_id: allValues.Choosecluster.value,
        instance_profile_arn:
          allValues.Clustersize.label === "Custom"
            ? allValues.DatabricksCustomARN
            : allValues.profileARN,
        created_by: userEmail,
        bring_your_own: allValues.ClusterFlag === "Choose Cluster" ? true : false,
        created_date: now.toISOString().slice(0, -1),
        last_updated_by: userEmail,
        last_updated_date: now.toISOString().slice(0, -1),
      };
      computeSize = {
        cluster_source: allValues.Chooseclustersource.label,
        cluster_size:
        allValues.Clustersize.label === "Custom"
        ? allValues.DatabricksClustersizeName
        : "Own Size",    
        cluster_name: allValues.Choosecluster.label,
        min_workers:
          allValues.Clustersize.label === "Custom"
            ? allValues.MinWorkers
            : allValues.ChooseMinWorkers,
        max_workers:
          allValues.Clustersize.label === "Custom"
            ? allValues.MaxWorkers
            : allValues.ChooseMaxWorkers,
        node_type_id:
          allValues.Clustersize.label === "Custom"
            ? allValues.NodeTypeId
            : allValues.ChooseNodeTypeId,
            
        driver_node_type_id:
          allValues.Clustersize.label === "Custom"
            ? allValues.NodeTypeId
            : allValues.ChooseDriverNodeTypeId,
            
        autotermination_minutes:
          allValues.Clustersize.label === "Custom"
            ? allValues.AutoTerminationMin
            : allValues.ChooseAutoTerminationMin,
      };
    }
  } else {
    let createCluster;
    if (allValues.Clustersource.value === "AWS-EMR-Classic") {
      computeBody = {
        tenant_id: 1,
        cluster_id: allValues.Clustersize.value, // ID
        created_by: userEmail,
        bring_your_own: allValues.ClusterFlag === "Choose Cluster" ? true : false,
        created_date: now.toISOString().slice(0, -1),
        last_updated_by: userEmail,
        last_updated_date: now.toISOString().slice(0, -1),
      };
      computeSize = {
        cluster_size:
          allValues.Clustersize.label === "Custom"
            ? allValues.EMRClustersizeName
            : allValues.Clustersize.label,
        cluster_name: allValues.Clustername,
        cluster_source: allValues.Clustersource.label,
        market:
          allValues.Clustersize.label === "Custom"
            ? allValues.Market
            : selectedValue?.market,
        instance_type:
          allValues.Clustersize.label === "Custom"
            ? allValues.Instancetype
            : selectedValue?.instance_type,
        master_instance_count:
          allValues.Clustersize.label === "Custom"
            ? allValues.MasterInstanceCount
            : selectedValue?.master_instance_count,
        slave_instance_count:
          allValues.Clustersize.label === "Custom"
            ? allValues.SlaveInstanceCount
            : selectedValue?.slave_instance_count,
        autotermination_seconds:
          allValues.Clustersize.label === "Custom"
            ? allValues.AutoterminationSeconds
            : selectedValue?.autotermination_seconds,
      };
    } else if (allValues.Clustersource.value === "Databricks") {
      console.log("#$$$$$$$$$")
      computeBody = {
        tenant_id: 1,
        cluster_id: allValues.Clustersize.value, // ID
        instance_profile_arn:
          allValues.Clustersize.label === "Custom"
            ? allValues.DatabricksCustomARN
            : allValues.profileARN,
        created_by: userEmail,
        bring_your_own: allValues.ClusterFlag === "Choose Cluster" ? true : false,
        created_date: now.toISOString().slice(0, -1),
        last_updated_by: userEmail,
        last_updated_date: now.toISOString().slice(0, -1),
      };
      computeSize = {
        cluster_source: allValues.Clustersource.label,
        cluster_size:
          allValues.Clustersize.label === "Custom"
            ? allValues.DatabricksClustersizeName
            : allValues.Clustersize.label,
        cluster_name: allValues.Clustername,
        min_workers:
          allValues.Clustersize.label === "Custom"
            ? allValues.MinWorkers
            : selectedValue?.min_workers,
        max_workers:
          allValues.Clustersize.label === "Custom"
            ? allValues.MaxWorkers
            : selectedValue?.max_workers,
        node_type_id:
          allValues.Clustersize.label === "Custom"
            ? allValues.NodeTypeId
            : selectedValue?.node_type_id,
        driver_node_type_id:
          allValues.Clustersize.label === "Custom"
            ? allValues.NodeTypeId
            : selectedValue?.driver_node_type_id,
        autotermination_minutes:
          allValues.Clustersize.label === "Custom"
            ? allValues.AutoTerminationMin
            : selectedValue?.autotermination_minutes,
      };
    } else if (allValues.Clustersource.value === "AWS-Serverless") {
      computeBody = {
        tenant_id: 1,
        cluster_source: allValues.Clustersource.label,
        cluster_name: allValues.Clustername,
        max_cpu: allValues.MaxCPU,
        max_memory: allValues.MaxMemory,
        max_disk: allValues.MaxDisk,
        run_time_version: allValues.ReleaseLabel,
        autotermination_minutes: allValues.AutoTerminationMinutesServerless,
        created_by: userEmail,
        bring_your_own: allValues.ClusterFlag === "Choose Cluster" ? true : false,
        created_date: now.toISOString().slice(0, -1),
        last_updated_by: userEmail,
        last_updated_date: now.toISOString().slice(0, -1),
      };
    }
  }
  let orchesBody;
  if (allValues?.inOrchcreation === "MWAA") {
    orchesBody = {
      vpc_db_id: 2,
      tenant_id: 1,
      vpc_id: allValues.vpcID,
      mwaa_environment_name: allValues.EnvName,
      mwaa_environment_class_size: allValues.EnvClass.value,
      mwaa_environment_class: mwaaData.mwaa_environment_class,
      mwaa_minworkers: mwaaData.mwaa_minworkers,
      mwaa_maxworkers: mwaaData.mwaa_maxworkers,
      mwaa_schedulers: mwaaData.mwaa_schedulers,
      securitygroupids: allValues.SecurityGroups,
      subnetids: allValues.Subnets,
      mwaa_kms_key: allValues.Kmskey,
      created_by: userEmail,
      bring_your_own: allValues.inOrchcreation === "MWAA" || "Airflow on EKS" ? false : true,
      created_date: now.toISOString().slice(0, -1),
      last_updated_by: userEmail,
      last_updated_date: now.toISOString().slice(0, -1),
    };
  }
  let roleBody;
  roleBody = {
    tenant_id: 1,
    role_name: Iam.Role,
  };
  if (Iam.iamOption === "Create Iam") {
    delete roleBody.role_name;
  }
  try {
    let body;
    if (allValues.Clustersource.value === "AWS-Serverless") {
      console.log("!!!!!!!!!!!!!")
      body = {
        project: projectBody,
        storage: storageBody,
        compute: computeBody,
        orchestration: orchesBody,
        role: roleBody,
      };
    } else {
      console.log("######")

      body = {
        project: projectBody,
        storage: storageBody,
        compute: computeBody,
        compute_size: computeSize,
        orchestration: orchesBody,
        role: roleBody,
      };
    }
    console.log("body===>", body);
    let token = sessionStorage.getItem("accessToken");
    let response;
    response = await axios.post(`${postUrl.wrapper}`, body, {
      headers : {
        Authorization : token ? ("Bearer " + token)  : undefined,   // Pass access-token
      }
    });
    console.log("Project Created response===>", response);
    toast.success("Record Added! Sent For Approval");
  } catch (Error) {
    console.log("Error in creating", Error);
  }
  
}

async function statusUpdate(
  projectID,
  storageID,
  computeID,
  sizeID,
  orchestratorID
) {
  try {
    let token = sessionStorage.getItem("accessToken");
    let res = await axios.get(
      `${getUrl.finalizeReport}${projectID}/${storageID}/${computeID}/${sizeID}/${orchestratorID}`, {
        headers : {
          Authorization : "Bearer "  + token //Pass Token
        }
      }
    );
    let resultData = res.data;
    return resultData;
  } catch (err) {
    console.error("Error in statusUpdate", err);
  }
}

export async function wrapper(data, setResponse) {

  console.log("data",data)
  let projectID = data.project_id;
  let storageID = data.storage_id;
  let computeID = data.compute_id;
  let sizeID = data.size_id;
  let token = sessionStorage.getItem("accessToken");
  let orchestratorID = data.orchestration_id;
  let toastID1;
  toastID1 = toast.warning("Services Creating!", {
    autoClose: false,
    icon: false,
  });
  let result = await statusUpdate(
    projectID,
    storageID,
    computeID,
    sizeID,
    orchestratorID
  );

  let status = "In Progress";
  result.project.status = status;
  result.storage.status = status;
  result.compute.status = status;
  result.compute_size.status = status;
  result.orchestration.status = status;

  try {
    let res2 = await axios.put(
      `${putUrl.finalizeInprogress}${projectID}/${storageID}/${computeID}/${sizeID}/${orchestratorID}`,
      result, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      }
    );

    setResponse(res2.data);
    console.log("after put", res2);
    let res3 = await statusUpdate(
      projectID,
      storageID,
      computeID,
      sizeID,
      orchestratorID
    );
    let activeStatus = "Active";
    res3.project.status = activeStatus;
    res3.storage.status = activeStatus;
    res3.compute.status = activeStatus;
    res3.compute_size.status = activeStatus;
    res3.orchestration.status = activeStatus;

    let res4 = await axios.post(
      `${postUrl.finalService}${projectID}/${storageID}/${computeID}/${sizeID}/${orchestratorID}`,
      res3,
      {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      },
      {
        timeout: 300000,
      } 
    );
    toast.dismiss(toastID1);
    toast.success("Project Active");
    console.log("final result", res4.data);
    setResponse(res4.data);
  } catch (err) {
    toast.error("Failed");
    toast.dismiss(toastID1);
    console.log("error in wrapper", err);
  }
}

export async function CicdGithub(cicdData) {
  let now = new Date();
  let userEmail = sessionStorage.getItem("email");
  let cicdBody = {
    project_id: cicdData.ProjectID,
    service: cicdData.cicd,
    method_name: cicdData.Flowdata,
    source: cicdData.Source,
    destination: cicdData.Destination,
    branch: cicdData.Branch,
    token: cicdData.Token,
    jobs: cicdData.Status,
    owner: cicdData.Username,
    file_name: cicdData.Filename,
    repo: cicdData.Repository,
    created_by: userEmail,
    created_date: now.toISOString().slice(0, -1),
    last_updated_by: userEmail,
    last_updated_date: now.toISOString().slice(0, -1),
  };
  console.log("body", cicdBody);
  try {
    let token = sessionStorage.getItem("accessToken");
    let res = await axios.post(`${postUrl.githubCicd}`, cicdBody, {
      headers : {
        Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
      }
    });
    console.log("CicdGithub Res", res);
    return res.data.message;
  } catch (err) {
    console.log("error in CICD Github Post Action", err);
  }
}

export async function CicdGitlab(cicdData) {
  let now = new Date();
  let userEmail = sessionStorage.getItem("email");
  let cicdBody = {
    project_id: cicdData.Projectids,
    gitlab_project_id: cicdData.ProjectID,
    service: cicdData.cicd,
    method_name: cicdData.Flowdata,
    source: cicdData.Source,
    destination: cicdData.Destination,
    branch: cicdData.Branch,
    token: cicdData.PersonalToken,
    stages: cicdData.Status,
    file_name: cicdData.Filename,
    created_by: userEmail,
    created_date: now.toISOString().slice(0, -1),
    last_updated_by: userEmail,
    last_updated_date: now.toISOString().slice(0, -1),
  };
  console.log("body", cicdBody);
  try {
    let token = sessionStorage.getItem("accessToken");
    let res = await axios.post(`${postUrl.gitlabCicd}`, cicdBody, {
      headers : {
        Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
      }
    });
    console.log("CicdGitlab Res", res);
    return res.data;
  } catch (err) {
    console.log("error in CICD CicdGitlab Post Action", err);
  }
}

export async function rejectWrapper(data, setResponse) {
  let projectID = data.project_id;
  let storageID = data.storage_id;
  let computeID = data.compute_id;
  let sizeID = data.size_id;
  let orchestratorID = data.orchestration_id;
  let toastID1;
  toastID1 = toast.warning("Rejection in Progress!", {
    autoClose: false,
    icon: false,
  });
  let result = await statusUpdate(
    projectID,
    storageID,
    computeID,
    sizeID,
    orchestratorID
  );

  let status = "Rejected";
  result.project.status = status;
  result.storage.status = status;
  result.compute.status = status;
  result.compute_size.status = status;
  result.orchestration.status = status;
  let iscurrent = false;
  result.project.is_current = iscurrent;
  result.storage.is_current = iscurrent;
  result.compute.is_current = iscurrent;
  result.compute_size.is_current = iscurrent;
  result.orchestration.is_current = iscurrent;
  
  try {
    let token = sessionStorage.getItem("accessToken");
    let res2 = await axios.put(
      `${putUrl.finalizeInprogress}${projectID}/${storageID}/${computeID}/${sizeID}/${orchestratorID}`,
      result, {
        headers : {
          Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
        }
      }
    );
    toast.dismiss(toastID1);
    setResponse(res2.data);
    toast.success("Project Rejected");
    console.log("after rejected", res2);
    return res2.data;
  } catch (err) {
    toast.error("Rejection Failed");
    console.log("Error in Reject Wrapper", err);
  }
}

export async function rejectNotification(body) {
  try {
    let token = sessionStorage.getItem("accessToken");
    let res = await axios.post(`${postUrl.rejectNotify}`, body, {
      headers : {
        Authorization : token ? ("Bearer " + token)  : undefined   // Pass access-token
      }
    });
    console.log("rejectNotification", res);
  } catch (err) {
    console.log("Error in Notification For Rejection", err);
  }
}

// Login page
export const loginEntry = async (ele) => {
  try {

    const params = new URLSearchParams()
    params.append("username", ele.Username)      // change the object format
    params.append("password", ele.Password)
    console.log("Params", params);
    let res = await axios.post(`${postUrl.login}`, params);
    
    let token;
    if(res?.data?.access_token)
      token = res?.data?.access_token

    toast.success("Logged In Successfully!!!");

    return res;

  } catch (err) {

    toast.error("Authentication Failed!!!");

    console.log("Error in login", err);

  }

};