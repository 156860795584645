import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../context/ContextApi";
import { useForm, useController } from "react-hook-form";
import Select from "react-select";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProjectValidation } from "../validation";
import CreateStorage from "../createstorage/storage";
import "../createproject/project.css";
import { MdArrowBackIos } from "react-icons/md";
import axios from "axios";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { ipAddress } from "../api/IpAddress";

const Createproject = () => {
  const navigate = useNavigate();
  const [allValues, setAllValues] = useState({
    StepProcess: "project",
    Project: "",
    Costcenter: "",
    goverence_tag: "",
    exicistingBucket: "",
    Pathfield: "",
    BucketFlag: "Choose Bucket",
    Bucketname: "",
    Region: "",
    Path: "",
    ClusterFlag: "Choose Cluster",
    Chooseclustersource: "",
    Choosecluster: "",
    Clustersource: "",
    Clustername: "",
    Clustersize: "",
    profileARN: "",
    ChooseMarket: "",
    ChooseInstancetype: "",
    ChooseSlaveInstanceCount: "",
    ChooseMasterInstanceCount: "",
    ChooseAutoTerminationSec: "",
    ChooseNodeTypeId: "",
    ChooseDriverNodeTypeId: "",
    ChooseAutoTerminationMin: "",
    ChooseMinWorkers: "",
    ChooseMaxWorkers: "",
    ChooseReleaseLabel: "",
    ChooseMaxCPU: "",
    ChooseMaxMemory: "",
    ChooseMaxDisk: "",
    ChooseAutoTerminationMinutesServerless: "",
    Market: "SPOT",
    Instancetype: "",
    SlaveInstanceCount: "",
    MasterInstanceCount: "",
    AutoTerminationSec: "",
    DatabricksClustersizeName: "",
    ServerlessClustersizeName: "",
    EMRClustersizeName: "",
    DatabricksCustomARN: "",
    NodeTypeId: "",
    AutoTerminationMin: "",
    MinWorkers: "",
    MaxWorkers: "",
    ReleaseLabel: "emr-6.9.0",
    MaxCPU: "",
    MaxMemory: "",
    MaxDisk: "",
    AutoTerminationMinutesServerless: 15,
    inOrchcreation: "MWAA",
    EnvName: "",
    EnvClass: "",
    s3Bucket: "",
    dagPath: "",
    s3Path: "",
    Kmskey: "",
    vpcID: "",
    Subnets: "",
    SecurityGroups: "",
    dagFolder: "",
    airflowEnv: "",
    dockerFile: `From apache/airflow:2.4.3-python3.10 
USER airflow 
RUN pip install apache-airflow[amazon]`,
    orchesRemoteLogging: "",
    airflowMinnode: "",
    airflowMaxnode: "",
    remotePath: "",
    airflowVersion: "2.4.3",
    kubernetsVersion: "1.26",
    postgresSql: "15.2",
  });

  const [component, setComponent] = useState(false);
  const [costcenterOptions, setCostcenterOptions] = useState([]);
  const [expand, setExpand] = useState(true);
  const [regionOptions, setRegionOptions] = useState([]);
  const [noOptionsMessage, setNoOptionsMessage] = useState("Loading...");

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setAllValues({ ...allValues, [name]: value });
  };

  const CostcenterData = async () => {
    if (costcenterOptions.length === 0) {
      try {
        let token = sessionStorage.getItem("accessToken");
        let [response, regions] = await Promise.all([
          axios.get(`${ipAddress.dev}/cost_center/list_get/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }),
          axios.get(`${ipAddress.dev}/project/availability_zones/`, {
            headers : {
              Authorization : "Bearer "  + token //Pass Token
            }
          }),
        ]);
        let data = response.data;
        const filteredOptions = data.filter(
          (item) => item.cost_center !== null
        );
        const mappedOptions = filteredOptions.map((option) => ({
          value: option.governance_tag.toString(),
          label: option.cost_center,
        }));
        let result = [...mappedOptions];
        setCostcenterOptions(result);
        if (result.length === 0) {
          setNoOptionsMessage("No data");
        }

        let data2 = regions.data;
        const regionoptions = data2.map((option) => ({
          value: option,
          label: option,
        }));
        let result2 = [...regionoptions];
        setRegionOptions(result2);
        if (result2.length === 0) {
          setNoOptionsMessage("No data");
        }
      } catch (err) {
        setNoOptionsMessage("No data");
        console.log(err);
      }
    }
  };

  useEffect(() => {
    CostcenterData();
  }, []);

  const { register, control, handleSubmit, formState } = useForm({
    resolver: zodResolver(ProjectValidation),
    mode: "all",
  });
  const { errors } = formState;

  const costcenter = useController({
    name: "costcenter",
    control,
  });

  // async function handleRevalidate() {
  //   await validateAllFields();
  // }
  const region = useController({
    name: "Region",
    control,
    defaultValue: allValues.Region.value,
  });

  const handleSelectRegion = (option) => {
    region.field.onChange(option.value);
    allValues.Region = option;
  };

  const handleSelectChange = (option) => {
    costcenter.field.onChange(option.value);
    allValues.Costcenter = option;
    allValues.goverence_tag = option.value;
  };

  const projectdata = async (formValues) => {
    setExpand((current) => !current);
    console.log({ formValues });
    console.log({ allValues });
    setComponent(true);
  };

  return (
    <div className="main">
      {" "}
      <span
        style={{
          cursor: "pointer",
          marginLeft: "29px",
        }}
        onClick={() => navigate("/project")}
      >
        <MdArrowBackIos />
        Back
      </span>
      <div className="layout">
        <div className="formLayout">
          <div
            className="heading"
            data-toggle="collapse"
            data-target="#collapseExample"
            onClick={() => setExpand((current) => !current)}
          >
            <h4>Project</h4>
            {expand ? (
              <BsCaretUpFill
                className="icon"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              />
            ) : (
              <BsCaretDownFill
                className="icon"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              />
            )}
          </div>
          <form
            className="formDetails collapse show "
            id="collapseExample"
            onSubmit={handleSubmit(projectdata)}
          >
            {" "}
            <div className="projectfields">
              <div>
                <input
                  name="Project"
                  type="text"
                  className="form-control"
                  {...register("Project")}
                  placeholder="Enter Project Name"
                  value={allValues.Project}
                  onChange={changeHandler}
                  // value={ProjectDetails.projectName}
                  // onChange={(e) => dispatch(updateProjectName(e.target.value))}
                />
                <div style={{ color: "red", height: "10px" }}>
                  {errors.Project?.message}
                </div>
              </div>
              <div>
                <div>
                  <Select
                    // {...register("costcenter")}
                    placeholder="Select CostCenter"
                    value={allValues.Costcenter}
                    onChange={handleSelectChange}
                    options={costcenterOptions}
                    noOptionsMessage={() => noOptionsMessage}
                  />
                </div>
                <div style={{ color: "red" }}>{errors.costcenter?.message}</div>
              </div>

              <div>
                <div>
                  <Select
                    placeholder="Select Region"
                    value={allValues.Region}
                    onChange={handleSelectRegion}
                    options={regionOptions}
                    noOptionsMessage={() => noOptionsMessage}
                  ></Select>
                </div>
                <div style={{ color: "red" }}>{errors.Region?.message}</div>
              </div>
            </div>
            <div className="text-center">
              <button
                className="projectbutton"
                type="submit"
                data-toggle="collapse"
                data-target={`${
                  allValues.Project === "" ||
                  allValues.Costcenter === "" ||
                  allValues.Region === ""
                    ? ""
                    : "#collapseExample"
                }`}
                // disabled={isSubmitting}
                // onClick={handleRevalidate}
              >
                Next
              </button>
            </div>
          </form>
        </div>
        <MyContext.Provider
          value={{
            changeHandler,
            allValues,
          }}
        >
          {!errors.Project?.message && (
            <div>{component && <CreateStorage />}</div>
          )}
        </MyContext.Provider>
      </div>
    </div>
  );
};

export default Createproject;
