import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import "../../components/customcompute/customcompute.css";
import {
  CustomComputeValidation,
  CustomComputeAWS,
  CustomAWSServerlessvalidation,
} from "../validation";
import { MyContext } from "../context/ContextApi";

const CustomCompute = ({ setShowOrchestration, setExpand }) => {
  const { allValues, changeHandler } = useContext(MyContext);

  function createResolver(schema) {
    return zodResolver(schema);
  }

  const { register, handleSubmit, formState } = useForm({
    resolver: createResolver(CustomComputeValidation),
  });

  const Awsemr = useForm({
    resolver: createResolver(CustomComputeAWS),
  });

  const AWSServerless = useForm({
    resolver: createResolver(CustomAWSServerlessvalidation),
  });

  const { errors } = formState;

  const CustomComputeData = async (formValues) => {
    allValues["AutoTerminationSec"] = formValues.AutoTerminationSec;
    allValues["MasterInstanceCount"] = formValues.MasterInstanceCount;
    allValues["SlaveInstanceCount"] = formValues.SlaveInstanceCount;
    console.log("CustomComputeData", formValues);
    console.log({ allValues });
    setShowOrchestration(true);
    setExpand(false);
  };
  const CustomDatabricks = async (formValues) => {
    allValues["AutoTerminationMin"] = formValues.AutoTerminationMin;
    allValues["MinWorkers"] = formValues.MinWorkers;
    allValues["MaxWorkers"] = formValues.MaxWorkers;
    console.log("CustomDatabricks", formValues);
    console.log("in CustomDatabricks", allValues);
    setShowOrchestration(true);
    setExpand(false);
  };

  const CustomAWSServerless = async (formValues) => {
    console.log("in AWSServerless", allValues);
    setShowOrchestration(true);
    setExpand(false);
  };

  return (
    <div className="">
      <div className="">
        <h5 style={{ marginLeft: "5%" }}>Custom Compute</h5>
      </div>
      <div className="formLayout">
        {allValues.Clustersource.value === "Databricks" && (
          <>
            <form
              className="computeform"
              onSubmit={handleSubmit(CustomDatabricks)}
            >
              <div className="computefields">
                <div>
                  <input
                    className="form-control"
                    {...register("DatabricksClustersizeName")}
                    placeholder="Enter ClustersizeName"
                    value={allValues.DatabricksClustersizeName}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {errors.DatabricksClustersizeName?.message}
                  </div>
                </div>
                <div>
                  <input
                    className="form-control"
                    {...register("DatabricksCustomARN")}
                    placeholder="Enter DatabricksCustomARN"
                    value={allValues.DatabricksCustomARN}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {errors.DatabricksCustomARN?.message}
                  </div>
                </div>

                <div>
                  <input
                    className="form-control"
                    {...register("NodeTypeId")}
                    placeholder="Enter NodeTypeId  (Example: i3.xlarge)"
                    value={allValues.NodeTypeId}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {errors.NodeTypeId?.message}
                  </div>
                </div>

                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...register("AutoTerminationMin")}
                    placeholder="Enter AutoTerminationMin"
                    value={allValues.AutoTerminationMin}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {errors.AutoTerminationMin?.message}
                  </div>
                </div>

                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...register("MinWorkers")}
                    placeholder="Enter MinWorkers"
                    value={allValues.MinWorkers}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {errors.MinWorkers?.message}
                  </div>
                </div>

                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...register("MaxWorkers")}
                    placeholder="Enter MaxWorkers"
                    value={allValues.MaxWorkers}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {errors.MaxWorkers?.message}
                  </div>
                </div>
              </div>

              <div className="text-center">
                <button
                  className="projectbutton mt-4"
                  type="submit"
                  data-toggle="collapse"
                  data-target={formState.isValid ? "#collapseExample2" : ""}
                >
                  Next
                </button>
              </div>
            </form>
          </>
        )}
        {allValues.Clustersource.value === "AWS-EMR-Classic" && (
          <>
            <form
              className="computeform"
              onSubmit={Awsemr.handleSubmit(CustomComputeData)}
            >
              <div className="computefields">
                <div>
                  <input
                    className="form-control"
                    {...Awsemr.register("EMRClustersizeName")}
                    placeholder="Enter ClustersizeName"
                    value={allValues.EMRClustersizeName}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {Awsemr.formState.errors.EMRClustersizeName?.message}
                  </div>
                </div>
                <div>
                  <input
                    className="form-control"
                    {...Awsemr.register("Market")}
                    placeholder="Enter Market"
                    value={`Market = ${allValues.Market}`}
                    readOnly
                  />
                  <div style={{ color: "red" }}>
                    {Awsemr.formState.errors.Market?.message}
                  </div>
                </div>

                <div>
                  <input
                    className="form-control"
                    {...Awsemr.register("Instancetype")}
                    placeholder="Enter InstanceType (Example: i3.xlarge)"
                    value={allValues.Instancetype}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {Awsemr.formState.errors.Instancetype?.message}
                  </div>
                </div>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...Awsemr.register("MasterInstanceCount")}
                    placeholder="Enter MasterInstanceCount"
                    value={allValues.MasterInstanceCount}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {Awsemr.formState.errors.MasterInstanceCount?.message}
                  </div>
                </div>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    {...Awsemr.register("SlaveInstanceCount")}
                    placeholder="Enter SlaveInstanceCount"
                    value={allValues.SlaveInstanceCount}
                    onChange={changeHandler}
                  />
                  <div style={{ color: "red" }}>
                    {Awsemr.formState.errors.SlaveInstanceCount?.message}
                  </div>
                </div>

                <div>
                  <input
                    type="number"
                    {...Awsemr.register("AutoTerminationSec")}
                    className="form-control"
                    placeholder="Enter AutoTerminationSeconds"
                    value={allValues.AutoTerminationSec}
                    onChange={changeHandler}
                  />

                  <div style={{ color: "red" }}>
                    {Awsemr.formState.errors.AutoTerminationSec?.message}
                  </div>
                </div>
              </div>

              <div className="text-center">
                <button
                  className="projectbutton mt-4"
                  type="submit"
                  data-toggle="collapse"
                  data-target={
                    Awsemr.formState.isValid ? "#collapseExample2" : ""
                  }
                >
                  Next
                </button>
              </div>
            </form>
          </>
        )}

        {allValues.Clustersource.value === "AWS-Serverless" && (
          <form
            className="computeform"
            onSubmit={AWSServerless.handleSubmit(CustomAWSServerless)}
          >
            <div className="computefields">
              {/* <div>
                <input
                  className="form-control"
                  {...AWSServerless.register("ServerlessClustersizeName")}
                  placeholder="Enter ClustersizeName"
                  value={allValues.ServerlessClustersizeName}
                  onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {
                    AWSServerless.formState.errors.ServerlessClustersizeName
                      ?.message
                  }
                </div>
              </div> */}
              <div>
                <input
                  className="form-control"
                  {...AWSServerless.register("ReleaseLabel")}
                  placeholder="Enter ReleaseLabel (Eg: emr-6.9.0)"
                  value={allValues.ReleaseLabel}
                  onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {AWSServerless.formState.errors.ReleaseLabel?.message}
                </div>
              </div>

              <div>
                <input
                  type="number"
                  className="form-control"
                  {...AWSServerless.register("MaxCPU")}
                  placeholder="Enter MaxCPU"
                  value={allValues.MaxCPU}
                  onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {AWSServerless.formState.errors.MaxCPU?.message}
                </div>
              </div>

              <div>
                <input
                  type="number"
                  className="form-control"
                  {...AWSServerless.register("MaxMemory")}
                  placeholder="Enter MaxMemory"
                  value={allValues.MaxMemory}
                  onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {AWSServerless.formState.errors.MaxMemory?.message}
                </div>
              </div>

              <div>
                <input
                  className="form-control"
                  {...AWSServerless.register("MaxDisk")}
                  placeholder="Enter MaxDisk"
                  value={allValues.MaxDisk}
                  onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {AWSServerless.formState.errors.MaxDisk?.message}
                </div>
              </div>

              <div>
                <input
                  type="number"
                  className="form-control"
                  {...AWSServerless.register(
                    "AutoTerminationMinutesServerless"
                  )}
                  placeholder="Enter AutoTerminationMinutes"
                  value={allValues.AutoTerminationMinutesServerless}
                  onChange={changeHandler}
                />
                <div style={{ color: "red" }}>
                  {
                    AWSServerless.formState.errors
                      .AutoTerminationMinutesServerless?.message
                  }
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                className="projectbutton mt-4"
                type="submit"
                data-toggle="collapse"
                data-target={
                  AWSServerless.formState.isValid ? "#collapseExample2" : ""
                }
              >
                Next
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CustomCompute;
