import { React, useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { CgCloseO } from "react-icons/cg";
import { motion } from "framer-motion";
import { Line5, Line6, Line7, Line8, Line11, Line3, Line12 } from "../line";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AddColumnValidation,
  RenameColumnValidation,
  ConcatValidation,
  FilterValidation,
  DropColumnValidation,
  DropDuplicateValidation,
  PivotValidation,
  OrderByValidation,
  SelectColumnValidation,
} from "../../validation";
import "../transforms/transforms.css";
import { useSelector, useDispatch } from "react-redux";
import { updateTransform } from "../../../store/reducers/etlSlice";
import Select from "react-select";
const Transforms = ({ setAddColumn, selected, data }) => {
  // const { control } = useForm({});
  const dispatch = useDispatch();
  const EtlDetails = useSelector((state) => state.etl);
  const transformCache = EtlDetails.transform?.find((ele) => ele.id === data);
  const PageClosing = () => {
    setAddColumn(false);
  };

  const [AddColumnselectedOption, setAddColumnselectedOption] = useState(null);
  const [filterselectedOption, setFilterSelectedOption] = useState(null);
  const [AggregateselectedOption, setAggregateselectedOption] = useState(null);

  const FilterOperatorsOptions = [
    { value: "==", label: "==" },
    { value: "!=", label: "!=" },
    { value: ">", label: ">" },
    { value: "<", label: "<" },
    { value: ">=", label: ">=" },
    { value: "<=", label: "<=" },
  ];
  const AddColumnOperatorsOptions = [
    { value: "+", label: "+" },
    { value: "-", label: "-" },
    { value: "*", label: "*" },
    { value: "/", label: "/" },
    { value: ">", label: ">" },
    { value: "<", label: "<" },
  ];

  const AggregateTypeOptions = [
    { value: "sum", label: "sum" },
    { value: "min", label: "min" },
    { value: "max", label: "max" },
    { value: "avg", label: "avg" },
  ];

  const AddColumn = useForm({
    resolver: zodResolver(AddColumnValidation),
  });

  const RenameColumn = useForm({
    resolver: zodResolver(RenameColumnValidation),
  });

  const Concat = useForm({
    resolver: zodResolver(ConcatValidation),
  });

  const Filter = useForm({
    resolver: zodResolver(FilterValidation),
  });

  const DropColumn = useForm({
    resolver: zodResolver(DropColumnValidation),
  });

  const SelectColumn = useForm({
    resolver: zodResolver(SelectColumnValidation),
  });
  const DropDuplicate = useForm({
    resolver: zodResolver(DropDuplicateValidation),
  });

  const Pivot = useForm({
    resolver: zodResolver(PivotValidation),
  });

  const OrderBy = useForm({
    resolver: zodResolver(OrderByValidation),
  });

  const AddColumnOperator = useController({
    name: "AddColumnOperator",
    control: AddColumn.control,
    defaultValue: transformCache ? transformCache.AddColumnOperator : "",
  });

  const AddColumnhandleSelectChange = (selectedOption) => {
    setAddColumnselectedOption(selectedOption);
    AddColumnOperator.field.onChange(selectedOption.value);
  };

  const FilterOperator = useController({
    name: "FilterOperator",
    control: Filter.control,
    defaultValue: transformCache ? transformCache.FilterOperator : "",
  });

  const FilterhandleSelectChange = (selectedOption) => {
    setFilterSelectedOption(selectedOption);
    FilterOperator.field.onChange(selectedOption.value);
  };

  const AggregateType = useController({
    name: "AggregateType",
    control: Pivot.control,
    defaultValue: transformCache ? transformCache.AggregateType : "",
  });

  const AggregatehandleSelectChange = (selectedOption) => {
    setAggregateselectedOption(selectedOption);
    AggregateType.field.onChange(selectedOption.value);
  };

  const AddColumnSubmit = (value) => {
    console.log("AddColumnSubmit Values", value);
    AddColumn.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    console.log("addcolumn", a);
    dispatch(updateTransform(a));
    console.log("Store transform", EtlDetails.transform);
  };

  const RenameColumnSubmit = (value) => {
    console.log("RenameColumnSubmit Values", value);
    RenameColumn.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store RenameColumn", EtlDetails.transform);
  };

  const ConcatSubmit = (value) => {
    console.log("ConcatSubmit Values", value);
    Concat.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store concat", EtlDetails.transform);
  };

  const FilterSubmit = (value) => {
    console.log("FilterSubmit Values", value);
    Filter.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store Filter", EtlDetails.transform);
  };

  const DropColumnSubmit = (value) => {
    console.log("DropColumnSubmit Values", value);
    DropColumn.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store Filter", EtlDetails.transform);
  };
  const SelectColumnSubmit = (value) => {
    console.log("DropColumnSubmit Values", value);
    SelectColumn.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store Filter", EtlDetails.transform);
  };

  const DropDuplicateSubmit = (value) => {
    console.log("DropDuplicateSubmit Values", value);
    DropDuplicate.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store Filter", EtlDetails.transform);
  };

  const PivotSubmit = (value) => {
    console.log("PivotSubmit Values", value);
    console.log("PivotAggregateType", AggregateselectedOption);
    Pivot.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store Filter", EtlDetails.transform);
  };

  const OrderBySubmit = (value) => {
    console.log("OrderBySubmit Values", value);
    OrderBy.reset();
    setAddColumn(false);
    let a = { id: data, ...value };
    dispatch(updateTransform(a));
    console.log("Store Filter", EtlDetails.transform);
  };
  useEffect(() => {
    if (transformCache) {
      if (transformCache.id.startsWith("AddColumn")) {
        AddColumn.setValue(
          "AddColumnOperator",
          transformCache.AddColumnOperator
        );
        setAddColumnselectedOption(
          AddColumnOperatorsOptions.filter(
            (e) => e.value === transformCache.AddColumnOperator
          )
        );
      }
      if (transformCache.id.startsWith("Filter")) {
        Filter.setValue("FilterOperator", transformCache.FilterOperator);
        setFilterSelectedOption(
          FilterOperatorsOptions.filter(
            (e) => e.value === transformCache.FilterOperator
          )
        );
      }
      if (transformCache.id.startsWith("Pivot")) {
        Pivot.setValue("AggregateType", transformCache.AggregateType);
        setAggregateselectedOption(
          AggregateTypeOptions.filter(
            (e) => e.value === transformCache.AggregateType
          )
        );
      }
    }
  }, []);

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      transition={{ duration: 0.8 }}
      style={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center
        position: "fixed",
        top: 0,
        right: 0,
        width: "35%",
        height: "100%",
        backgroundColor: "#fff",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
        // padding: "30px 20px",
        boxSizing: "border-box",
        // zIndex: 9999,
        borderRadius: "20px 0px 0px 20px",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          height: "70px",
        }}
      >
        <h4 className="Heading1">
          {selected === "AddColumn" && "Add Column"}
          {selected === "RenameColumn" && "Rename Column"}
          {selected === "Concat" && "Concat"}
          {selected === "Filter" && "Filter"}
          {selected === "Pivot" && "Pivot"}
          {selected === "DropDuplicate" && "Drop Duplicate"}
          {selected === "DropColumn" && "Drop Column"}
          {selected === "SelectColumn" && "Select Column"}
          {selected === "OrderBy" && "OrderBy"}
        </h4>

        <CgCloseO
          size={20}
          onClick={PageClosing}
          style={{ cursor: "pointer" }}
        />
      </div>
      {/* <Line7 /> */}
      {selected === "Concat" && (
        <div className="mt-4">
          <div className="formLayout ">
            {/* <div className="Heading">
              <h4 className="Heading1">Concat</h4>
              <Line7 />
            </div> */}
            <form onSubmit={Concat.handleSubmit(ConcatSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Concat.register("TaskName")}
                    placeholder="e.g Concat"
                    defaultValue={
                      transformCache ? transformCache?.TaskName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Concat.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Column 1 :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Concat.register("AddColumn1")}
                    placeholder="e.g First Name"
                    defaultValue={
                      transformCache ? transformCache?.AddColumn1 : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Concat.formState.errors.AddColumn1?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Column 2 :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Concat.register("AddColumn2")}
                    placeholder="e.g Last Name"
                    defaultValue={
                      transformCache ? transformCache?.AddColumn2 : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Concat.formState.errors.AddColumn2?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Target Column Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Concat.register("TargetName")}
                    placeholder="e.g Full Name"
                    defaultValue={
                      transformCache ? transformCache?.TargetName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Concat.formState.errors.TargetName?.message}
                  </div>
                </div>
              </div>

              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "AddColumn" && (
        <div>
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">AddColumn</h4>
              <Line5 />
            </div> */}
            <form onSubmit={AddColumn.handleSubmit(AddColumnSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...AddColumn.register("TaskName")}
                    placeholder="e.g AddColumn"
                    defaultValue={
                      transformCache ? transformCache?.TaskName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {AddColumn.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Existing Column Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...AddColumn.register("Condition")}
                    placeholder="e.g Department Id"
                    defaultValue={
                      transformCache ? transformCache?.Condition : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {AddColumn.formState.errors.Condition?.message}
                  </div>
                </div>
                <div>
                  <label className="fields ">Add Column Operator:</label>
                  <Select
                    value={AddColumnselectedOption}
                    options={AddColumnOperatorsOptions}
                    onChange={AddColumnhandleSelectChange}
                    className="fields mt-1"
                    placeholder="Select Operator"
                    // defaultValue={defaultOption}
                  />
                  {AddColumn.formState.errors.AddColumnOperator?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
                <div>
                  <label className="fields">Value :</label>
                  <input
                    type="number"
                    className="form-control fields mt-1"
                    {...AddColumn.register("Value")}
                    placeholder="e.g 123"
                    defaultValue={transformCache ? transformCache?.Value : ""}
                  />
                  <div style={{ color: "red" }}>
                    {AddColumn.formState.errors.Value?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Target Column Name :</label>
                  <input
                    type="text"
                    className="form-control fields  mt-1"
                    {...AddColumn.register("TargetName")}
                    placeholder="e.g Sales Department"
                    defaultValue={
                      transformCache ? transformCache?.TargetName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {AddColumn.formState.errors.TargetName?.message}
                  </div>
                </div>
              </div>

              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "RenameColumn" && (
        <div className="mt-4">
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">RenameColumn</h4>
              <Line8 />
            </div> */}
            <form onSubmit={RenameColumn.handleSubmit(RenameColumnSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control mt-1 fields"
                    {...RenameColumn.register("TaskName")}
                    placeholder="e.g RenameColumn"
                    defaultValue={
                      transformCache ? transformCache?.TaskName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {RenameColumn.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Existing Column Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...RenameColumn.register("Oldcolumn")}
                    placeholder="e.g Brands"
                    defaultValue={
                      transformCache ? transformCache?.Oldcolumn : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {RenameColumn.formState.errors.Oldcolumn?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Updated Column Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...RenameColumn.register("TargetName")}
                    placeholder="e.g Brand Name"
                    defaultValue={
                      transformCache ? transformCache?.TargetName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {RenameColumn.formState.errors.TargetName?.message}
                  </div>
                </div>
              </div>

              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "Filter" && (
        <div className="mt-4">
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">Filter</h4>
              <Line6 />
            </div> */}
            <form onSubmit={Filter.handleSubmit(FilterSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Filter.register("TaskName")}
                    placeholder="e.g Filter"
                    defaultValue={
                      transformCache ? transformCache?.TaskName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Filter.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Existing Column Name:</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Filter.register("TargetColumn")}
                    placeholder="e.g Department Id"
                    defaultValue={
                      transformCache ? transformCache?.TargetColumn : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Filter.formState.errors.TargetColumn?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Filter Operator:</label>
                  <Select
                    value={filterselectedOption}
                    options={FilterOperatorsOptions}
                    onChange={FilterhandleSelectChange}
                    className="mt-1"
                    placeholder="Select Operator"
                  />
                  {Filter.formState.errors.FilterOperator?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
                <div>
                  <label className="fields">Value :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Filter.register("Value")}
                    placeholder="e.g 02 "
                    defaultValue={transformCache ? transformCache?.Value : ""}
                  />
                  <div style={{ color: "red" }}>
                    {Filter.formState.errors.Value?.message}
                  </div>
                </div>
              </div>
              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "Pivot" && (
        <div>
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">Pivot</h4>
              <Line6 />
            </div> */}
            <form onSubmit={Pivot.handleSubmit(PivotSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Pivot.register("TaskName")}
                    placeholder="e.g Pivot"
                    defaultValue={transformCache ? transformCache.TaskName : ""}
                  />
                  <div style={{ color: "red" }}>
                    {Pivot.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">GroupBy Column :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Pivot.register("GroupbyColumn")}
                    placeholder="e.g Pincode"
                    defaultValue={
                      transformCache ? transformCache.GroupbyColumn : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Pivot.formState.errors.GroupbyColumn?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Pivot Column :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Pivot.register("PivotColumn")}
                    placeholder="e.g Category"
                    defaultValue={
                      transformCache ? transformCache.PivotColumn : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Pivot.formState.errors.PivotColumn?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Aggregate Type:</label>
                  <Select
                    value={AggregateselectedOption}
                    options={AggregateTypeOptions}
                    onChange={AggregatehandleSelectChange}
                    className="mt-1 fields"
                    placeholder="Select Aggregate"
                  />
                  {Pivot.formState.errors.AggregateType?.message && (
                    <span style={{ color: "red" }}>Select an option</span>
                  )}
                </div>
                <div>
                  <label className="fields">Aggregate Column :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...Pivot.register("AggregateColumn")}
                    placeholder="e.g Price"
                    defaultValue={
                      transformCache ? transformCache.AggregateColumn : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {Pivot.formState.errors.AggregateColumn?.message}
                  </div>
                </div>
              </div>
              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "DropDuplicate" && (
        <div>
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">DropDuplicate</h4>
              <Line11 />
            </div> */}
            <form onSubmit={DropDuplicate.handleSubmit(DropDuplicateSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...DropDuplicate.register("TaskName")}
                    placeholder="e.g Drop Duplicate"
                    defaultValue={transformCache ? transformCache.TaskName : ""}
                  />
                  <div style={{ color: "red" }}>
                    {DropDuplicate.formState.errors.TaskName?.message}
                  </div>
                </div>
              </div>
              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "DropColumn" && (
        <div className="mt-4">
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">DropColumn</h4>
              <Line3 />
            </div> */}
            <form onSubmit={DropColumn.handleSubmit(DropColumnSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...DropColumn.register("TaskName")}
                    placeholder="e.g Drop Column"
                    defaultValue={
                      transformCache ? transformCache?.TaskName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {DropColumn.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Column :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...DropColumn.register("Columns")}
                    placeholder="e.g Address"
                    defaultValue={transformCache ? transformCache?.Columns : ""}
                  />
                  {/* <p>* You Can Add Multiple Columns using (, comma)</p> */}
                  <div style={{ color: "red" }}>
                    {DropColumn.formState.errors.Columns?.message}
                  </div>
                </div>
              </div>
              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "SelectColumn" && (
        <div className="mt-4">
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">SelectColumn</h4>
              <Line3 />
            </div> */}
            <form onSubmit={SelectColumn.handleSubmit(SelectColumnSubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...SelectColumn.register("TaskName")}
                    placeholder="e.g Select Column"
                    defaultValue={
                      transformCache ? transformCache?.TaskName : ""
                    }
                  />
                  <div style={{ color: "red" }}>
                    {SelectColumn.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Column :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...SelectColumn.register("Columns")}
                    placeholder="e.g Brand Name, Category"
                    defaultValue={transformCache ? transformCache?.Columns : ""}
                  />
                  {/* <p>* You Can Add Multiple Columns using (, comma)</p> */}
                  <div style={{ color: "red" }}>
                    {SelectColumn.formState.errors.Columns?.message}
                  </div>
                </div>
              </div>
              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {selected === "OrderBy" && (
        <div className="mt-3">
          <div className="formLayout">
            {/* <div className="Heading">
              <h4 className="Heading1">OrderBy</h4>
              <Line12 />
            </div> */}
            <form onSubmit={OrderBy.handleSubmit(OrderBySubmit)}>
              <div className="transformsfields">
                <div>
                  <label className="fields">Task Name :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...OrderBy.register("TaskName")}
                    placeholder="e.g OrderBy"
                    defaultValue={transformCache ? transformCache.TaskName : ""}
                  />
                  <div style={{ color: "red" }}>
                    {OrderBy.formState.errors.TaskName?.message}
                  </div>
                </div>
                <div>
                  <label className="fields">Column :</label>
                  <input
                    type="text"
                    className="form-control fields mt-1"
                    {...OrderBy.register("Columns")}
                    placeholder="e.g Department Id"
                    defaultValue={transformCache ? transformCache.Columns : ""}
                  />
                  {/* <p>* You Can Add Multiple Columns using (, comma)</p> */}
                  <div style={{ color: "red" }}>
                    {OrderBy.formState.errors.Columns?.message}
                  </div>
                </div>
              </div>
              <div className="transformsfieldbutton">
                <div>
                  <button className="transformsbutton" onClick={PageClosing}>
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="transformsbutton" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Transforms;
