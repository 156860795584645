import React from "react";
import { GoGitMerge } from "react-icons/go";
const Pull = ({ activeStep, setActiveStep, setStepper }) => {
  return (
    <div className="commitview">
      <div className="repoDetails">
        <div className="CurrentRepo"></div>
        <div className="OldRepo"></div>
      </div>
      <div className="commitMessage">
        <input className="form-control" placeholder="Commit Message" />
      </div>
      <div className="commitbutton">
        <button className="btn btn-secondary mt-3 mr-3">Cancel</button>
        <button className="btn btn-primary mt-3">
          <GoGitMerge size={20} />
          &ensp;Merge
        </button>
      </div>
    </div>
  );
};

export default Pull;
